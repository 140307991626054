<template>
    <el-container class="home-container">
        <!-- 左边区域 -->
        <el-aside :width="isCollapse ? '64px' : '201px'">
            <el-header style="text-align:center;">
                <img height="60" width="60" src="../assets/logo2.png" alt="" style="float:left;">
                <h3 v-if="!isCollapse" style="margin:0;padding-left:80px;line-height: 60px;">{{ logo_title }}</h3>
            </el-header>

            <el-menu background-color="#fff" active-text-color="#409BFF" unique-opened :collapse="isCollapse"
                     :collapse-transition="false" router :default-active="$route.path">
                <!--<el-submenu index="1">-->
                <!--<template slot="title">-->
                <!--<i class="iconfont icon-xitongshezhi"></i>-->
                <!--<span>报表统计</span>-->
                <!--</template>-->
                <!--<el-menu-item index="/UserAnalysis">-->
                <!--<i class="iconfont icon-wangzhanshezhi"></i>-->
                <!--<span slot="title">用户分析</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/TenantAnalysis">-->
                <!--<i class="iconfont icon-gonggaoguanli"></i>-->
                <!--<span slot="title">租户分析</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/ShareList">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">分享记录</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/TranslateList">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">翻译记录</span>-->
                <!--</el-menu-item>-->
                <!--</el-submenu>-->
                <!--<el-submenu index="2">-->
                <!--<template slot="title">-->
                <!--<i class="iconfont icon-yonghuguanli"></i>-->
                <!--<span>用户管理</span>-->
                <!--</template>-->
                <!--<el-menu-item index="/Usermanagement">-->
                <!--<i class="iconfont icon-yonghuliebiao"></i>-->
                <!--<span slot="title">用户列表</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/Shopmanagement">-->
                <!--<i class="iconfont icon-dianpuguanli"></i>-->
                <!--<span slot="title">店铺管理</span>-->
                <!--</el-menu-item>-->
                <!--&lt;!&ndash;<el-menu-item index="/Packagemanagement">-->
                <!--<i class="iconfont icon-iconfront-"></i>-->
                <!--<span slot="title">套餐管理</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/CardKeymanagement">-->
                <!--<i class="iconfont icon-kami"></i>-->
                <!--<span slot="title">卡密管理</span>-->
                <!--</el-menu-item>&ndash;&gt;-->
                <!--</el-submenu>-->
                <!--<el-submenu index="3">-->
                <!--<template slot="title">-->
                <!--<i class="iconfont icon-xitongshezhi"></i>-->
                <!--<span>运营管理</span>-->
                <!--</template>-->
                <!--<el-menu-item index="/Feedback">-->
                <!--<i class="iconfont icon-wangzhanshezhi"></i>-->
                <!--<span slot="title">用户反馈管理</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/SysMsg">-->
                <!--<i class="iconfont icon-gonggaoguanli"></i>-->
                <!--<span slot="title">通知管理</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/RechargeRecord">-->
                <!--<i class="iconfont icon-chongzhijilu"></i>-->
                <!--<span slot="title">充值记录</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/ConsumptionLog">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">消费记录</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/PopupImg">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">图片弹框</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/PopupTxt">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">文字弹框</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/IpMeal">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">IP管理</span>-->
                <!--</el-menu-item>-->
                <!--</el-submenu>-->
                <!--<el-submenu index="4">-->
                <!--<template slot="title">-->
                <!--<i class="iconfont icon-tiepai"></i>-->
                <!--<span>贴牌管理</span>-->
                <!--</template>-->
                <!--<el-menu-item index="/Tenantmanagement">-->
                <!--<i class="iconfont icon-jiaoseguanlizuhu"></i>-->
                <!--<span slot="title">租户列表</span>-->
                <!--</el-menu-item>-->

                <!--<el-menu-item index="/Bannermanagement">-->
                <!--<i class="iconfont icon-banner"></i>-->
                <!--<span slot="title">Banner图管理</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/WithdrawList">-->
                <!--<i class="iconfont icon-zijinzhangbian"></i>-->
                <!--<span slot="title">提现记录</span>-->
                <!--</el-menu-item>-->
                <!--</el-submenu>-->
                <!--<el-submenu index="5">-->
                <!--<template slot="title">-->
                <!--<i class="iconfont icon-xitongshezhi"></i>-->
                <!--<span>系统管理</span>-->
                <!--</template>-->
                <!--<el-menu-item index="/WebConfigList">-->
                <!--<i class="iconfont icon-wangzhanshezhi"></i>-->
                <!--<span slot="title">网站设置</span>-->
                <!--</el-menu-item>-->

                <!--&lt;!&ndash;<el-menu-item index="/PaymentSetting">&ndash;&gt;-->
                <!--&lt;!&ndash;<i class="iconfont icon-65_zhifushezhi"></i>&ndash;&gt;-->
                <!--&lt;!&ndash;<span slot="title">支付设置</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
                <!--&lt;!&ndash;<el-menu-item index="/Ipmanagement">&ndash;&gt;-->
                <!--&lt;!&ndash;<i class="iconfont icon-fuwuqiguanli"></i>&ndash;&gt;-->
                <!--&lt;!&ndash;<span slot="title"> I P 管 理 </span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
                <!--<el-menu-item index="/Logmanagement">-->
                <!--<i class="iconfont icon-rizhiguanli"></i>-->
                <!--<span slot="title">日志管理</span>-->
                <!--</el-menu-item>-->
                <!--<el-menu-item index="/AppUpdate">-->
                <!--<i class="iconfont icon-xiazai"></i>-->
                <!--<span slot="title">APP版本管理</span>-->
                <!--</el-menu-item>-->
                <!--&lt;!&ndash;<el-menu-item index="/Announcement">&ndash;&gt;-->
                <!--&lt;!&ndash;<i class="iconfont icon-gonggaoguanli"></i>&ndash;&gt;-->
                <!--&lt;!&ndash;<span slot="title">公告管理</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->
                <!--&lt;!&ndash;<el-menu-item index="/CustomerService">&ndash;&gt;-->
                <!--&lt;!&ndash;<i class="iconfont icon-kefu"></i>&ndash;&gt;-->
                <!--&lt;!&ndash;<span slot="title">客服管理</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</el-menu-item>&ndash;&gt;-->

                <!--</el-submenu>-->

                <el-submenu :index="menu.id" v-for="menu in menuList">
                    <template slot="title">
                        <i :class="menu.menuIcon"></i>
                        <span>{{menu.menuName}}</span>
                    </template>
                    <el-menu-item v-if="menu.children" :index="m.menuUrl" v-for="m in menu.children">
                        <i :class="m.menuIcon"></i>
                        <span slot="title">{{m.menuName}}</span>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <!-- 右边区域 -->
        <el-container>
            <!-- 头部区域 -->
            <el-header class="header-class">
                <i class="iconfont icon-jihe" @click="toggleCollapse"></i>
                <!-- 头像区域 -->
                <el-dropdown @command="handleCommand">
                    <el-badge is-dot>
                        <img src="../assets/images/like.png" height="32" width="32"/>
                        <!--<el-avatar src="../assets/images/user.png"></el-avatar>-->
                    </el-badge>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item command='modify'>修改密码</el-dropdown-item> -->
                        <!-- <el-dropdown-item command='buy'>购买套餐</el-dropdown-item> -->
                        <el-dropdown-item command='logout'>退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>

            <!-- 内容区域 -->
            <el-main>
                <!-- 路由占位符 -->
                <router-view></router-view>
            </el-main>

        </el-container>
    </el-container>
</template>

<script>

    import {
        ApiLogout, ApiAuthMenu
    } from '@/request/api'

    export default {
        data() {
            return {
                isCollapse: false,
                menuList: []
            }
        },

        created: function () {
            ApiAuthMenu({menuType: "WEB"}).then(res => {
                this.$store.commit("setAuthMenu", res.data);
                const menuList = res.data;
                let pMenu = menuList.filter(menu => {
                    return menu.pid == 0
                });
                pMenu.forEach(menu => {
                    menu['children'] = menuList.filter(m => {
                        return m.pid == menu.id
                    })
                })
                console.info(pMenu)
                this.menuList = pMenu;
            })
        },
        methods: {
            toggleCollapse() {
                this.isCollapse = !this.isCollapse
            },
            handleCommand(command) {
                switch (command) {
                    case 'logout':
                        //清空session
                        ApiLogout().then(response => (window.sessionStorage.clear()))
                        this.$router.push('/login')
                        break
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .home-container {
        height: 100%;
        // min-width: 1000px;
    }

    .el-header {
        padding: 0;
        background-color: #00aaff; //409eff //
        color: #fff;
        line-height: 60px;
    }

    .el-aside {
        background-color: #fff; //fff
        transition: width 0.3s;
        border-right: 1px solid #eee;

        .logo {
            border-right: 1px solid #eee;
            line-height: 60px;
            display: flex;
            align-items: center;
            padding: 0;

            img {
                height: 40px;

            }
        }

        .el-menu {
            border-right: none;
        }
    }

    .header-class {
        padding: 0 20px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-dropdown {
            height: 40px;
            cursor: pointer;
        }

        i {
            cursor: pointer;
        }
    }

    .iconfont {
        margin-right: 15px;
    }

    // .el-menu-item{
    //background-color: #eee !important;
    // }
    .el-menu-item:hover {
        background-color: #ecf5ff !important;
    }

    .el-menu-item:focus {
        background-color: #ecf5ff !important;
    }

    .el-main {
        background-color: #fff; //f6f6f6
    }

    .modifypass {
        div {
            .el-input {
                width: 80%;
                margin-bottom: 15px;
            }

            .el-button {
                width: 80%;
                height: 40px;
            }
        }
    }
</style>
