<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您可以在这里查看账户登录情况</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>
                <el-button type="primary" icon="el-icon-search" @click="getLogList">搜索结果</el-button>
            </el-card>
        </el-col>

        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result.data" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="loginIp" label="登录IP" align="center"></el-table-column>
                <el-table-column prop="loginDevice" label="登录设备" align="center"></el-table-column>
                <el-table-column prop="loginBrowser" label="浏览器" align="center"></el-table-column>
                <el-table-column prop="loginResult" label="登录状态" align="center"></el-table-column>
                <el-table-column prop="createTime" label="登录时间" align="center"></el-table-column>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.list.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="result.count"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiPersonalLoginLog } from '@/request/api'

export default {
    data(){
        return{
            loading:true,
            list:{
                page:1,
                limit:10,
                beginTime:'',
                endTime:''
            },
            result:[],
            xtime:''
        }
    },
    created(){
        // 获取套餐列表
        this.getLogList()
    },
    methods:{
        // 获取登录日志
        async getLogList(){
            this.loading = true
            ApiPersonalLoginLog(this.list).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.result = res.data
            })
            this.loading = false
        },

        handleSizeChange(value){
            this.list.limit = value
            this.getLogList()
        },
        handleCurrentChange(value){
            this.list.page = value
            this.getLogList()
        },
        queryTime(){
            this.list.beginTime = this.xtime[0]
            this.list.endTime = this.xtime[1]
        },
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: right;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>