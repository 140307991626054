<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>公告列表管理</span></el-alert>
		</el-col>

		<el-col>
			<el-card shadow="hover">

				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="ShowAddVisible = true"> 添加公告</el-button>
						</el-col>
					</el-col>

					<el-dialog class="modifypass" title="添加公告" width="40%" :visible.sync="ShowAddVisible">
						<div class="AddUserClass">
							<el-row :gutter="15">
								<el-col>
									<el-input v-model="add_params.title" clearable placeholder="公告标题" prefix-icon="iconfont icon-gonggaoguanli"></el-input>
								</el-col>
								<el-col :span="24" style="margin-bottom: 10px;">
									<el-input v-model="add_params.content" type="textarea" :rows="4" clearable placeholder="公告内容" prefix-icon="iconfont icon-gonggaoguanli"></el-input>
								</el-col>
								<el-col style="margin-bottom: 10px;">
									<el-switch v-model="add_params.state" inactive-text="是否显示" style="margin-right: 15px;"></el-switch>
									<el-switch v-model="add_params.top" inactive-text="是否置顶"></el-switch>
								</el-col>
							</el-row>
							<el-button type="primary" @click="AddAnnounce">添加公告</el-button>
						</div>
					</el-dialog>

					<el-dialog class="modifypass" title="编辑公告" width="40%" :visible.sync="ShowEditVisible">
						<div class="AddUserClass">
							<el-row :gutter="15">
								<el-col>
									<el-input v-model="edit_params.title" clearable placeholder="公告标题" prefix-icon="iconfont icon-gonggaoguanli"></el-input>
								</el-col>
								<el-col :span="24" style="margin-bottom: 10px;">
									<el-input v-model="edit_params.content" type="textarea" :rows="4" clearable placeholder="公告内容" prefix-icon="iconfont icon-gonggaoguanli"></el-input>
								</el-col>
							</el-row>
							<el-button type="primary" @click="SetAnnounce('edit','')">编辑公告</el-button>
						</div>
					</el-dialog>


					<el-col :xs="24" :span="12">
						<el-col :xs="24" :md="8">
							<el-select v-model="params.top" clearable placeholder="置顶状态">
								<el-option v-for="item in topoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-select v-model="params.state" clearable placeholder="公告状态">
								<el-option v-for="item in stateoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetAnnounceList">查询</el-button>
						</el-col>
					</el-col>


				</el-row>

			</el-card>
		</el-col>


		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="title" label="公告标题" align="center"></el-table-column>
				<el-table-column prop="content" label="公告内容" align="center"></el-table-column>


				<el-table-column prop="content" label="是否显示" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.state" @change="UpdateStatusValue('state',scope.row.id,scope.row.state)"></el-switch>
					</template>
				</el-table-column>

				<el-table-column prop="content" label="是否置顶" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.top" @change="UpdateStatusValue('top',scope.row.id,scope.row.top)"></el-switch>
					</template>
				</el-table-column>


				<el-table-column fixed="right" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="success" size="small" @click="EditAnnounce(scope.row)">编辑</el-button>
						<el-button type="danger" size="small" @click="SetAnnounce('delete',scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-col :xs="12">
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
				</el-pagination>
			</el-col>

		</el-col>
	</el-row>

</template>

<script>
	import {
		ApiNoticesList,
		ApiNoticesAdd,
		ApiNoticesDelete,
		ApiNoticesChangeTop,
		ApiNoticesChangeState,
		ApiNoticesEdit
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: false,
				ShowAddVisible: false,
				ShowEditVisible: false,
				params: {
					top: '',
					state: '',
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				topoptions:[
					{
						value:true,
						label:'置顶'
					},
					{
						value:false,
						label:'不置顶'
					}
				],
				stateoptions:[
					{
						value:true,
						label:'显示'
					},
					{
						value:false,
						label:'不显示'
					}
				],
				result: [],
				xtime: '',
				add_params: {
					top: false,
					state: true,
					title: '',
					content: '',
				},
				edit_params: {
					id: '',
					title: '',
					content: ''
				}
			}
		},
		mounted() {

		},
		created() {
			this.GetAnnounceList()
		},
		methods: {
			// 获取公告列表
			GetAnnounceList: function() {
				this.loading = true
				ApiNoticesList(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			AddAnnounce: function() {
				// api
				ApiNoticesAdd(this.add_params).then(res => {
					this.$message.success('添加公告成功!')
					this.ShowAddVisible = false
					this.GetAnnounceList()
				})
			},
			EditAnnounce: function(row) {
				// api
				this.edit_params.id = row.id
				this.edit_params.title = row.title
				this.edit_params.content = row.content
				this.ShowEditVisible = true
			},
			UpdateStatusValue: function(method, id, value) {
				if (method == 'state') {
					ApiNoticesChangeState({
						id: id,
						state: value
					}).then(res => {
						this.$message.success('更新成功！')
					})
				} else {
					ApiNoticesChangeTop({
						id: id,
						top: value
					}).then(res => {
						this.$message.success('更新成功！')
					})
				}
				// this.GetAnnounceList()
			},
			SetAnnounce: function(method, row) {
				switch (method) {
					case 'edit':
						ApiNoticesEdit(this.edit_params).then(res => {
							this.$message.success('更新成功!')
							this.GetAnnounceList()
							this.ShowEditVisible = false
						})
						break
					case 'delete':
						ApiNoticesDelete({
							id: row.id
						}).then(res => {
							this.$message.success('删除公告成功!')
							this.GetAnnounceList()
						})
						break
				}
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetAnnounceList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetAnnounceList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
