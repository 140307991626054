<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>提现记录</span></el-alert>
		</el-col>

		<!-- 查询区域 -->
		<el-col>

			<el-card shadow="hover">
				<el-row :gutter="15">
					<el-col class="hidden-xs-only" :span="12">

						<el-col :xs="24" :sm="8" :md="8">
							<el-input v-model="params.tenantName" placeholder="租户名称" clearable></el-input>
						</el-col>

						<el-col :xs="24" :sm="8" :md="8">
							<el-select v-model="params.result" placeholder="账单类型" style="width: 100%;" clearable>
								<el-option v-for="item in result_type" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-col>



						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetList">查询</el-button>
						</el-col>

						<!-- <el-col :md="4">
							<el-button type="danger" icon="iconfont icon-tianjia" @click="OpenVisible"> 提成提现</el-button>
						</el-col> -->
					</el-col>


					<!-- <el-dialog class="modifypass" title="提现" width="35%" :visible.sync="SubmitVisible">
						<div class="AddUserClass">
							<span style="margin-bottom: 15px;display: block;">账号可提现余额为:<a style="color: red;"> {{ user_result.tenantAvailableBalance }} 元</a></span>
							<el-input v-model="data.money" clearable placeholder="需提现金额" prefix-icon="iconfont icon-tixian"></el-input>
							<el-input v-model="data.alipay" clearable placeholder="支付宝账号" prefix-icon="iconfont icon-tixian"></el-input>
							<el-input v-model="data.alipayName" clearable placeholder="支付宝名称" prefix-icon="iconfont icon-tixian"></el-input>
							<el-button type="primary" @click="WithdrawSubmit">提交审核</el-button>
						</div>
					</el-dialog>
 -->
				</el-row>

			</el-card>
		</el-col>

		<!-- 账单表格 -->
		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
				<el-table-column prop="money" label="金额" align="center"></el-table-column>
				<el-table-column prop="alipay" label="支付宝" align="center"></el-table-column>
				<el-table-column prop="alipayName" label="支付宝名称" align="center"></el-table-column>
				<el-table-column prop="result" label="支付状态" align="center"></el-table-column>
				<el-table-column prop="payTime" label="支付时间" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="success" size="small" @click="WithdrawOperation(scope.row)">打款完成</el-button>
						<el-button type="danger" size="small" @click="WithdrawOperation(scope.row,'REFUSE_PAY')">拒绝打款</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>
	</el-row>

</template>

<script>
	import {
		ApiWithdrawList,
		ApiWithdrawOperation

	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				result_type: [{
						value: 'REFUSE_PAY',
						label: '拒绝打款'
					},
					{
						value: 'FINISH_PAY',
						label: '打款完成'
					},
					{
						value: 'WAIT_PAY',
						label: '等待打款'
					},
				],
				params: {
					tenantName: '',
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				xtime: '',
			}
		},
		created() {
			this.GetList()
		},
		methods: {
			// 获取提现记录
			GetList: function() {
				this.loading = true
				ApiWithdrawList(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			// 操作处理
			WithdrawOperation:function(row,result="FINISH_PAY"){
				
				this.$confirm('此操作将会更新记录，是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					ApiWithdrawOperation({id:row.id,result:result}).then(res => {
						this.$message.success("更新成功!")
						// 刷新列表
						this.GetList()
					})
					
				}).catch(() => {})
				
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}
</style>
