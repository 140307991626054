import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Control from '../components/Control.vue'
import RechargeList from '../components/RechargeList.vue'
import Recharge from '../components/Recharge.vue'
import PackageList from '../components/PackageList.vue'
import Loginlog from '../components/Loginlog.vue'
import CardList from '../components/CardList.vue'
import ConsumptionList from '../components/ConsumptionList.vue'
import UseCard from '../components/UseCard.vue'
import PersonalStores from '../components/Personalstores.vue'

import DataBriefing from '../components/Admin/DataBriefing.vue'
import PaymentSetting from '../components/Admin/PaymentSetting.vue'
import Logmanagement from '../components/Admin/Logmanagement.vue'
import Usermanagement from '../components/Admin/Usermanagement.vue'
import RechargeRecord from '../components/Admin/RechargeRecord.vue'
import Shopmanagement from '../components/Admin/Shopmanagement.vue'
import CustomerService from '../components/Admin/CustomerService.vue'
import Announcement from '../components/Admin/Announcement.vue'
import Packagemanagement from '../components/Admin/Packagemanagement.vue'
import CardKeymanagement from '../components/Admin/CardKeymanagement.vue'
import WebConfigList from '../components/Admin/WebConfigList.vue'
import Ipmanagement from '../components/Admin/Ipmanagement.vue'
import Tenantmanagement from '../components/Admin/Tenantmanagement.vue'
import Bannermanagement from '../components/Admin/Bannermanagement.vue'
import ConsumptionLog from '../components/Admin/ConsumptionLog.vue'
import WithdrawList from '../components/Admin/WithdrawList.vue'
import AppUpdate from '../components/Admin/AppUpdate.vue'
import Feedback from '../components/Admin/Feedback.vue'
import SysMsg from '../components/Admin/SysMsg.vue'
import PopupImg from '../components/Admin/popupImg.vue'
import PopupTxt from '../components/Admin/popupTxt.vue'
import UserAnalysis from '../components/Admin/UserAnalysis.vue'
import TenantAnalysis from '../components/Admin/TenantAnalysis.vue'
import ShareList from '../components/Admin/ShareList.vue'
import TranslateList from '../components/Admin/TranslateList.vue'
import IpMeal from '../components/Admin/IpMeal.vue'
import {Message} from 'element-ui'

Vue.use(VueRouter)

var admin_title = '知虾聊聊APP管理'

const routes = [
    // 默认跳转
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        component: Login,
        meta: {
            title: admin_title
        }
    },
    {
        path: '/home',
        component: Home,
        redirect: '/control',
        children: [
            {
                path: '/control',
                component: Control,
                meta: {
                    title: '仪表盘' + " - " + admin_title
                }
            },
            {
                path: '/DataBriefing',
                component: DataBriefing,
                meta: {
                    title: '数据简报' + " - " + admin_title
                }
            },
            {
                path: '/PaymentSetting',
                component: PaymentSetting,
                meta: {
                    title: '支付设置' + " - " + admin_title
                }
            },
            {
                path: '/Logmanagement',
                component: Logmanagement,
                meta: {
                    title: '日志管理' + " - " + admin_title
                }
            },
            {
                path: '/Usermanagement',
                component: Usermanagement,
                meta: {
                    title: '用户管理' + " - " + admin_title
                }
            },
            {
                path: '/RechargeRecord',
                component: RechargeRecord,
                meta: {
                    title: '充值列表' + " - " + admin_title
                }
            },
            {
                path: '/Shopmanagement',
                component: Shopmanagement,
                meta: {
                    title: '店铺管理' + " - " + admin_title
                }
            },
            {
                path: '/CustomerService',
                component: CustomerService,
                meta: {
                    title: '客服管理' + " - " + admin_title
                }
            },
            {
                path: '/Announcement',
                component: Announcement,
                meta: {
                    title: '公告管理' + " - " + admin_title
                }
            },
            {
                path: '/Packagemanagement',
                component: Packagemanagement,
                meta: {
                    title: '套餐管理' + " - " + admin_title
                }
            },
            {
                path: '/CardKeymanagement',
                component: CardKeymanagement,
                meta: {
                    title: '卡密管理' + " - " + admin_title
                }
            },
            {
                path: '/WebConfigList',
                component: WebConfigList,
                meta: {
                    title: '网站设置' + " - " + admin_title
                }
            },
            {
                path: '/Ipmanagement',
                component: Ipmanagement,
                meta: {
                    title: 'IP管理' + " - " + admin_title
                }
            },
            {
                path: '/Tenantmanagement',
                component: Tenantmanagement,
                meta: {
                    title: '租户管理' + " - " + admin_title
                }
            },
            {
                path: '/Bannermanagement',
                component: Bannermanagement,
                meta: {
                    title: 'Banner管理' + " - " + admin_title
                }
            },
            {
                path: '/ConsumptionLog',
                component: ConsumptionLog,
                meta: {
                    title: '账变管理' + " - " + admin_title
                }
            },
            {
                path: "/WithdrawList",
                component: WithdrawList,
                meta: {
                    title: '提现记录' + " - " + admin_title
                }
            },
            {
                path: "/AppUpdate",
                component: AppUpdate,
                meta: {
                    title: 'APP链接管理' + " - " + admin_title
                }
            },
            {
                path: '/rechargelist',
                component: RechargeList
            },
            {
                path: '/recharge',
                component: Recharge
            },
            {
                path: '/packagelist',
                component: PackageList
            },
            {
                path: '/login-log',
                component: Loginlog
            },
            {
                path: '/cardlist',
                component: CardList
            },
            {
                path: '/consumptionList',
                component: ConsumptionList
            },
            {
                path: '/usecard',
                component: UseCard
            },
            {
                path: '/personalstores',
                component: PersonalStores
            },
            {
                path: '/Feedback',
                component: Feedback,
                meta: {
                    title: '用户反馈管理' + " - " + admin_title
                }
            },
            {
                path: '/SysMsg',
                component: SysMsg,
                meta: {
                    title: '通知管理' + " - " + admin_title
                }
            },
            {
                path: '/PopupImg',
                component: PopupImg,
                meta: {
                    title: '图片弹框' + " - " + admin_title
                }
            }, {
                path: '/PopupTxt',
                component: PopupTxt,
                meta: {
                    title: '文字弹框' + " - " + admin_title
                }
            }, {
                path: '/UserAnalysis',
                component: UserAnalysis,
                meta: {
                    title: '用户分析' + " - " + admin_title
                }
            }, {
                path: '/TenantAnalysis',
                component: TenantAnalysis,
                meta: {
                    title: '租户分析' + " - " + admin_title
                }
            }, {
                path: '/ShareList',
                component: ShareList,
                meta: {
                    title: '分享记录' + " - " + admin_title
                }
            }, {
                path: '/TranslateList',
                component: TranslateList,
                meta: {
                    title: '翻译记录' + " - " + admin_title
                }
            },
            {
                path: '/IpMeal',
                component: IpMeal,
                meta: {
                    title: 'IP套餐' + " - " + admin_title
                }
            },


        ]
    },
]

const router = new VueRouter({
    routes
})

// 挂在路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login')
        return next();
    // 获取token
    const tokenStr = window.sessionStorage.getItem('token')
    // 如果token不存在则跳转登录
    if (!tokenStr)
        return next('/login')

    if ("/,/control,/home".indexOf(to.path) == -1) {
        const menuList = store.getters.authMenu;
        let menus = menuList.filter(menu => {
            return menu.menuUrl == to.path
        });
        if (menus.length == 0) {
            if (menuList.length > 0)
                Message.error("权限不足");
            return next("/control");
        }
    }
    next()

    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
})

export default router
