<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>套餐列表管理</span></el-alert>
		</el-col>

		<el-col>
			<el-tabs v-model="activeName" @tab-click="tableClick">
				<el-tab-pane label="基础套餐管理" name="VIP">
					<!-- 查询区域 -->
					<el-card shadow="hover">
						<el-row :gutter="15">
							<el-col class="hidden-xs-only" :span="12" style="float: left;">
								<el-col :md="4">
									<el-button type="primary" icon="iconfont icon-tianjia" @click="ShowVisible('add')"> 添加套餐</el-button>
								</el-col>
							</el-col>
							<el-dialog class="modifypass" :title="MealVIP.btnName" :md="8" :visible.sync="MealVIP.AddMealVIP">
								<div class="AddUserClass">

									<el-input v-model="MealVIP.setMealName" clearable placeholder="套餐名称">
										<template slot="prepend">套餐名称</template>
									</el-input>
									<el-input v-model="MealVIP.setMealDesc" clearable placeholder="套餐描述">
										<template slot="prepend">套餐描述</template>
									</el-input>
									<el-input v-model="MealVIP.setMealPrice" clearable placeholder="套餐价格">
										<template slot="prepend">套餐价格</template>
									</el-input>

									<el-row :gutter="15">
										<el-col :span="12">
											<el-input v-model="MealVIP.setMealTime" clearable placeholder="套餐时间">
												<template slot="prepend">套餐时间</template>
											</el-input>
										</el-col>
										<el-col :span="12">
											<el-select v-model="MealVIP.setMealTimeType" placeholder="请选择时间类型">
												<el-option v-for="item in TimeType" :key="item.id" :value="item.value" :label="item.label"></el-option>
											</el-select>
										</el-col>
									</el-row>

									<el-switch style="margin-bottom: 10px;" inactive-text="套餐状态" v-model="MealVIP.setMealState" active-value="启用"
									 inactive-value="禁用"></el-switch>

									<el-input v-model="MealVIP.setMealBindShop" clearable placeholder="店铺数量">
										<template slot="prepend">店铺数量</template>
									</el-input>

									<el-button type="primary" @click="RequestHandle">{{ MealVIP.btnName }}</el-button>
								</div>
							</el-dialog>
						</el-row>
					</el-card>

					<!-- 订单表格 -->
					<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
						<el-table-column type="index" label="#"></el-table-column>
						<el-table-column prop="setMealName" label="套餐名称" align="center"></el-table-column>
						<el-table-column prop="setMealDesc" label="套餐描述" align="center"></el-table-column>
						<el-table-column prop="setMealPrice" label="套餐价格" align="center"></el-table-column>
						<el-table-column prop="setMealTime" label="套餐时间" align="center"></el-table-column>
						<el-table-column prop="setMealTimeType" label="时间类型" align="center"></el-table-column>


						<el-table-column prop="setMealVip" label="VIP" align="center">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.setMealVip" @change="UpdateState(scope.row,'VIP')"></el-switch>
							</template>
						</el-table-column>
						<el-table-column prop="setMealSVip" label="SVIP" align="center">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.setMealSVip" @change="UpdateState(scope.row,'SVIP')"></el-switch>
							</template>
						</el-table-column>



						<el-table-column prop="setMealBindShop" label="绑定店铺数量" align="center"></el-table-column>
						<el-table-column fixed="right" label="操作" align="center" width="150">
							<template slot-scope="scope">
								<el-button type="success" size="small" @click="ShowVisible(scope.row)">编辑</el-button>
								<el-button type="danger" size="small" @click="DeleteRecord(scope.row,'NORMAL')">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<el-col :xs="12">
						<!-- 分页区域 -->
						<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
						 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
						</el-pagination>
					</el-col>

				</el-tab-pane>



				<el-tab-pane label="聊易虾套餐管理" name="LYX">
					<!-- 查询区域 -->
					<el-card shadow="hover">
						<el-row :gutter="15">
							<el-col class="hidden-xs-only" :span="12" style="float: left;">
								<el-col :md="4">
									<el-button type="success" icon="iconfont icon-tianjia" @click="EditMealLYX('add')"> 添加套餐</el-button>
								</el-col>
							</el-col>
							<!-- 编辑AND添加 -->
							<el-dialog class="modifypass" title="添加套餐" :md="8" :visible.sync="MealLYX.AddMealLYX">
								<div class="AddUserClass">
									<el-input v-model="MealLYX.name" clearable placeholder="套餐名称" prefix-icon="iconfont icon-yonghu">
										<template slot="prepend">套餐名称</template>
									</el-input>
									<el-input v-model="MealLYX.day" clearable placeholder="套餐时间" prefix-icon="iconfont icon-time">
										<template slot="prepend">套餐时间</template>
									</el-input>

									<el-input v-model="MealLYX.originalPrice" clearable placeholder="原有价格" prefix-icon="iconfont icon-yue">
										<template slot="prepend">原有价格</template>
									</el-input>
									<el-input v-model="MealLYX.discountPrice" clearable placeholder="现在价格" prefix-icon="iconfont icon-yue">
										<template slot="prepend">现在价格</template>
									</el-input>
									<el-select v-model="MealLYX.type" clearable placeholder="请选择套餐类型">
										<el-option v-for="item in packtype" :key="item.value" :label="item.label" :value="item.value">
										</el-option>
									</el-select>
									<el-switch style="margin-bottom: 10px;" v-model="MealLYX.state" active-text="套餐状态"></el-switch>
									<el-button type="primary" @click="EditMealLYX('handle')">{{ MealLYX.btnName }}</el-button>
								</div>
							</el-dialog>

						</el-row>
					</el-card>

					<!-- 订单表格 -->
					<el-table stripe :data="app_result.data" v-loading.fullscreen.lock="loading" :xs="24">
						<el-table-column type="index" label="#"></el-table-column>
						<el-table-column prop="id" label="套餐ID" align="center"></el-table-column>
						<el-table-column prop="name" label="套餐名" align="center"></el-table-column>
						<el-table-column prop="originalPrice" label="原价格" align="center"></el-table-column>
						<el-table-column prop="discountPrice" label="新价格" align="center"></el-table-column>
						<el-table-column prop="day" label="时间" align="center"></el-table-column>

						<el-table-column prop="state" label="状态" align="center">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.state" @change="UpdateState(scope.row,'state')"></el-switch>
							</template>
						</el-table-column>

						<el-table-column fixed="right" label="操作" align="center" width="150">
							<template slot-scope="scope">
								<el-button type="success" size="small" @click="EditMealLYX(scope.row)">编辑</el-button>
								<el-button type="danger" size="small" @click="DeleteRecord(scope.row,'APP')">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<el-col :xs="12">
						<!-- 分页区域 -->
						<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
						 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
						</el-pagination>
					</el-col>

				</el-tab-pane>

			</el-tabs>
		</el-col>



	</el-row>

</template>

<script>
	import {
		ApiMealList,
		ApiMealVIPEdit,
		ApiMealVIPAdd,
		ApiMealDelete,
		ApiMealAppList,
		ApiMealAppStateEdit,
		ApiMealAppAdd,
		ApiMealAppEdit,
		ApiMealEdit
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: '',
					setMealName: '',
				},
				app_params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: '',
				},
				activeName: 'VIP',
				result: [], //普通套餐
				app_result: [], //APP套餐
				xtime: '',
				AddShopVisible: false,
				ShopInfo: {
					'id': '',
					'username': '',
					'shopCountry': '',
					'shopType': '',
					'shopUsername': '',
					'shopPassword': '',
					'btnName': '添加',
					'method': ''
				},
				MealLYX: {
					'id': '',
					'day': '',
					'name': '',
					'type':'',
					'originalPrice': '',
					'discountPrice': '',
					'state': true,
					'AddMealLYX': false,
					'btnName': '添加套餐',
					'method': ''
				},
				MealVIP: {
					'id': '',
					'AddMealVIP': false,
					'setMealName': '',
					'setMealDesc': '',
					'setMealPrice': '',
					'setMealTime': '',
					'setMealTimeType': '',
					'setMealBindShop': '',
					'setMealState': '',
					'btnName': '添加套餐',
					'method': ''
				},
				TimeType: [{
						value: '天',
						label: '天'
					},
					{
						value: '月',
						label: '月'
					},
					{
						value: '年',
						label: '年'
					},
					{
						value: '小时',
						label: '小时'
					},
				],
				toType: {
					'天': 'DAY',
					'月': 'MONTH',
					'年': 'YEAR',
					'小时': 'HOUR',
				},
				packtype: [{
						value: 0,
						label: 'VIP'
					},
					{
						value: 1,
						label: '隔离'
					},
				]
			}
		},
		created() {
			this.GetMealList('VIP')
		},
		methods: {
			// 获取套餐列表
			GetMealList: function(method) {
				this.loading = true
				if (method == 'VIP' || method == 'SVIP') {
					ApiMealList(this.params).then(res => {
						this.result = res.data
					})
				} else {
					ApiMealAppList(this.app_params).then(res => {
						this.app_result = res.data
					})
				}
				this.loading = false
			},
			UpdateState: function(row, method) {

				if (method == 'state') {
					ApiMealAppStateEdit({
						'id': row.id,
						'state': row.state
					}).then(res => {
						this.$message.success('更新成功!')
						this.GetMealList('app')
					})
					return
				}

				let data = {
					'id': row.id,
					'type': method,
					'state': method == 'VIP' ? row.setMealVip : row.setMealSVip
				}

				ApiMealVIPEdit(data).then(res => {
					this.$message.success('更新成功！')
					this.GetMealList(method)
				})

			},
			tableClick: function() {
				// 切换请求列表
				this.GetMealList(this.activeName)
			},
			// 编辑添加聊一虾
			EditMealLYX: function(value) {
				if (value == 'add') {
					this.MealLYX.method = 'add'
					this.MealLYX.AddMealLYX = true

				} else if (value == 'handle') {
					if (this.MealLYX.method == 'add') {
						// 添加
						ApiMealAppAdd(this.MealLYX).then(res => {
							this.$message.success('新增记录成功!')
							this.MealLYX.AddMealLYX = false
							this.GetMealList('app')
						})
					} else {
						// 编辑
						ApiMealAppEdit(this.MealLYX).then(res => {
							this.$message.success('编辑记录成功!')
						})
					}
					// 隐藏更新列表
					this.MealLYX.AddMealLYX = false
					this.GetMealList('app')
				} else {
					// 编辑
					this.MealLYX.btnName = '编辑套餐'
					this.MealLYX.method = 'edit'
					this.MealLYX.id = value.id
					this.MealLYX.name = value.name
					this.MealLYX.day = value.day
					this.MealLYX.type = value.type
					this.MealLYX.originalPrice = value.originalPrice
					this.MealLYX.discountPrice = value.discountPrice
					this.MealLYX.state = value.state
					this.MealLYX.AddMealLYX = true
					return
				}
			},
			// 显示添加套餐
			ShowVisible: function(method) {
				this.MealVIP.btnName = method == 'add' ? '添加套餐' : '编辑套餐'
				this.MealVIP.method = 'add'
				if (method != 'add') {
					this.MealVIP.id = method.id
					this.MealVIP.setMealName = method.setMealName
					this.MealVIP.setMealDesc = method.setMealDesc
					this.MealVIP.setMealPrice = method.setMealPrice
					this.MealVIP.setMealTime = method.setMealTime
					this.MealVIP.setMealTimeType = method.setMealTimeType
					this.MealVIP.setMealBindShop = method.setMealBindShop
					this.MealVIP.setMealState = method.setMealState
					this.MealVIP.method = 'edit'
				}

				this.MealVIP.AddMealVIP = true
			},
			// 处理编辑添加
			RequestHandle: function() {

				this.MealVIP.setMealTimeType = this.toType[this.MealVIP.setMealTimeType]
				this.MealVIP.setMealState = this.MealVIP.setMealState == '启用' ? 'ENABLE' : 'DISABLE'

				if (this.MealVIP.method == 'add') {
					// ADD
					ApiMealVIPAdd(this.MealVIP).then(res => {
						this.$message.success('添加成功!')
					})
				} else {
					// Edit
					ApiMealEdit(this.MealVIP).then(res => {
						this.$message.success('编辑成功!')
					})
				}
				this.MealVIP.AddMealVIP = false
				this.GetMealList('VIP')
			},

			// 删除记录
			DeleteRecord: function(row, method) {
				this.$confirm('此操作将永久删除该记录，是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 删除
					let data = {
						'id': row.id,
						'type': method
					}
					ApiMealDelete(data).then(res => {
						this.$message.success('删除成功！')
						this.GetMealList()
					})
				}).catch(() => {
					// 取消
				})
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetMealList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetMealList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-select {
			width: 100%;
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}
</style>
