<template>

	<el-row :xs="24">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>网站设置</span></el-alert>
		</el-col>

		<el-col>
			<el-card shadow="hover">
				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="AddConfigVisible = true"> 添加配置</el-button>
						</el-col>
					</el-col>

					<el-dialog title="添加配置" width="25%" :visible.sync="AddConfigVisible">
						<div>
							<el-input style="margin-bottom: 10px;" v-model="add_data.name" clearable placeholder="Name" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="add_data.code" clearable placeholder="Code" prefix-icon="iconfont icon-code"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="add_data.value" clearable placeholder="Value" prefix-icon="iconfont icon-neirong"></el-input>
							<el-input style="margin-bottom: 10px;" type="textarea" autosize placeholder="remark" v-model="add_data.remark"></el-input>
							<el-button style="width: 100%;" type="primary" @click="AddConfig">添加</el-button>
						</div>
					</el-dialog>

					<el-dialog title="编辑配置" width="25%" :visible.sync="EditConfigVisible">
						<div>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.id" clearable placeholder="id" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.name" clearable placeholder="Name" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.code" clearable placeholder="Code" prefix-icon="iconfont icon-code"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.value" clearable placeholder="Value" prefix-icon="iconfont icon-neirong"></el-input>
							<el-input style="margin-bottom: 10px;" type="textarea" autosize placeholder="remark" v-model="edit_data.remark"></el-input>
							<el-button  style="width: 100%;" type="primary" @click="EditConfig">编辑</el-button>
						</div>
					</el-dialog>


					<!-- <el-col :xs="24" :span="12">
						<el-col :xs="24" :md="8">
							<el-input v-model="params.username" :xs="10" :md="10" placeholder="用户" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-input v-model="params.phone" :xs="10" placeholder="手机号码" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetUserList">查询</el-button>
						</el-col>
					</el-col> -->

				</el-row>

			</el-card>
		</el-col>


		<!-- 账单表格 -->
		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="name" label="Name" align="center"></el-table-column>
				<el-table-column prop="code" label="Code" align="center"></el-table-column>
				<el-table-column prop="value" label="Value" align="center"></el-table-column>
				<el-table-column prop="remark" label="Remark" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="EditConfigView(scope.$index,scope.row)">编辑</el-button>
					</template>
				</el-table-column>

			</el-table>

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="result.count"
				 style="margin-top:15px;margin-bottom: 15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>

	</el-row>

</template>

<script>
	import {
		ApiConfigList,
		ApiConfigAdd,
		ApiConfigEdit
	} from '@/request/api'
	export default {
		data() {
			return {
				loading: false,
				params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				AddConfigVisible: false,
				EditConfigVisible: false,
				add_data: {
					'name': '',
					'value': '',
					'code': '',
					'remark':''
				},
				edit_data: {
					'id': '',
					'name': '',
					'value': '',
					'code': '',
					'remark':''
				}
			}
		},
		created() {
			this.GetConfigList()
		},
		methods: {
			GetConfigList: function() {
				ApiConfigList(this.params).then(res => {
					this.result = res.data
				})
			},
			AddConfig:function(){
				ApiConfigAdd(this.add_data).then(res => {
					this.$message.success('添加配置成功！')
					this.AddConfigVisible = false
					this.GetConfigList()
				})
			},
			EditConfig:function(){
				ApiConfigEdit(this.edit_data).then(res => {
					this.$message.success('配置修改成功！')
					this.EditConfigVisible = false
					this.GetConfigList()
				})
			},
			EditConfigView: function(index, row) {
				this.EditConfigVisible = true
				this.edit_data.id = row.id
				this.edit_data.name = row.name
				this.edit_data.value = row.value
				this.edit_data.code = row.code
				this.edit_data.remark = row.remark
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetConfigList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetConfigList()
			},
		}
	}
</script>

<style scoped>

</style>
