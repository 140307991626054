<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>租户分析报表</span></el-alert>
        </el-col>

        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">
                <el-form :inline="true" :model="params" label-width="100px">
                    <el-row :gutter="6">
                        <el-col :span="24">
                            <el-form-item label="数据维度:">
                                <el-radio-group v-model="params.findType" @change="findChange">
                                    <el-radio label="date">日期</el-radio>
                                    <el-radio label="tenant">渠道</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="6">
                        <el-col :md="8" :lg="8">
                            <el-form-item label="日期:">
                                <el-date-picker
                                        style="width:250px"
                                        id="datePicker"
                                        v-model="xtime"
                                        @change="queryTime"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        clearable
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :md="8" :lg="8">
                            <el-form-item label="渠道：">
                                <el-select
                                        style="width:250px"
                                        v-model="params.tenantId"
                                        clearable>
                                    <el-option
                                            v-for="item in optionsTenant"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md="8" :lg="8">
                            <el-form-item>
                                <el-button
                                        style="float:right"
                                        type="primary"
                                        @click="handleSearchList()">
                                    查询
                                </el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button
                                        style="float:right;margin-right: 15px"
                                        type="primary"
                                        @click="handleResetSearch()">
                                    重置
                                </el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button
                                        style="float:right;margin-right: 15px"
                                        type="primary"
                                        @click="exportExcel()">
                                    导出
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
        </el-col>

        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24" @sort-change="sortHttp">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="dataDate" label="日期" align="center" width="150"
                                 sortable="custom" v-if="params.findType=='date'"></el-table-column>
                <el-table-column prop="tenantName" label="渠道" align="center" width="150"
                                 v-if="params.findType=='tenant'">
                </el-table-column>
                <el-table-column prop="registerNum" label="注册用户数" sortable="custom" align="center"
                                 width="150">
                </el-table-column>
                <el-table-column prop="levelRate" label="会员购买率" sortable="custom" align="center" width="150">
                </el-table-column>
                <el-table-column prop="payAmount" sortable="custom" label="总充值" align="center"
                                 width="150"></el-table-column>
                <el-table-column prop="consumeAmount" sortable="custom" label="总消费" align="center"
                                 width="150"></el-table-column>
                <el-table-column label="店铺绑定情况" header-align="center">
                    <el-table-column prop="shopNum" label="店铺数量/跨境/本土" align="center"
                                     width="180">
                        <template slot-scope="scope">
                            {{shopNumTxt(scope.row)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="crossLevelSort0" label="试用用户 跨境/本土" align="center" width="180">
                        <template slot-scope="scope">
                            {{scope.row.crossLevelSort0}}/{{scope.row.localLevelSort0}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="crossLevelSort1" label="普通会员 跨境/本土" align="center" width="180">
                        <template slot-scope="scope">
                            {{scope.row.crossLevelSort1}}/{{scope.row.localLevelSort1}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="crossLevelSort2" label="高级会员 跨境/本土" align="center" width="180">
                        <template slot-scope="scope">
                            {{scope.row.crossLevelSort2}}/{{scope.row.localLevelSort2}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="crossLevelSort3" label="豪华会员 跨境/本土" align="center" width="180">
                        <template slot-scope="scope">
                            {{scope.row.crossLevelSort3}}/{{scope.row.localLevelSort3}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="crossLevelSort4" label="专业会员 跨境/本土" align="center" width="180">
                        <template slot-scope="scope">
                            {{scope.row.crossLevelSort4}}/{{scope.row.crossLevelSort4}}
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>

        </el-col>
    </el-row>

</template>

<script>
    import {
        ApiTenantList,
        ApiTenantAnalysisList,
        ApiTenantAnalysisExport
    } from '@/request/reportApi'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {baseURL} from '../../request/config';
    import qs from 'qs'

    export default {
        components: {},
        data() {
            return {
                optionsTenant: [],
                xtime: [],
                loading: false,
                params: {
                    tenantId: '',
                    page: 1,
                    limit: 10,
                    sortProp: "",
                    sortType: '',
                    findType: 'date'
                },
                result: {},
            }
        },
        mounted() {
        },
        created() {
            this.initSearchDate();
            this.getTenantList();
            this.handleSearchList();
        },
        methods: {
            shopNumTxt(row) {
                return row.shopNum + "/" + row.shopNumCross + "/" + row.shopNumLocal;
            },

            findChange(v) {
                this.handleSearchList();
            },
            exportExcel() {
                this.queryTime();
                ApiTenantAnalysisExport(qs.stringify(this.params)).then(function (response) {
                    var blob = new Blob([response.data])
                    var downloadElement = document.createElement('a');
                    var href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '租户分析.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                }).catch(function (error) {
                    debugger
                    console.log(error);
                });
                // window.open(baseURL + "/report/tenantAnalysisExport?" + this.$qs.stringify(this.params));
            },
            sortHttp(e) {
                let sortProp = "";
                for (let i = 0; i < e.prop.length; i++) {
                    let str = e.prop[i];
                    if (str.match(/^.*[A-Z]+.*$/)) {
                        sortProp += "_" + str.toString().toLocaleLowerCase();
                    } else {
                        sortProp += str;
                    }
                }
                this.params.sortProp = sortProp;
                this.params.sortType = e.order == "ascending" ? "asc" : e.order == "descending" ? "desc" : "";
                this.handleSearchList();
            },
            getDay(day) {
                let today = new Date();
                let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                let tYear = today.getFullYear();
                let tMonth = today.getMonth();
                let tDate = today.getDate();
                tMonth = this.doHandleMonth(tMonth + 1);
                tDate = this.doHandleMonth(tDate);
                return tYear + "-" + tMonth + "-" + tDate;
            },
            doHandleMonth(month) {
                let m = month;
                if (month.toString().length == 1) {
                    m = "0" + month;
                }
                return m;
            },
            initSearchDate() {
                this.xtime.push(this.getDay(-7));
                this.xtime.push(this.getDay(0));
            },
            getTenantList() {
                ApiTenantList().then(res => {
                    res['data'].forEach(item => {
                        this.optionsTenant.push({
                            value: item['id'],
                            label: item['tenantName']
                        });
                    })

                })
            },
            handleResetSearch() {
                this.params = Object.assign({}, {
                    limit: 10,
                    page: 1
                });
                this.xtime = '';
            },
            queryTime() {
                if (this.xtime && this.xtime.length == 2) {
                    this.params.beginDate = this.xtime[0]
                    this.params.endDate = this.xtime[1]
                } else {
                    this.params.beginDate = null
                    this.params.endDate = null
                }
            },
            handleSearchList() {
                this.queryTime();
                this.params.page = 1
                this.getPageList()
            },
            // 获取公告列表
            getPageList: function () {
                this.loading = true
                ApiTenantAnalysisList(this.params).then(res => {
                    this.result = res.data
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                });
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            }
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
