<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>用户列表管理</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col>
            <el-card shadow="hover">

                <el-row :gutter="15">

                    <el-col class="hidden-xs-only" :span="3" style="float: left;">
                        <el-col :md="4">
                            <el-button type="primary" icon="iconfont icon-tianjia" @click="AddUserVisible = true">
                                添加用户
                            </el-button>
                        </el-col>
                    </el-col>

                    <!-- 添加用户窗口开始 -->

                    <el-dialog class="modifypass" title="添加用户" width="30%" :visible.sync="AddUserVisible">
                        <div class="AddUserClass">
                            <el-input v-model="AddUser.username" clearable placeholder="账号"
                                      prefix-icon="iconfont icon-yonghu"></el-input>
                            <el-input v-model="AddUser.password" clearable show-password placeholder="密码"
                                      prefix-icon="iconfont icon-mima"></el-input>
                            <el-input v-model="AddUser.tenantName" clearable placeholder="所属租户"
                                      prefix-icon="iconfont icon-mima"></el-input>
                            <el-input v-model="AddUser.email" clearable placeholder="邮箱"
                                      prefix-icon="iconfont icon-youxiang1"></el-input>
                            <el-input v-model="AddUser.phone" clearable placeholder="电话"
                                      prefix-icon="iconfont icon-dianhua1"></el-input>
                            <el-button type="primary" @click="AddUserInfo">添加用户</el-button>
                        </div>
                    </el-dialog>

                    <!-- 添加用户窗口结束 -->

                    <!-- 编辑用户窗口开始 -->
                    <el-dialog class="modifypass" title="编辑用户" width="30%" :visible.sync="EditUserVisible">
                        <div class="AddUserClass">
                            <el-input disabled v-model="EditUser.userId" clearable placeholder="UID"
                                      prefix-icon="iconfont icon-yonghu">
                                <template slot="prepend">U ID</template>
                            </el-input>
                            <el-input v-model="EditUser.username" clearable placeholder="账号"
                                      prefix-icon="iconfont icon-yonghu">
                                <template slot="prepend">账号</template>
                            </el-input>
                            <el-input v-model="EditUser.password" clearable placeholder="密码"
                                      prefix-icon="iconfont icon-mima">
                                <template slot="prepend">密码</template>
                            </el-input>
                            <el-input v-model="EditUser.phone" clearable placeholder="电话"
                                      prefix-icon="iconfont icon-dianhua1">
                                <template slot="prepend">电话</template>
                            </el-input>
                            <el-input v-model="EditUser.email" clearable placeholder="邮箱"
                                      prefix-icon="iconfont icon-youxiang1">
                                <template slot="prepend">邮箱</template>
                            </el-input>
                            <el-input v-model="EditUser.balance" clearable placeholder="余额"
                                      prefix-icon="iconfont icon-yue">
                                <template slot="prepend">余额</template>
                            </el-input>
                            <el-input v-model="EditUser.proxyPoint" clearable placeholder="代理点位"
                                      prefix-icon="iconfont icon-fudianshu">
                                <template slot="prepend">代理点位</template>
                            </el-input>
                            <el-input v-model="EditUser.tenantId" clearable placeholder="所属租户"
                                      prefix-icon="iconfont icon-yonghu">
                                <template slot="prepend">所属租户</template>
                            </el-input>
                            <el-input v-model="EditUser.appExpireTime" clearable placeholder="APP到期时间"
                                      prefix-icon="iconfont icon-fudianshu">
                                <template slot="prepend">APP到期时间</template>
                            </el-input>


                            <el-input v-model="EditUser.expireTime" clearable placeholder="PC到期时间"
                                      prefix-icon="iconfont icon-fudianshu">
                                <template slot="prepend">PC到期时间</template>
                            </el-input>


                            <el-button type="primary" @click="EditUserInfo('edit')">修改信息</el-button>
                        </div>
                    </el-dialog>
                    <!-- 编辑用户窗口结束 -->

                    <el-col :xs="24" :span="18">
                      <el-col :xs="24" :md="3">
                          <el-input v-model="params.username" :xs="10" :md="10" placeholder="用户" clearable></el-input>
                      </el-col>
                      <el-col :xs="24" :md="4">
                          <el-input v-model="params.phone" :xs="10" placeholder="手机号码" clearable></el-input>
                      </el-col>

                      <el-col :md="3">
                        <el-select v-model="params.channel" placeholder="来源">
                          <el-option label="聊聊app" value="0"> </el-option>
                          <el-option label="知虾存量" value="1"></el-option>
                          <el-option label="知虾增量" value="2"></el-option>
                          <el-option label="虾扑存量" value="3"></el-option>
                          <el-option label="虾扑增量" value="4"></el-option>
                        </el-select>
                      </el-col>

                      <el-col :md="4">
                        <el-select
                            placeholder="渠道"
                            style="width:150px"
                            v-model="params.tenantId"
                            clearable>
                          <el-option
                              v-for="item in optionsTenant"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :md="10">
                        <el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker>
                      </el-col>

                    </el-col>

                </el-row>

                <el-row :gutter="15" type="flex" justify="end" style="margin-top: 20px">

                  <el-col :xs="24" :md="3">
                    <el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetUserList">
                      查询
                    </el-button>
                  </el-col>

                  <el-col :xs="24" :md="3">
                    <el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="exportExcel">
                      导出
                    </el-button>
                  </el-col>
                </el-row>

                <!-- <el-select placeholder="卡密状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->


            </el-card>
        </el-col>

        <!-- 账单表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="username" label="用户名" align="center"></el-table-column>
                <el-table-column prop="tenantName" label="所属租户" align="center"></el-table-column>
                <el-table-column prop="channel" label="来源" align="center">
                  <template slot-scope="{row}">
                    {{row.channel === 0 ? '聊聊app' : row.channel === 1 ? '知虾存量' : row.channel === 2 ? '知虾增量' : row.channel === 3 ? '虾扑存量' : '虾扑增量'}}
                  </template>
                </el-table-column>

                <el-table-column prop="phone" label="电话" align="center"></el-table-column>
                <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
<!--                <el-table-column prop="userLevel.setMealName" label="套餐" align="center"></el-table-column>-->
                <el-table-column prop="balance" label="余额" align="center"></el-table-column>
                <el-table-column prop="levelName" label="会员等级" align="center"></el-table-column>
                <el-table-column prop="endDate" label="到期时间" align="center"></el-table-column>
<!--                <el-table-column prop="appExpireTime" label="APP到期时间" align="center"></el-table-column>-->
<!--                <el-table-column prop="proxyPoint" label="代理点位" align="center"></el-table-column>-->
                <el-table-column prop="proxy" label="是否代理" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.proxy" @change="UpdateProxy(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="loginState" label="登录状态" align="center">

                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.loginState" active-value="启用" inactive-value="冻结"
                                   @change="UpdateStatus(scope.row)"></el-switch>
                    </template>

                </el-table-column>
                <el-table-column prop="createTime" label="注册时间" align="center"></el-table-column>
                <el-table-column prop="activationTime" label="激活时间" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" @click="EditUserInfo(scope.row)">编辑</el-button>
                        <!-- <el-button type="danger" size="small" @click="DeleteRecord(scope.$index,scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>


        </el-col>
    </el-row>

</template>

<script>
import {
  ApiUserList,
  ApiEditProxyStatus,
  ApiEditStatus,
  ApiUserAdd,
  ApiEditUser, ApiConsumeLogList
} from '@/request/api'
    import {
      ApiUserListExport,
      ApiTenantList
    } from '@/request/reportApi'
    import qs from "qs";

    export default {
        data() {
            return {
                optionsTenant: [],
                loading: true,
                params: {
                    username: '',
                    email: '',
                    channel: '',
                    phone: '',
                    tenantId: '',
                    loginState: '',
                    proxy: '',
                    page: 1,
                    limit: 10
                },
                result: [],
                xtime: [],
                AddUserVisible: false,
                EditUserVisible: false,
                AddUser: {
                    username: '',
                    password: '',
                    tenantName: '',
                    email: '',
                    phone: ''
                },
                EditUser: {
                    userId: '',
                    username: '',
                    password: '',
                    email: '',
                    phone: '',
                    balance: '',
                    appExpireTime: '',
                    tenantId: '',
                    // loginState:'',
                    // proxy:'',
                    proxyPoint: ''
                }
            }
        },
        created() {
            this.GetUserList();
            this.getTenantList();
        },
        methods: {
            //导出的方法
            async exportExcel() {
              let excelResult;
              let paramsExcel = this.params;
              paramsExcel.pageType = 1;
              if (!paramsExcel.beginDate) {
                this.$message.warning('请输入开始时间')
                return;
              }
              await ApiUserList(paramsExcel).then(res => {
                excelResult = res.data
              })
              require.ensure([], () => {
                const { export_json_to_excel } = require('@/excel/Export2Excel');
                const tHeader = ['用户名', '所属租户', '来源', '电话', '邮箱', '余额', '会员等级', '到期时间', '注册时间', '激活时间'];
                // 上面设置Excel的表格第一行的标题
                const filterVal = ['username', 'tenantName', 'channel', 'phone', 'email', 'balance', 'levelName', 'endDate', 'createTime', 'activationTime'];
                // 上面的index、nickName、name是tableData里对象的属性
                const data = this.formatJson(filterVal, excelResult);
                export_json_to_excel(tHeader, data, 'user_excel');
              })
            },
            formatJson(filterVal, jsonData) {
              return jsonData.data.map(v => filterVal.map(j => {
                if (j === 'channel') {
                  return v[j] === 0 ? '聊聊app' : v[j] === 1 ? '知虾存量' : v[j] === 2 ? '知虾增量' : v[j] === 3 ? '虾扑存量' : '虾扑增量';
                } else {
                  return v[j]
                }
              }))
            },

            // 获取用户列表
            GetUserList: function () {
                this.loading = true
                ApiUserList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            // 添加用户信息
            AddUserInfo: function () {
                ApiUserAdd(this.AddUser).then(res => {
                    this.$message.success('添加成功!')
                    this.AddUserVisible = false
                })
            },
            // 编辑用户信息
            EditUserInfo: function (value) {

                if (value === 'edit') {
                    // 提交修改
                    ApiEditUser(this.EditUser).then(res => {
                        this.$message.success('修改成功')
                        // 更新结果
                        this.GetUserList()
                    })
                    this.EditUserVisible = false
                } else {
                    // 显示编辑
                    this.EditUserVisible = true

                    this.EditUser.userId = value.userId
                    this.EditUser.username = value.username
                    this.EditUser.password = value.password
                    this.EditUser.email = value.email
                    this.EditUser.phone = value.phone
                    this.EditUser.balance = value.balance
                    this.EditUser.loginState = value.loginState
                    this.EditUser.proxy = value.proxy
                    this.EditUser.proxyPoint = value.proxyPoint
                    this.EditUser.tenantId = value.tenantId
                    this.EditUser.appExpireTime = value.appExpireTime

                }

            },
            // 更新代理状态
            UpdateProxy: function (row) {
                let data = {
                    id: row.userId,
                    proxy: row.proxy
                }
                ApiEditProxyStatus(data).then(res => {
                    this.$message.success('更新成功!')
                })
            },
            // 更新用户状态
            UpdateStatus: function (row) {
                let data = {
                    id: row.userId,
                    // status:row.loginState == '启用' ? 'ENABLE':'LOCKED'
                    status: row.loginState
                }
                ApiEditStatus(data).then(res => {
                    this.$message.success('更新成功!')
                })
            },
            getTenantList() {
              ApiTenantList().then(res => {
                res['data'].forEach(item => {
                  this.optionsTenant.push({
                    value: item['id'],
                    label: item['tenantName']
                  });
                })
              })
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.GetUserList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetUserList()
            },
            queryTime() {
              if (this.xtime && this.xtime.length === 2) {
                this.params.beginDate = this.xtime[0]
                this.params.endDate = this.xtime[1]
              } else {
                this.params.beginDate = null
                this.params.endDate = null
              }
            },
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }
</style>
