<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您可以在这里选择购买适合的套餐，或升级更高级的套餐!</span></el-alert>
        </el-col>

        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result.list" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="setMealName" label="套餐名字" align="center"></el-table-column>
                <el-table-column prop="setMealPrice" label="套餐价格" align="center">
                    <template slot-scope="scope">
                        <span style="color:red;">￥{{ scope.row.setMealPrice }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="setMealTime" label="套餐时间" align="center"></el-table-column>
                <el-table-column prop="setMealTimeType" label="时间类型" align="center"></el-table-column>
                <el-table-column prop="setMealVip" label="VIP" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.setMealVip" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="setMealSVip" label="SVIP" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.setMealSVip" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="setMealBindShop" label="绑定店铺数量" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="380">
                    <template slot-scope="scope">

                        <el-popover
                            placement="top-start"
                            title="介绍"
                            width="200"
                            trigger="hover"
                            :content="scope.row.setMealDesc">
                            <el-button icon="el-icon-search" slot="reference" size="small">介绍</el-button>
                        </el-popover>

                        
                        <el-button style="margin-left:15px;" type="success" size="small" @click="goBuy(scope.$index,scope.row,1)" :disabled="!scope.row.enable">购买套餐</el-button>
                        
                        <el-button style="margin-left:15px;" type="warning" size="small" @click="goBuy(scope.$index,scope.row,2)" v-if="result.proxy" >购买卡密</el-button>
                    </template>
                </el-table-column>
                
            </el-table>

        </el-col>
    </el-row>

</template>

<script>

import { ApiSetMealList , ApiSetMealBuy ,ApiSetMealBuyCardKey } from '@/request/api'

export default {
    data(){
        return{
            loading:true,
            result:[],
        }
    },
    created(){
        // 获取套餐列表
        this.getPackageList()
    },
    methods:{

        // 获取套餐列表
        async getPackageList(){
            this.loading = true
            ApiSetMealList().then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.result = res.data
            })
            // 总数
            this.loading = false
        },
        // 购买套餐
        async buy(index,row){

            let data = {
                'id':row.id
            }

            ApiSetMealBuy(data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$message.success('购买成功!')
            })

        },

        async buyCard(index,row){

            let data = {
                'id':row.id
            }

            ApiSetMealBuyCardKey(data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$notify({title:'购买成功',message:res.data,type:'success'})
            })
       
        },


        // 请求购买
        async goBuy(index,row,val){

            this.$confirm('请确认是否购买此套餐?' + row.setMealName, '购买提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => { 
                if(val == 2){
                    // 卡密
                    this.buyCard(index,row)
                }else{
                    this.buy(index,row)
                }
                
            }).catch(() => {
                return
            });
        }

    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: left;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>