<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>通知列表管理</span></el-alert>
        </el-col>

        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">

                <el-form :inline="true" :model="params" label-width="140px">
                    <el-form-item label="标题：">
                        <el-input v-model="params.msgTitle" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="消息类型：">
                        <el-select
                                style="width:220px"
                                v-model="params.msgType"
                                clearable
                        >
                            <el-option label="系统消息" :value="1"/>
                            <el-option label="活动消息" :value="2"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-select
                                style="width:220px"
                                v-model="params.msgStatus"
                                clearable
                        >
                            <el-option label="上线" :value="1"/>
                            <el-option label="下线" :value="0"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button
                                style="float:right"
                                type="primary"
                                @click="handleSearchList()">
                            查询
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                style="float:right;margin-right: 15px"
                                type="primary"
                                @click="edit({})">
                            添加
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-col>

        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="msgTitle" label="标题" align="center"></el-table-column>
                <el-table-column prop="msgType" label="类型" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.msgType ===1 ">系统消息</span>
                        <span v-if="scope.row.msgType ===2 ">活动消息</span>
                    </template>
                </el-table-column>
                <el-table-column prop="msgStatus" label="状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.msgStatus ===1 ">上线</span>
                        <span v-else>下线</span>
                    </template>
                </el-table-column>
                <el-table-column prop="subtitle" label="副标题" :show-overflow-tooltip="true"
                                 align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="createUser" label="创建人" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="scope.row.msgStatus !=1" @click="release(scope.row)">
                            发布
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.msgStatus ===1" @click="reCall(scope.row)">
                            撤回
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.msgStatus ===1" @click="pushMsg(scope.row)">
                            推送
                        </el-button>
                        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>

        </el-col>
        <el-dialog
                title="通知新增/编辑"
                :visible.sync="dialogVisible"
                width="85%">
            <el-form :model="saveData" :rules="rules" ref="saveData" label-width="100px" :inline="true"
                     style="padding-top: 10px">
                <el-form-item label="通知标题" prop="name">
                    <el-input v-model="saveData.msgTitle" style="width: 180px;"></el-input>
                </el-form-item>
                <el-form-item label="消息副标题" prop="subtitle">
                    <el-input v-model="saveData.subtitle" style="width: 350px;"></el-input>
                </el-form-item>
                <el-form-item label="通知类型">
                    <el-select
                            v-model="saveData.msgType"
                            prop="type"
                            style="width: 150px;"
                    >
                        <el-option label="系统通知" :value="1"/>
                        <el-option label="活动通知" :value="2"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit()">保存</el-button>
                    <el-button @click="dialogVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
            <quill-editor
                    ref="myQuillEditor"
                    v-model="saveData.msgDesc"
            />
        </el-dialog>
    </el-row>

</template>

<script>
    import {
        ApiSysMsgList,
        ApiSysMsgSave,
        ApiSysMsgRemove,
        ApiSysMsgRelease,
        ApiSysMsgReCall,
        ApiSysMsgPush,
        ApiSysMsgGetById
    } from '@/request/api'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {quillEditor} from 'vue-quill-editor'

    export default {
        components: {
            quillEditor
        },
        data() {
            return {
                loading: false,
                params: {
                    msgType: '',
                    msgTitle: '',
                    page: 1,
                    limit: 10,
                    msgStatus: '',
                    subtitle: ""
                },
                result: [],
                saveData: {},
                dialogVisible: false,
                rules: {
                    msgTitle: [
                        {required: true, message: '请输入通知标题', trigger: 'blur'}
                    ],
                    subtitle: [
                        {required: true, message: '请输入通知副标题', trigger: 'blur'}
                    ],
                    msgType: [
                        {required: true, message: '请选择通知类型', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {

        },
        created() {
            this.handleSearchList()
        },
        methods: {
            pushMsg(row) {
                if (row.pushMsgId) {
                    this.$confirm('已经推送，是否重复推送？')
                        .then(() => {
                            ApiSysMsgPush(row).then(res => {
                                if (res.success) {
                                    this.getPageList()
                                    this.$message.success(res.data)
                                }
                            });
                        });
                } else {
                    ApiSysMsgPush(row).then(res => {
                        if (res.success) {
                            this.getPageList()
                            this.$message.success(res.data)
                        }
                    });
                }
            },
            reCall(row) {
                ApiSysMsgReCall(row).then(res => {
                    if (res.success) {
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            handleSearchList() {
                this.params.page = 1
                this.getPageList()
            },
            // 获取公告列表
            getPageList: function () {
                this.loading = true
                ApiSysMsgList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            release(row) {
                ApiSysMsgRelease(row).then(res => {
                    if (res.success) {
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            handleSubmit() {
                ApiSysMsgSave(this.saveData).then(res => {
                    if (res.success) {
                        this.dialogVisible = false;
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            edit(row) {
                if (row.id) {
                    this.loading = true
                    ApiSysMsgGetById(row).then(res => {
                        this.loading = false
                        this.dialogVisible = true;
                        this.saveData = {
                            id: res.data.id,
                            msgType: res.data.msgType,
                            msgTitle: res.data.msgTitle,
                            msgDesc: res.data.msgDesc,
                            subtitle: res.data.subtitle
                        };
                    }).catch(err => {
                        this.loading = false;
                    });
                } else {
                    this.saveData = {}
                    this.dialogVisible = true;
                }


            },
            remove(row) {
                this.$confirm('确认删除？')
                    .then(() => {
                        ApiSysMsgRemove(row).then(res => {
                            if (res.success) {
                                this.getPageList()
                                this.$message.success(res.data)
                            }
                        });
                    })
                    .catch(_ => {
                    });
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            }
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
