<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>用户反馈管理</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">

                <el-form :inline="true" :model="params" label-width="140px">
                    <el-form-item label="反馈日期:">
                        <el-date-picker
                                id="datePicker"
                                v-model="xtime"
                                @change="queryTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                clearable
                        />
                    </el-form-item>
                    <el-form-item label="用户账号：">
                        <el-input v-model="params.username" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户手机号：">
                        <el-input v-model="params.phone" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="类型：">
                        <el-select
                                style="width:220px"
                                v-model="params.backType"
                                clearable
                        >
                            <el-option label="功能异常" value="功能异常"/>
                            <el-option label="体验问题" value="体验问题"/>
                            <el-option label="新功能建议" value="新功能建议"/>
                            <el-option label="其他吐槽" value="其他吐槽"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-select
                                style="width:220px"
                                v-model="params.backStatus"
                                clearable
                        >
                            <el-option label="待回复" :value="0"/>
                            <el-option label="已回复" :value="1"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button
                                style="float:right"
                                type="primary"
                                @click="handleSearchList()">
                            查询
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                style="float:right;margin-right: 15px"
                                type="primary"
                                @click="handleResetSearch()">
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-col>

        <!-- 充值表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="username" label="用户账号"></el-table-column>
                <el-table-column prop="markDesc" label="问题/投诉内容" min-width="200"></el-table-column>
                <el-table-column prop="backType" label="问题类型"></el-table-column>
                <el-table-column prop="createTime" label="反馈日期" width="160"></el-table-column>
                <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
                <el-table-column prop="contactInformation" label="联系方式" width="160">
                    <template slot-scope="scope">
                        {{ scope.row.contactType }}:{{ scope.row.contactInformation }}
                    </template>
                </el-table-column>
                <el-table-column prop="backStatus" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.backStatus ===1 ">已回复</span>
                        <span v-else>待回复</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="updateStatus(scope.row,1)" type="text">编辑回复
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>


        </el-col>
        <el-dialog
                title="编辑回复"
                :visible.sync="dialogVisible"
                width="800">
            <el-form label-width="140px">
                <el-form-item label="反馈类型:">{{showData.backType}}</el-form-item>
                <el-form-item label="问题:">{{showData.markDesc}}</el-form-item>
                <el-form-item>
                    <el-row :gutter="8">
                        <el-col :span="4" v-if="showData&&showData.imgUrl" v-for="url in showData.imgUrlList">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="url"
                                    :preview-src-list="showData.imgUrlList">
                            </el-image>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="请输入回复:">
                    <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="showData.replyDesc">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">确定</el-button>
                    <el-button @click="dialogVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-row>

</template>

<script>
    import {
        ApiFeedbackList, ApiFeedbackReply
    } from '@/request/api'

    export default {
        data() {
            return {
                loading: true,
                params: {
                    username: "",
                    phone: "",
                    backType: "",
                    backStatus: "",
                    limit: 10,
                    page: 1,
                },
                result: [],
                dialogVisible: false,
                xtime: null,
                showData: {}
            }
        },
        created() {
            this.handleSearchList()
        },
        methods: {
            onSubmit() {
                ApiFeedbackReply(this.showData).then(res => {
                    if (res.success) {
                        this.dialogVisible = false;
                        this.getPageList()
                        this.$message.success(res.msg)
                    }
                })
            },
            handleResetSearch() {
                this.params = Object.assign({}, {
                    limit: 10,
                    page: 1
                });
                this.xtime='';
            },
            handleSearchList() {
                this.params.page = 1
                this.getPageList()
            },
            // 获取用户列表
            getPageList: function () {
                this.loading = true
                ApiFeedbackList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            updateStatus(row) {
                this.dialogVisible = true;
                this.showData = row;
                if (row.imgUrl) {
                    this.showData['imgUrlList'] = row.imgUrl.split(",");
                }
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            },
            queryTime() {
                this.params.beginTime = this.xtime[0]
                this.params.endTime = this.xtime[1]
            },
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;

        }
    }

    .table-expand {
        font-size: 0;

        .el-form-item {
            margin-right: 0;
            margin-bottom: 0;
            width: 50%;
        }
    }

    .table-expand label {
        width: 90px;
        color: #99a9bf;
    }
</style>
