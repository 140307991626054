<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>店铺列表管理</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col>
            <el-card shadow="hover">

                <el-row :gutter="15">

                    <el-col class="hidden-xs-only" :span="4" style="float: left;">
                        <el-col :md="4">
                            <el-button type="primary" icon="iconfont icon-tianjia" @click="EditShopInfo('add')"> 添加店铺
                            </el-button>
                        </el-col>
                    </el-col>

                    <!-- 添加店铺窗口开始 -->

                    <el-dialog class="modifypass" title="添加店铺" :md="8" :visible.sync="AddShopVisible">
                        <div class="AddUserClass">
                            <el-input v-model="ShopInfo.username" clearable placeholder="用户账号"
                                      prefix-icon="iconfont icon-yonghu">
                                <template slot="prepend">用户账号</template>
                            </el-input>
                            <el-select v-model="ShopInfo.shopCountry">
                                <el-option v-for="item in AreaOptions" :key="item.id" :value="item.value"
                                           :label="item.label"></el-option>
                            </el-select>
                            <el-select v-model="ShopInfo.shopType">
                                <el-option v-for="item in ShopType" :key="item.value" :value="item.value"
                                           :label="item.label"></el-option>
                            </el-select>
                            <el-input v-model="ShopInfo.shopUsername" clearable placeholder="店铺账号"
                                      prefix-icon="iconfont icon-yonghu">
                                <template slot="prepend">店铺账号</template>
                            </el-input>
                            <el-input v-model="ShopInfo.shopPassword" clearable show-password placeholder="店铺密码"
                                      prefix-icon="iconfont icon-mima">
                                <template slot="prepend">店铺密码</template>
                            </el-input>
                            <el-button type="primary" @click="EditShopInfo('handle')">{{ ShopInfo.btnName }}</el-button>
                        </div>
                    </el-dialog>

                    <!-- 添加店铺窗口结束 -->


                    <el-col :xs="24" :span="20">
                        <el-col :xs="24" :md="6">
                            <el-input :xs="10" :md="10" v-model="params.username" placeholder="用户名"
                                      clearable></el-input>
                        </el-col>
                        <el-col :xs="24" :md="6">
                            <el-input :xs="10" v-model="params.shopUsername" placeholder="店铺账号" clearable></el-input>
                        </el-col>
                        <el-col :xs="24" :md="6">
                            <el-select v-model="params.shopType" clearable>
                                <el-option value="CROSS"
                                           label="跨境"></el-option>
                                <el-option value="LOCAL"
                                           label="本土"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :xs="24" :md="6">
                            <el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="search">
                                查询
                            </el-button>
                        </el-col>
                    </el-col>


                </el-row>


                <!-- <el-select placeholder="卡密状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
                <!-- <el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                 end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker> -->

            </el-card>
        </el-col>

        <!-- 账单表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24" @sort-change="sortHttp">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="username" label="所属用户" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="shopName" label="店铺名" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="shopUsername" label="店铺账号" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="shopCountry" label="所属站点" align="center"></el-table-column>
                <el-table-column prop="shopUid" label="UID" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="shopId" label="SHOPID" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="shopType" label="店铺类型" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" sortable="custom" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" @click="EditShopInfo(scope.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="DeleteRecord(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>


        </el-col>
    </el-row>

</template>

<script>
    import {
        ApiShopList,
        ApiShopEdit,
        ApiShopDelete,
        ApiShopAdd
    } from '@/request/api'

    export default {
        data() {
            return {
                loading: true,
                AreaOptions: [{
                    value: 'https://seller.xiapi.shopee.cn/',
                    label: '台湾',
                    area: 'TW'
                },
                    {
                        value: 'https://seller.th.shopee.cn/',
                        label: '泰国',
                        area: 'TH'
                    },
                    {
                        value: 'https://seller.ph.shopee.cn/',
                        label: '菲律宾',
                        area: 'PH'
                    },
                    {
                        value: 'https://seller.my.shopee.cn/',
                        label: '马来',
                        area: 'MY'
                    },
                    {
                        value: 'https://seller.id.shopee.cn/',
                        label: '印尼',
                        area: 'ID'
                    },
                    {
                        value: 'https://seller.br.shopee.cn/',
                        label: '巴西',
                        area: 'BR'
                    },
                    {
                        value: 'https://seller.vn.shopee.cn/',
                        label: '越南',
                        area: 'VN'
                    },
                    {
                        value: 'https://seller.sg.shopee.cn/',
                        label: '新加坡',
                        area: 'SG'
                    },
                ],
                ShopType: [{
                    value: '跨境',
                    label: '跨境'
                },
                    {
                        value: '本土',
                        label: '本土'
                    }
                ],
                params: {
                    page: 1,
                    limit: 10,
                    beginTime: '',
                    endTime: '',
                    shopUsername: '',
                    username: '',
                    sortProp: "create_time",
                    sortType: 'desc',
                    shopType:''
                },
                result: [],
                xtime: '',
                AddShopVisible: false,
                ShopInfo: {
                    'id': '',
                    'username': '',
                    'shopCountry': '',
                    'shopType': '',
                    'shopUsername': '',
                    'shopPassword': '',
                    'btnName': '添加',
                    'method': ''
                },
                Areaadr: {
                    'https://seller.xiapi.shopee.cn/': 'TW',
                    'https://seller.th.shopee.cn/': 'TH',
                    'https://seller.my.shopee.cn/': 'MY',
                    'https://seller.ph.shopee.cn/': 'PH',
                    'https://seller.br.shopee.cn/': 'BR',
                    'https://seller.sg.shopee.cn/': 'SG',
                    'https://seller.id.shopee.cn/': 'ID',
                    'https://seller.vn.shopee.cn/': 'VN'
                }
            }
        },
        created() {
            this.GetShopList()
        },
        methods: {
            sortHttp(e) {
                let sortProp = "";
                for (let i = 0; i < e.prop.length; i++) {
                    let str = e.prop[i];
                    if (str.match(/^.*[A-Z]+.*$/)) {
                        sortProp += "_" + str.toString().toLocaleLowerCase();
                    } else {
                        sortProp += str;
                    }
                }
                this.params.sortProp = sortProp == "username" ? "user_id" : sortProp;
                this.params.sortType = e.order == "ascending" ? "asc" : e.order == "descending" ? "desc" : "";
                this.search();
            },
            search() {
                this.params.page = 1;
                this.GetShopList();
            },
            // 获取店铺列表
            GetShopList: function () {
                this.loading = true
                ApiShopList(this.params).then(res => {
                    this.loading = false
                    this.result = res.data
                }).catch(err => {
                    this.loading = false
                });

            },
            // 编辑店铺信息
            EditShopInfo: function (value) {

                if (value == 'add') {
                    this.ShopInfo.btnName = '添加店铺'
                    this.ShopInfo.method = 'add'
                    this.AddShopVisible = true
                } else if (value == 'handle') {
                    this.ShopInfo.shopCountry = this.Areaadr[this.ShopInfo.shopCountry]
                    this.ShopInfo.shopType = this.ShopInfo.shopType == "本土" ? 'LOCAL' : 'CROSS'
                    // 处理
                    if (this.ShopInfo.method == 'edit') {
                        ApiShopEdit(this.ShopInfo).then(res => {
                            this.$message.success('更新成功！')
                            this.AddShopVisible = false
                            this.GetShopList()
                        })
                    } else {
                        ApiShopAdd(this.ShopInfo).then(res => {
                            this.$message.success('添加成功！')
                            this.GetShopList()
                        })
                    }

                } else {
                    // 绑定数据

                    this.ShopInfo.btnName = '修改店铺'
                    this.ShopInfo.method = 'edit'

                    this.ShopInfo.id = value.id
                    this.ShopInfo.username = value.username
                    this.ShopInfo.shopCountry = value.shopCountry
                    this.ShopInfo.shopType = value.shopType
                    this.ShopInfo.shopUsername = value.shopUsername
                    this.ShopInfo.shopPassword = value.shopPassword

                    this.AddShopVisible = true
                }

            },
            // 删除记录
            DeleteRecord: function (row) {
                this.$confirm('此操作将永久删除该记录，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 删除
                    let data = {
                        'id': row.id
                    }
                    ApiShopDelete(data).then(res => {
                        this.$message.success('删除成功！')
                        // 重新获取
                        this.GetShopList()
                    })
                }).catch(() => {
                    // 取消
                })
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.GetShopList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetShopList()
            },
            queryTime() {
                this.params.beginTime = this.xtime[0]
                this.params.endTime = this.xtime[1]
            },
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-select {
            width: 100%;
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }
</style>
