<template>
    <div class="login_container" v-loading="loading" @contextmenu.prevent>

        <el-row>
            <el-col class="login_box" v-bind:style="google_auth ? styleObject : styleObjectone">
                <!-- <div class="left">
                    <img src="../assets/banner.jpg" alt="">
                </div> -->
                <div class="right">

                    <div class="logo">
                        <div class="logo_img">
                            <img src="../assets/login_logo.png" alt="">
                            <div>
                                <h1>{{ titleName }}</h1>
                            </div>

                        </div>
                    </div>
                    <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" label-width="0px" class="login_form">
                        <!-- 用户名 -->
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" prefix-icon="iconfont icon-yonghu" @blur="GetCheckAuth()"></el-input>
                        </el-form-item>
                        <!-- 密码 -->
                        <el-form-item prop="password">
                            <el-input v-model="loginForm.password" prefix-icon="iconfont icon-mima" show-password></el-input>
                        </el-form-item>

						<el-form-item v-if="google_auth">
						    <el-input v-model="loginForm.secret" placeholder="验证" prefix-icon="iconfont icon-mima"></el-input>
						</el-form-item>

                        <!-- 按钮 -->
                        <el-form-item class="btns">
                            <el-button type="primary" round @click="login">登录</el-button>
                        </el-form-item>

                    </el-form>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>

import { ApiLogin,ApiCheckGoogleAuth } from '@/request/api'

export default {
    data(){
        return{
            loading: true,
            loginForm:{
                username:'',
                password:'',
				secret:''
            },
            titleName:"后台登录",
            // 表单验证规则
            loginRules:{
                username:[
                    { required:true, message: '请输入用户账号', trigger:'blur'},
                    { min: 3, max: 15, message: '用户名长度在 3 - 15 个字符', trigger:'blur'}
                ],
                password:[
                    { required:true, message: '请输入用户密码', trigger:'blur'},
                    { min: 3, max: 15, message: '密码长度在 3 - 15 个字符', trigger:'blur'}
                ],
            },
			google_auth:false,
			styleObject:{
				height:'400px'
			},
			styleObjectone:{
				height:'350px'
			}
        }
    },
    created(){
		if(this.loginForm.username != ""){
			this.GetCheckAuth()
		}
    },
    mounted(){
        this.loading = false
    },
    methods:{
        login(){
            this.$refs.loginFormRef.validate( async valid =>{
                if(!valid)return;
                // 登录请求
                ApiLogin(this.loginForm).then(res => {
                    // this.$alert('登录成功!',{type:'success'})
                    window.sessionStorage.setItem('token',res.data)
                    this.$router.push('/home')
                })

            })
        },
		GetCheckAuth(){
			let data = {
				username:this.loginForm.username
			}
			ApiCheckGoogleAuth(data).then(res => {
				this.google_auth = res.data
			})
		}
    }
}
</script>



<style lang="less" scoped>
.login_container{
    background-color: #2b4b6b;
    height: 100%;
}

.login_box{
    height: 400px;
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,50%);
	border-radius: 10px;

    .left{
        height: 100%;
        width: 272px;
        float: left;
        img{
            height: 100%;
        }
    }
    .right{
        float: left;
        height: 100%;
        width: 400px;
        background-color: #fff;
		border-radius: 10px;
        .close-h{
            display: flex;
            align-items:flex-start;
            float: right;
            img{
                cursor: pointer;
            }
        }
        .logo_img{
            height: 80px;
            margin-top: 40px;
            display: flex;
            align-items: center;
            padding: 0 70px;
            img{
                height: 100%;
            }
        }
        .login_form{
            height: 170px;
            margin-top: 20px;
            padding: 0 80px;
            box-sizing: border-box;
            color:rgb(108, 160, 73);
        }

    }
}
.btns{
    .el-button{
        width: 100%;
    }
}
.el-tag{
    cursor: pointer;
}


</style>
