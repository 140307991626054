<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您的充值记录账单</span></el-alert>
        </el-col>
        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                
                <el-input v-model="pay.outTradeNo" placeholder="订单号" clearable></el-input>
                <el-input v-model="pay.tradeNo" placeholder="支付交易号" clearable></el-input>

                <el-select v-model="pay.result" placeholder="支付状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    :picker-options="pickerOptions"
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="search">搜索结果</el-button>

            </el-card>
        </el-col>
        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="outTradeNo" label="订单号"></el-table-column>
                <el-table-column prop="tradeNo" label="支付交易号"></el-table-column>
                <el-table-column prop="type" label="支付类型"></el-table-column>
                <el-table-column prop="totalAmount" label="充值金额"></el-table-column>
                <el-table-column prop="payAmount" label="支付金额"></el-table-column>
                <el-table-column prop="result" label="支付状态">
                    <template slot-scope="scope">
                        <!-- <el-tag type="danger">{{ scope.row.result }}</el-tag> -->
                        
                        <el-tag type="success" v-if="scope.row.result ==='支付成功' ">支付成功</el-tag>
                        <el-tag type="danger" v-else>{{ scope.row.result }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="payTime" label="支付时间"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.pay.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiRechargeList } from '@/request/api'

export default {
    data(){
        return{
            payStatus:[
                {value:'WAIT_BUYER_PAY',label:'等待支付'},
                {value:'TRADE_SUCCESS',label:'支付成功'},
            ],
            loading:true,
            pay:{
                outTradeNo:'',
                tradeNo:'',
                beginTime:'',
                endTime:'',
                limit:10,
                page:1,
                result:''
            },
            xtime:'',
            result:[],
            total:0,
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
        }
    },
    created(){

        this.getRechargeinfo()

    },
    methods:{
        async getRechargeinfo(){
            
            this.loading = true

            ApiRechargeList(this.pay).then(res => {
                // console.log(res)
                // if(res.code !== 200)return this.$message.error(res.message)
                this.total = res.data.count
                this.result = res.data.data
            })

            this.loading = false

        },
        search(){
            // 搜索
            this.getRechargeinfo()
        },
        // 更改数量
        handleSizeChange(value){
            this.pay.limit = value
        },
        // 更改页数
        handleCurrentChange(value){
            this.pay.page = value
        },
        // 更改时间
        queryTime(){
            this.pay.beginTime = this.xtime[0]
            this.pay.endTime = this.xtime[1]
        }
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: left;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>