<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>Banner管理</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col>
            <el-card shadow="hover">

                <el-row :gutter="15">

                    <el-col class="hidden-xs-only" :span="8" style="float: left;">
                        <el-col :md="4">
                            <el-button type="primary" icon="iconfont icon-tianjia" @click="AddUserVisible = true"> 添加
                                Banner
                            </el-button>
                        </el-col>
                    </el-col>

                    <!-- 添加Banner窗口开始 -->
                    <el-dialog class="modifypass" title="添加图片" width="30%" :visible.sync="AddUserVisible">
                        <div class="AddUserClass">
                            <el-input v-model="AddBanner.tenantName" clearable placeholder="租户名称"
                                      prefix-icon="iconfont icon-yonghu"></el-input>
                            <el-input v-model="AddBanner.remark" clearable placeholder="备注"
                                      prefix-icon="iconfont icon-yonghu"></el-input>
                            <el-input v-model="AddBanner.bannerUrl" clearable placeholder="图片链接"
                                      prefix-icon="iconfont icon-yonghu"></el-input>
                            <el-input v-model="AddBanner.bannerJump" clearable placeholder="跳转地址"
                                      prefix-icon="iconfont icon-mima"></el-input>
                            <el-switch style="margin-bottom: 10px;" v-model="AddBanner.bannerState"
                                       active-text="显示状态"></el-switch>
                            <el-button type="primary" @click="AddBannerInfo">添加图片</el-button>
                        </div>
                    </el-dialog>
                    <!-- 添加Banner窗口结束 -->

                    <!-- 编辑窗口开始 -->
                    <el-dialog class="modifypass" title="编辑信息" width="35%" :visible.sync="EditUserVisible">
                        <div class="AddUserClass">
                            <el-input disabled v-model="EditInfo.id" clearable placeholder="UID">
                                <template slot="prepend">U ID</template>
                            </el-input>
                            <el-input v-model="EditInfo.tenantName" clearable placeholder="租户名称">
                                <template slot="prepend">租户名称</template>
                            </el-input>
                            <el-input v-model="EditInfo.remark" clearable placeholder="备注">
                                <template slot="prepend">备注</template>
                            </el-input>
                            <el-input v-model="EditInfo.bannerUrl" clearable placeholder="图片地址">
                                <template slot="prepend">图片地址</template>
                            </el-input>
                            <el-input v-model="EditInfo.bannerJump" clearable placeholder="跳转地址">
                                <template slot="prepend">跳转地址</template>
                            </el-input>
                            <el-switch style="margin-bottom: 10px;" v-model="EditInfo.bannerState"
                                       active-text="展示状态"></el-switch>

                            <el-button type="primary" @click="EditBannerInfo('edit')">修改信息</el-button>
                        </div>
                    </el-dialog>
                    <!-- 编辑窗口结束 -->

                    <el-col :xs="24" :span="16">
                        <el-col :xs="12" :md="6">
                            <el-input v-model="params.tenantName" :xs="10" :md="10" placeholder="租户名"
                                      clearable></el-input>
                        </el-col>
                        <el-col :xs="12" :md="6">
                            <el-input v-model="params.remark" :xs="10" :md="10" placeholder="备注"
                                      clearable></el-input>
                        </el-col>

                        <el-col :xs="24" :md="12">
                            <el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetBannerList">
                                查询
                            </el-button>
                        </el-col>
                    </el-col>

                </el-row>

            </el-card>
        </el-col>

        <!-- 账单表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="id" label="ID" align="center"></el-table-column>
                <el-table-column prop="tenantName" label="租户名称" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                <el-table-column prop="bannerUrl" label="图片地址" width="400" align="center"></el-table-column>
                <el-table-column prop="bannerJump" label="跳转地址" align="center"></el-table-column>

                <el-table-column prop="proxy" label="显示状态" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.bannerState" @change="UpdateBannerState(scope.row)"></el-switch>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" @click="EditBannerInfo(scope.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="DeleteBanner(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>


        </el-col>
    </el-row>

</template>

<script>
    import {
        ApiBannerList,
        ApiBannerAdd,
        ApiBannerEditState,
        ApiBannerDelete,
        ApiBannerEdit
    } from '@/request/api'

    export default {
        data() {
            return {
                loading: true,
                params: {
                    tenantName: '',
                    page: 1,
                    limit: 10,
                    beginTime: '',
                    endTime: '',
                    remark:''
                },
                result: [],
                xtime: '',
                AddUserVisible: false,
                EditUserVisible: false,
                AddBanner: {
                    tenantName: '',
                    bannerUrl: '',
                    bannerJump: '',
                    bannerState: true,
                    remark: ''
                },
                EditInfo: {
                    id: '',
                    tenantName: '',
                    bannerUrl: '',
                    bannerJump: '',
                    bannerState: '',
                    remark: ''
                }
            }
        },
        created() {
            this.GetBannerList()
        },
        methods: {
            // 获取Banner列表
            GetBannerList: function () {
                this.loading = true
                ApiBannerList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            // 添加Banner信息
            AddBannerInfo: function () {
                ApiBannerAdd(this.AddBanner).then(res => {
                    this.$message.success('添加成功!')
                    this.AddUserVisible = false
                    // 刷新
                    this.GetBannerList()
                })
            },
            // 删除Banner信息
            DeleteBanner: function (row) {
                this.$confirm('此操作将删除该Banner记录，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    ApiBannerDelete({id: row.id}).then(res => {
                        this.$message.success('删除成功!')
                        // 刷新列表
                        this.GetBannerList()
                    })
                }).catch(() => {
                })
            },
            // 编辑用户信息
            EditBannerInfo: function (value) {

                if (value == 'edit') {
                    // 提交修改
                    ApiBannerEdit(this.EditInfo).then(res => {
                        this.$message.success('修改成功')
                        // 更新结果
                        this.GetBannerList()
                    })
                    this.EditUserVisible = false
                } else {
                    // 显示编辑
                    this.EditUserVisible = true
                    // 绑定数据
                    this.EditInfo.id = value.id
                    this.EditInfo.tenantName = value.tenantName
                    this.EditInfo.bannerUrl = value.bannerUrl
                    this.EditInfo.bannerJump = value.bannerJump
                    this.EditInfo.bannerState = value.bannerState
                    this.EditInfo.remark = value.remark;
                }

            },
            // 更新Banner状态
            UpdateBannerState: function (row) {
                ApiBannerEditState({id: row.id, state: row.bannerState}).then(res => {
                    this.$message.success('更新成功!')
                })
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.GetBannerList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetBannerList()
            },
            queryTime() {
                this.params.beginTime = this.xtime[0]
                this.params.endTime = this.xtime[1]
            },
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }
</style>
