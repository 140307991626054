<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您可以在这里激活卡密和查看历史使用的卡密记录</span></el-alert>
        </el-col>
        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                

                <div style="float:left;">
                    <el-input v-model="cardKey" style="text-align:left;" placeholder="请输入卡密"></el-input>
                    <el-button type="primary" @click="useCard">激活卡密</el-button>
                </div>

            
                <!--
                <el-select v-model="pay.state" placeholder="卡密状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                -->

                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    :picker-options="pickerOptions"
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="search">搜索结果</el-button>

            </el-card>
        </el-col>
        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="cardKey" label="卡密"></el-table-column>
                <!-- <el-table-column prop="generator" label="创建用户"></el-table-column> -->
                <el-table-column prop="createTime" label="使用时间"></el-table-column>
                <!-- <el-table-column prop="updateTime" label="更新时间"></el-table-column> -->
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.pay.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiCardKeyUseLog , ApiCardKeyUse } from '@/request/api'

export default {
    data(){
        return{
            loading:true,
            pay:{
                beginTime:'',
                endTime:'',
                limit:10,
                page:1,
            },
            cardKey:'',
            xtime:'',
            result:[],
            total:0,
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
        }
    },
    created(){
        this.getCardList()
    },
    methods:{
        // 获取使用卡密列表
        async getCardList(){
            this.loading = true
            ApiCardKeyUseLog(this.pay).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.total = res.data.count
                this.result = res.data.data
            })
            this.loading = false
        },
        search(){
            // 搜索
            this.getCardList()
        },
        // 更改数量
        handleSizeChange(value){
            this.pay.limit = value
        },
        // 更改页数
        handleCurrentChange(value){
            this.pay.page = value
        },
        // 更改时间
        queryTime(){
            this.pay.beginTime = this.xtime[0]
            this.pay.endTime = this.xtime[1]
        },
        // 使用卡密
        async useCard(index,row){
            let data = {
                'cardKey':this.cardKey
            }
            ApiCardKeyUse(data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$message.success('激活成功!')
            })
        },
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: right;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>