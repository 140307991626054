<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>租户列表管理</span></el-alert>
		</el-col>

		<!-- 查询区域 -->
		<el-col>
			<el-card shadow="hover">

				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="AddUserVisible = true"> 添加用户</el-button>
						</el-col>
					</el-col>

					<!-- 添加用户窗口开始 -->

					<el-dialog class="modifypass" title="添加用户" width="30%" :visible.sync="AddUserVisible">
						<div class="AddUserClass">
							<!-- <el-input v-model="AddUser.tenantName" clearable placeholder="租户名称" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input v-model="AddUser.tenantUsername" clearable placeholder="用户账号" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input v-model="AddUser.tenantPassword" clearable show-password placeholder="用户密码" prefix-icon="iconfont icon-mima"></el-input>
							 -->
							<el-input v-model="AddUser.tenantName" clearable placeholder="租户名称">
								<template slot="prepend">租户名称</template>
							</el-input>
							<el-input v-model="AddUser.tenantUsername" clearable placeholder="用户账号">
								<template slot="prepend">用户账号</template>
							</el-input>
							<el-input v-model="AddUser.tenantPassword" clearable placeholder="用户密码">
								<template slot="prepend">用户密码</template>
							</el-input>

							<el-input v-model="AddUser.tenantWebsite" clearable placeholder="站点域名">
								<template slot="prepend">站点域名</template>
							</el-input>
							<el-input v-model="AddUser.tenantLogo" clearable placeholder="LOGO">
								<template slot="prepend">站点LOGO</template>
							</el-input>

							<el-input v-model="AddUser.tenantPhone" clearable placeholder="用户手机">
								<template slot="prepend">用户手机</template>
							</el-input>

							<el-input v-model="AddUser.tenantEmail" clearable placeholder="用户邮箱">
								<template slot="prepend">用户邮箱</template>
							</el-input>

							<el-switch style="margin-bottom: 10px;" v-model="AddUser.tenantState" active-text="租户状态"></el-switch>
							<el-button type="primary" @click="AddTenantUserInfo">添加用户</el-button>
						</div>
					</el-dialog>

					<!-- 添加用户窗口结束 -->

					<!-- 编辑用户窗口开始 -->
					<el-dialog class="modifypass" title="编辑信息" width="35%" :visible.sync="EditUserVisible">
						<div class="AddUserClass">
							<el-input disabled v-model="EditUser.id" clearable placeholder="UID">
								<template slot="prepend">U ID</template>
							</el-input>
							<el-input v-model="EditUser.tenantName" clearable placeholder="租户名称">
								<template slot="prepend">租户名称</template>
							</el-input>
							<el-input v-model="EditUser.tenantUsername" clearable placeholder="用户账号">
								<template slot="prepend">用户账号</template>
							</el-input>
							<el-input v-model="EditUser.tenantPassword" clearable placeholder="用户密码">
								<template slot="prepend">用户密码</template>
							</el-input>
							<el-input v-model="EditUser.tenantPoint" clearable placeholder="用户点数">
								<template slot="prepend">用户点数</template>
							</el-input>
							<el-input v-model="EditUser.tenantPhone" clearable placeholder="用户手机">
								<template slot="prepend">用户手机</template>
							</el-input>
							<el-input v-model="EditUser.tenantEmail" clearable placeholder="用户邮箱">
								<template slot="prepend">用户邮箱</template>
							</el-input>
							<el-input v-model="EditUser.tenantWebsite" clearable placeholder="站点域名">
								<template slot="prepend">站点域名</template>
							</el-input>
							<el-input v-model="EditUser.tenantLogo" clearable placeholder="站点LOGO">
								<template slot="prepend">站点LOGO</template>
							</el-input>
							<el-switch style="margin-bottom: 10px;" v-model="EditUser.tenantState" active-text="租户状态"></el-switch>

							<el-button type="primary" @click="EditTenantUserInfo('edit')">修改信息</el-button>
						</div>
					</el-dialog>
					<!-- 编辑用户窗口结束 -->

					<el-col :xs="24" :span="12">
						<el-col :xs="24" :md="8">
							<el-input v-model="params.tenantName" :xs="10" :md="10" placeholder="租户名" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-input v-model="params.tenantUsername" :xs="10" placeholder="用户名称" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetTenantList">查询</el-button>
						</el-col>
					</el-col>


				</el-row>


				<!-- <el-select placeholder="卡密状态" clearable>
				    <el-option
				        v-for="item in payStatus"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value">
				    </el-option>
				</el-select> -->
				<!-- <el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
				 end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker> -->

			</el-card>
		</el-col>

		<!-- 账单表格 -->
		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">


				<el-table-column type="expand" label="#">
					<template slot-scope="props">

						<el-form label-position="left" inline>
							<el-form-item label="站点域名">
								<span>{{ props.row.tenantWebsite }}</span>
							</el-form-item>
							<el-form-item label="LOGO">
								<span>{{ props.row.tenantLogo }}</span>
							</el-form-item>
						</el-form>

					</template>
				</el-table-column>


				<!-- <el-table-column type="index" fixed label="#"></el-table-column> -->
				<el-table-column prop="id" label="ID" align="center"></el-table-column>
				<el-table-column prop="tenantName" label="租户名称" align="center"></el-table-column>
				<el-table-column prop="tenantBalance" label="用户余额" align="center"></el-table-column>
				<el-table-column prop="tenantAvailableBalance" label="待提现余额" align="center"></el-table-column>
				<el-table-column prop="tenantPhone" label="租户手机" align="center"></el-table-column>
				<el-table-column prop="tenantEmail" label="租户邮箱" align="center"></el-table-column>
				<el-table-column prop="tenantPoint" label="租户点数" align="center"></el-table-column>

				<el-table-column prop="tenantUsername" label="用户账号" align="center"></el-table-column>
				<el-table-column prop="proxy" label="用户状态" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.tenantState" @change="UpdateTenantState(scope.row)"></el-switch>
					</template>
				</el-table-column>

				<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>

				<el-table-column fixed="right" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="success" size="small" @click="EditTenantUserInfo(scope.row)">编辑</el-button>
						<el-button type="danger" size="small" @click="DeleteTenantUser(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>
	</el-row>

</template>

<script>
	import {
		ApiTenantList,
		ApiTenantAdd,
		ApiTenantDelete,
		ApiTenantEditState,
		ApiTenantEdit
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				params: {
					tenantName: '',
					tenantUsername: '',
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				xtime: '',
				AddUserVisible: false,
				EditUserVisible: false,
				AddUser: {
					tenantName: '',
					tenantUsername: '',
					tenantPassword: '',
					tenantPhone: '',
					tenantEmail: '',
					tenantState: true,
					tenantWebsite:'',
					tenantLogo:''
				},
				EditUser: {
					id: '',
					tenantName: '',
					tenantUsername: '',
					tenantPassword: '',
					tenantPhone: '',
					tenantEmail: '',
					tenantState: '',
					tenantPoint: '',
					tenantWebsite:'',
					tenantLogo:''
				}
			}
		},
		created() {
			this.GetTenantList()
		},
		methods: {
			// 获取租户用户列表
			GetTenantList: function() {
				this.loading = true
				ApiTenantList(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			// 添加用户信息
			AddTenantUserInfo: function() {
				ApiTenantAdd(this.AddUser).then(res => {
					this.$message.success('添加成功!')
					this.AddUserVisible = false
					// 刷新
					this.GetTenantList()
				})
			},
			// 删除租户信息
			DeleteTenantUser: function(row) {
				this.$confirm('此操作将删除该租户记录，是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					ApiTenantDelete({
						id: row.id
					}).then(res => {
						this.$message.success('删除成功!')
						// 刷新列表
						this.GetTenantList()
					})
				}).catch(() => {})
			},
			// 编辑用户信息
			EditTenantUserInfo: function(value) {

				if (value == 'edit') {
					// 提交修改
					ApiTenantEdit(this.EditUser).then(res => {
						this.$message.success('修改成功')
						// 更新结果
						this.GetTenantList()
					})
					this.EditUserVisible = false
				} else {
					// 显示编辑
					this.EditUserVisible = true
					// 绑定数据
					this.EditUser.id = value.id
					this.EditUser.tenantName = value.tenantName
					this.EditUser.tenantUsername = value.tenantUsername
					this.EditUser.tenantPhone = value.tenantPhone
					this.EditUser.tenantEmail = value.tenantEmail
					this.EditUser.tenantState = value.tenantState
					this.EditUser.tenantPoint = value.tenantPoint
					this.EditUser.tenantLogo = value.tenantLogo
					this.EditUser.tenantWebsite = value.tenantWebsite
				}

			},
			// 更新租户状态
			UpdateTenantState: function(row) {
				ApiTenantEditState({
					id: row.id,
					state: row.tenantState
				}).then(res => {
					this.$message.success('更新成功!')
				})
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetTenantList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetTenantList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}
</style>
