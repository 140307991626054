<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>用户充值列表</span></el-alert>
        </el-col>

        <!-- 查询区域 -->
        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">

                <el-form :inline="true" :model="params" label-width="140px">
                    <el-form-item label="充值时间:">
                        <el-date-picker
                                id="datePicker"
                                v-model="xtime"
                                @change="queryTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                clearable
                        />
                    </el-form-item>
                    <el-form-item label="支付单号：">
                        <el-input v-model="params.outTradeNo" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户账号：">
                        <el-input v-model="params.username" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：">
                        <el-input v-model="params.phone" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="支付模式：">
                        <el-select
                                style="width:220px"
                                v-model="params.payPattern"
                                clearable
                        >
                            <el-option label="线上" :value="1"/>
                            <el-option label="线下" :value="2"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付方式：">
                        <el-select
                                style="width:220px"
                                v-model="params.type"
                                clearable
                        >
                            <el-option label="微信" value="WECHAT"/>
                            <el-option label="支付宝" value="ALIPAY"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付状态：">
                        <el-select
                                style="width:220px"
                                v-model="params.result"
                                clearable
                        >
                            <el-option label="支付成功" value="TRADE_SUCCESS"/>
                            <el-option label="待支付" value="WAIT_BUYER_PAY"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                type="primary"
                                @click="handleSearchList()">
                            查询
                        </el-button>
                        <el-button
                                type="primary"
                                @click="handleResetSearch()">
                            重置
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                type="success"
                                @click="add()">
                            新增订单
                        </el-button>
                    </el-form-item>
                  <el-form-item>
                    <el-button
                        type="success"
                        @click="exportExcel()">
                      导出
                    </el-button>
                  </el-form-item>
                </el-form>
            </el-card>
        </el-col>
        <!-- 充值表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="outTradeNo" label="支付单号" width="180"></el-table-column>
                <el-table-column prop="tradeNo" label="支付交易号" width="180"></el-table-column>
                <el-table-column prop="username" label="用户账号" width="180"></el-table-column>
                <el-table-column prop="payPattern" label="支付模式">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payPattern == 1 ">线上</span>
                        <span v-if="scope.row.payPattern == 2 ">线下</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="支付方式">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 'ALIPAY' ">支付宝</span>
                        <span v-if="scope.row.type == 'WECHAT' ">微信</span>
                    </template>
                </el-table-column>
                <el-table-column prop="totalAmount" label="应收金额"></el-table-column>
                <el-table-column prop="payAmount" label="实收金额"></el-table-column>
                <el-table-column prop="payTime" label="支付时间" width="190"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
                <el-table-column prop="payChannel" label="收款渠道">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payChannel == 1 ">企业微信</span>
                        <span v-if="scope.row.payChannel == 2 ">企业支付宝</span>
                    </template>
                </el-table-column>
                <el-table-column prop="payer" label="操作人"></el-table-column>
                <el-table-column prop="markDesc" label="备注"></el-table-column>
                <el-table-column prop="result" label="支付状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.result ==='支付成功' ">支付成功</el-tag>
                        <el-tag type="danger" v-else>{{ scope.row.result }}</el-tag>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.result ==='等待支付' " style="margin-left:15px;" type="success"
                                   size="small" @click="getResultStatus(scope.row,1)">查询结果
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>
        </el-col>
        <el-dialog
                title="添加订单"
                :visible.sync="dialogVisible"
                width="800">
            <el-form :model="saveData" :rules="rules" ref="saveData" label-width="100px" label-position="right"
                     style="padding-top: 10px">
                <el-form-item label="用户账号：" prop="username">
                    <el-input v-model="saveData.username"></el-input>
                </el-form-item>
                <el-form-item label="套餐类型：" prop="totalAmount">
                    <el-select
                            v-model="saveData.totalAmount"
                            prop="type"
                    >
                        <el-option label="9.8" :value="9.8"/>
                        <el-option label="30" :value="30"/>
                        <el-option label="69" :value="69"/>
                        <el-option label="100" :value="100"/>
                        <el-option label="100" :value="100"/>
                        <el-option label="500" :value="500"/>
                        <el-option label="1500" :value="1500"/>
                        <el-option label="2000" :value="2000"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="应收金额：" prop="totalAmount">
                    <el-input v-model="saveData.totalAmount" readonly></el-input>
                </el-form-item>
                <el-form-item label="实收金额：" prop="payAmount">
                    <el-input v-model="saveData.payAmount"></el-input>
                </el-form-item>
                <el-form-item label="收款渠道：" prop="payChannel">
                    <el-select
                            v-model="saveData.payChannel"
                            prop="type"
                    >
                        <el-option label="企业微信" :value="1"/>
                        <el-option label="企业支付宝" :value="2"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="markDesc">
                    <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="saveData.markDesc">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit()">保存</el-button>
                    <el-button @click="dialogVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-row>

</template>

<script>
import {ApiPayByAdmin, ApiRechargeList, ApiRechargeQuery} from '@/request/api'

export default {
        data() {
            return {
                loading: true,
                params: {
                    outTradeNo: '',
                    username: '',
                    phone: '',
                    type: '',
                    payPattern: "",
                    result: '',
                    beginTime: '',
                    endTime: '',
                    limit: 10,
                    page: 1,
                },
                result: [],
                xtime: '',
                saveData: {},
                dialogVisible: false,
                rules: {
                    username: [
                        {required: true, message: '请输入用户账号', trigger: 'blur'}
                    ],
                    totalAmount: [
                        {required: true, message: '请选择套餐', trigger: 'blur'}
                    ],
                    payAmount: [
                        {required: true, message: '请输入实收金额', trigger: 'blur'}
                    ],
                    payChannel: [
                        {required: true, message: '请选择收款渠道', trigger: 'blur'}
                    ],
                    markDesc: [
                        {required: true, message: '请输入备注', trigger: 'blur'}
                    ],
                }
            }
        },
        created() {
            this.GetRechargeList()
        },
        methods: {
            //导出的方法
            async exportExcel() {
              let excelResult;
              let paramsExcel = this.params;
              paramsExcel.pageType = 1;
              if (!paramsExcel.beginTime) {
                this.$message.warning('请输入充值时间')
                return;
              }

              await ApiRechargeList(paramsExcel).then(res => {
                excelResult = res.data
              })
              require.ensure([], () => {
                const { export_json_to_excel } = require('@/excel/Export2Excel');
                const tHeader = ['支付单号', '支付交易号', '用户账号', '支付模式', '支付方式', '应收金额', '实收金额', '支付时间', '创建时间', '收款渠道', '操作人', '备注', '支付状态', '操作'];
                // 上面设置Excel的表格第一行的标题
                const filterVal = ['outTradeNo', 'tradeNo', 'username', 'payPattern', 'type', 'totalAmount', 'payAmount', 'payTime', 'createTime', 'payChannel', 'payer', 'markDesc', 'result', 'right'];
                // 上面的index、nickName、name是tableData里对象的属性
                const data = this.formatJson(filterVal, excelResult);
                export_json_to_excel(tHeader, data, 'pay_excel');
              })
            },
            formatJson(filterVal, jsonData) {
              return jsonData.data.map(v => filterVal.map(j => {
                if (j === 'payPattern') {
                  return v[j] === 1 ? '线上' : '线下'
                } else if (j === 'type') {
                  return v[j] === 'ALIPAY' ? '支付宝' : '微信'
                } else if (j === 'payChannel') {
                  return v[j] === 1 ? '企业微信' : '企业支付宝'
                } else {
                  return v[j]
                }
              }))
            },
            onSubmit() {
                this.$refs["saveData"].validate((valid) => {
                    if (valid) {
                        ApiPayByAdmin(this.saveData).then(res => {
                            if (res.success) {
                                this.dialogVisible = false;
                                this.GetRechargeList()
                                this.$message.success(res.data)
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            add() {
                this.saveData = {};
                this.dialogVisible = true;
            },
            handleResetSearch() {
                this.params = Object.assign({}, {
                    limit: 10,
                    page: 1
                });
                this.xtime = '';
            },
            handleSearchList() {
                this.params.page = 1
                this.GetRechargeList();
            },
            // 获取用户列表
            GetRechargeList: function () {
                this.loading = true
                ApiRechargeList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            async getResultStatus(row) {
                let data = {outTradeNo: row.outTradeNo}
                ApiRechargeQuery(data).then(res => {
                    if (res.data != '支付成功') return this.$alert('尚未支付!', {type: 'warning'})
                    this.GetRechargeList()
                })
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.GetRechargeList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetRechargeList()
            },
            queryTime() {
                if (this.xtime && this.xtime.length == 2) {
                    this.params.beginTime = this.xtime[0]
                    this.params.endTime = this.xtime[1]
                } else {
                    this.params.beginTime = null
                    this.params.endTime = null
                }
            },
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;

        }
    }

    .table-expand {
        font-size: 0;

        .el-form-item {
            margin-right: 0;
            margin-bottom: 0;
            width: 50%;
        }
    }

    .table-expand label {
        width: 90px;
        color: #99a9bf;
    }
</style>
