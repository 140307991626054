<template>

	<el-row :xs="24">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>IP管理</span></el-alert>
		</el-col>

		<el-col>
			<el-card shadow="hover">
				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="AddConfigVisible = true"> 添加IP</el-button>
						</el-col>
					</el-col>

					<el-dialog title="添加配置" width="25%" :visible.sync="AddConfigVisible">
						<div>
							<el-select style="margin-bottom: 10px;width: 100%;" v-model="add_data.area" placeholder="请选择地区">
								<el-option v-for="item in shop_area" :key="item.value" :value="item.value" :label="item.label"></el-option>
							</el-select>
							<el-input style="margin-bottom: 10px;" v-model="add_data.proxyIp" clearable placeholder="代理IP" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="add_data.proxyPort" clearable placeholder="代理端口" prefix-icon="iconfont icon-code"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="add_data.proxyUsername" clearable placeholder="代理用户" prefix-icon="iconfont icon-neirong"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="add_data.proxyPassword" clearable placeholder="代理密码" prefix-icon="iconfont icon-neirong"></el-input>
							<el-button style="width: 100%;" type="primary" @click="AddConfig">添加</el-button>
						</div>
					</el-dialog>

					<el-dialog title="编辑配置" width="25%" :visible.sync="EditConfigVisible">
						<div>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.id" clearable placeholder="id" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.name" clearable placeholder="Name" prefix-icon="iconfont icon-yonghu"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.code" clearable placeholder="Code" prefix-icon="iconfont icon-code"></el-input>
							<el-input style="margin-bottom: 10px;" v-model="edit_data.value" clearable placeholder="Value" prefix-icon="iconfont icon-neirong"></el-input>
							<el-input style="margin-bottom: 10px;" type="textarea" autosize placeholder="remark" v-model="edit_data.remark"></el-input>
							<el-button style="width: 100%;" type="primary" @click="EditConfig">编辑</el-button>
						</div>
					</el-dialog>


					<!-- <el-col :xs="24" :span="12">
						<el-col :xs="24" :md="8">
							<el-input v-model="params.username" :xs="10" :md="10" placeholder="用户" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-input v-model="params.phone" :xs="10" placeholder="手机号码" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetUserList">查询</el-button>
						</el-col>
					</el-col> -->

				</el-row>

			</el-card>
		</el-col>


		<!-- 账单表格 -->
		<el-col style="margin-top:15px;" :xs="24">



			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">

				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" inline class="demo-table-expand">
							<el-form-item label="绑定用户ID">
								<span>{{ props.row.bindUser }}</span>
							</el-form-item>
							<el-form-item label="绑定店铺">
								<span>{{ props.row.bindShop }}</span>
							</el-form-item>
							<el-form-item label="绑定时间">
								<span>{{ props.row.bindTime }}</span>
							</el-form-item>
							<el-form-item label="到期时间">
								<span>{{ props.row.expireTime }}</span>
							</el-form-item>
						</el-form>
					</template>
				</el-table-column>

				<el-table-column prop="area" label="地区" align="center"></el-table-column>
				<el-table-column prop="proxyIp" label="代理IP" align="center"></el-table-column>
				<el-table-column prop="proxyPort" label="代理端口" align="center"></el-table-column>
				<el-table-column prop="proxyUsername" label="用户名" align="center"></el-table-column>
				<el-table-column prop="proxyPassword" label="密码" align="center"></el-table-column>
				<el-table-column prop="bindState" label="绑定状态" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center">
					<template slot-scope="scope">
						<!-- <el-button type="primary" size="small" @click="EditConfigView(scope.$index,scope.row)">编辑</el-button> -->
						<el-button type="danger" size="small" @click="DeleteConfig(scope.row)">删除</el-button>
					</template>
				</el-table-column>


			</el-table>


			<!-- <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="area" label="地区" align="center"></el-table-column>
				<el-table-column prop="proxyIp" label="代理IP" align="center"></el-table-column>
				<el-table-column prop="proxyPort" label="代理端口" align="center"></el-table-column>
				<el-table-column prop="proxyUsername" label="用户名" align="center"></el-table-column>
				<el-table-column prop="proxyPassword" label="密码" align="center"></el-table-column>
				<el-table-column prop="bindState" label="绑定状态" align="center"></el-table-column>
				<el-table-column prop="bindUser" label="绑定用户ID" align="center"></el-table-column>
				<el-table-column prop="bindTime" label="绑定时间" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="EditConfigView(scope.$index,scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table> -->

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="result.count"
				 style="margin-top:15px;margin-bottom: 15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>

	</el-row>

</template>

<script>
	import {
		ApiIpList,
		ApiIpAdd,
		ApiIpDelete
	} from '@/request/api'
	export default {
		data() {
			return {
				loading: false,
				params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				AddConfigVisible: false,
				EditConfigVisible: false,
				shop_area: [{
						value: 'TW',
						label: '台湾',
					},
					{
						value: 'TH',
						label: '泰国',
					},
					{
						value: 'ID',
						label: '印尼',
					},
					{
						value: 'SG',
						label: '新加坡',
					},
					{
						value: 'PH',
						label: '菲律宾',
					},
					{
						value: 'VN',
						label: '越南',
					},
					{
						value: 'MY',
						label: '马来',
					},
					{
						value: 'BR',
						label: '巴西',
					},
				],
				add_data: {
					'area': '',
					'proxyIp': '',
					'proxyPort': '',
					'proxyUsername': '',
					'proxyPassword': '',
				},
				edit_data: {
					'id': '',
					'name': '',
					'value': '',
					'code': '',
					'remark': ''
				}
			}
		},
		created() {
			this.GetIpList()

		},
		methods: {
			GetIpList: function() {
				ApiIpList(this.params).then(res => {
					this.result = res.data
				})
			},
			AddConfig: function() {
				ApiIpAdd(this.add_data).then(res => {
					this.$message.success('添加IP代理成功！')
					this.AddConfigVisible = false
					this.GetIpList()
				})
			},
			DeleteConfig:function(row){
				ApiIpDelete({'id':row.id}).then(res => {
					this.$message.success('删除IP成功！')
					this.GetIpList()
				})
			},
			// EditConfig: function() {
			// 	ApiConfigEdit(this.edit_data).then(res => {
			// 		this.$message.success('配置修改成功！')
			// 		this.EditConfigVisible = false
			// 		this.GetIpList()
			// 	})
			// },
			// EditConfigView: function(index, row) {
			// 	this.EditConfigVisible = true
			// 	this.edit_data.id = row.id
			// 	this.edit_data.name = row.name
			// 	this.edit_data.value = row.value
			// 	this.edit_data.code = row.code
			// 	this.edit_data.remark = row.remark
			// },
			handleSizeChange(value) {
				this.params.limit = value
				this.GetIpList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetIpList()
			},
		}
	}
</script>

<style scoped>
	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #99a9bf;
	}

	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
</style>
