<template>

    <el-row :xs="24">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>下载设置</span></el-alert>
        </el-col>

        <el-col>
            <el-card shadow="hover">
                <el-row :gutter="15">
                    <el-dialog title="全部租户发版" width="25%" :visible.sync="allVisible">
                        <el-form ref="form" :model="form" label-width="80px">
                            <el-form-item label="版本号">
                                <el-input v-model="form.versionCode"></el-input>
                            </el-form-item>
                            <el-form-item label="更新号">
                                <el-input v-model="form.appVersionCode"></el-input>
                            </el-form-item>
                            <el-form-item label="下载前缀">
                                <el-input v-model="form.urlPrefix"></el-input>
                            </el-form-item>
                            <el-form-item label="下载后缀">
                                <el-input v-model="form.urlSuffix"></el-input>
                            </el-form-item>
                            <el-form-item label="更新策略">
                                <el-select v-model="form.appUpdateState" style="width: 100%">
                                    <el-option label="可选更新" :value="1"></el-option>
                                    <el-option label="强制更新" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="更新内容">
                                <el-input type="textarea" v-model="form.appUpdateContent"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit" :loading="btnLoading">立即创建</el-button>
                                <el-button @click="allVisible=false">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-dialog>

                    <el-dialog title="新增/编辑配置" width="25%" :visible.sync="EditConfigVisible">
                        <div>
                            <!--<el-input style="margin-bottom: 10px;" v-model="edit_data.id" clearable placeholder="id"-->
                            <!--prefix-icon="iconfont icon-yonghu"></el-input>-->
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.tenantId" clearable
                                      placeholder="租户ID" prefix-icon="iconfont icon-URLguanli"></el-input>

                            <el-input style="margin-bottom: 10px;" v-model="edit_data.appDownloadUrl" clearable
                                      placeholder="appDownloadUrl" prefix-icon="iconfont icon-URLguanli"></el-input>
                            <el-input style="margin-bottom: 10px;" type="textarea" v-model="edit_data.appUpdateContent"
                                      clearable
                                      placeholder="appUpdateContent" prefix-icon="iconfont icon-neirong"></el-input>
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.appUpdateState" clearable
                                      placeholder="appUpdateState" prefix-icon="iconfont icon-tubiao-"></el-input>
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.appVersionCode" clearable
                                      placeholder="appVersionCode" prefix-icon="iconfont icon-code1"></el-input>
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.appVersionName" clearable
                                      placeholder="appVersionName" prefix-icon="iconfont icon-neirong"></el-input>
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.pcVersion" clearable
                                      placeholder="pcVersion" prefix-icon="iconfont icon-neirong"></el-input>
                            <el-input style="margin-bottom: 10px;" v-model="edit_data.pcDownloadUrl" clearable
                                      placeholder="pcDownloadUrl" prefix-icon="iconfont icon-neirong"></el-input>
                            <el-button style="width: 100%;" type="primary" @click="EditConfig">保存</el-button>
                        </div>
                    </el-dialog>


                    <el-col :xs="24" :span="24">
                        <el-col :xs="24" :md="8">
                            <el-input v-model="params.tenantName" :xs="10" :md="10" placeholder="租户名称"
                                      clearable></el-input>
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <el-input v-model="params.appVersionCode" :xs="10" :md="10" placeholder="版本号"
                                      clearable></el-input>
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <el-button type="primary" icon="el-icon-search" @click="GetConfigList">
                                查询
                            </el-button>
                            <el-button type="success" icon="el-icon-search" @click="add()">新增
                            </el-button>
                            <el-button type="success" icon="el-icon-search" @click="addAll()">一键发版
                            </el-button>
                        </el-col>
                    </el-col>

                </el-row>

            </el-card>
        </el-col>


        <!-- 账单表格 -->
        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="tenantId" label="租户ID" align="center"></el-table-column>
                <el-table-column prop="appVersionName" label="APP版本名称" align="center"></el-table-column>
                <el-table-column prop="appUpdateState" label="APP更新状态" align="center"></el-table-column>
                <el-table-column prop="appUpdateContent" label="APP更新内容" :show-overflow-tooltip="true"
                                 align="center"></el-table-column>
                <el-table-column prop="appDownloadUrl" label="APP下载地址" :show-overflow-tooltip="true"
                                 align="center"></el-table-column>
                <el-table-column prop="pcVersion" label="PC版本" align="center"></el-table-column>
                <el-table-column prop="pcDownloadUrl" label="PC下载地址" :show-overflow-tooltip="true"
                                 align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="EditConfigView(scope.$index,scope.row)">编辑
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-col :xs="12">
                <!-- 分页区域 -->
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10"
                               layout="total, sizes, prev, pager, next, jumper" :total="result.count"
                               style="margin-top:15px;margin-bottom: 15px;text-align:left;">
                </el-pagination>
            </el-col>


        </el-col>

    </el-row>

</template>

<script>
    import {
        ApiAppUpdate,
        ApiAppUpdateEdit,
        ApiQuickUpApp
    } from '@/request/api'

    export default {
        data() {
            return {
                btnLoading: false,
                form: {},
                allVisible: false,
                loading: false,
                params: {
                    tenantName: '',
                    page: 1,
                    limit: 10,
                    beginTime: '',
                    endTime: ''
                },
                result: [],
                AddConfigVisible: false,
                EditConfigVisible: false,
                edit_data: {
                    'id': '',
                    'appDownloadUrl': '',
                    'appUpdateContent': '',
                    'appUpdateState': '',
                    'appVersionCode': '',
                    'appVersionName': '',
                    'pcVersion': '',
                    'pcDownloadUrl': '',
                    tenantId: ""
                }
            }
        },
        created() {
            this.GetConfigList()
        },
        methods: {
            onSubmit() {
                this.btnLoading = true;
                ApiQuickUpApp(this.form).then(res => {
                    this.$message.success(res.data)
                    this.allVisible = false
                    this.btnLoading = false;
                }).catch(err => {
                    this.$message.error(res.msg)
                    this.btnLoading = false;
                })
            },
            addAll() {
                this.allVisible = true;
                this.btnLoading = false;
            },
            add() {
                this.EditConfigVisible = true;
                this.edit_data.id = null;
            },
            GetConfigList: function () {
                ApiAppUpdate(this.params).then(res => {
                    this.result = res.data
                })
            },
            EditConfig: function () {
                ApiAppUpdateEdit(this.edit_data).then(res => {
                    this.$message.success('配置修改成功！')
                    this.EditConfigVisible = false
                    this.GetConfigList()
                })
            },
            EditConfigView: function (index, row) {
                this.EditConfigVisible = true
                this.edit_data.id = row.id
                this.edit_data.appDownloadUrl = row.appDownloadUrl
                this.edit_data.appUpdateContent = row.appUpdateContent
                this.edit_data.appUpdateState = row.appUpdateState
                this.edit_data.appVersionCode = row.appVersionCode
                this.edit_data.appVersionName = row.appVersionName
                this.edit_data.pcVersion = row.pcVersion
                this.edit_data.pcDownloadUrl = row.pcDownloadUrl
                this.edit_data.tenantId = row.tenantId;
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.GetConfigList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.GetConfigList()
            },
        }
    }
</script>

<style scoped>

</style>
