<template>

    <el-row>

        <el-col>
            <el-alert type="success" :closable="false"><span>账户充值</span></el-alert>
        </el-col>

        <el-col style="margin-top:15px;">
            <!-- <el-steps :active="1" finish-status="success" simple style="margin-top: 20px"> -->
                <!-- <el-step title="选择充值金额" > -->
<!--                      -->
                <!-- </el-step> -->
                <!-- <el-step title="选择充值方式" > -->
<!--                      -->
                <!-- </el-step> -->
                <!-- <el-step title="完成充值" > -->
<!--                      -->
                <!-- </el-step> -->
            <!-- </el-steps> -->

            <el-card shadow="hover">
                <el-form>

                    <!-- <el-form-item label="充值金额"> -->
                        <!-- <el-input-number v-model="pay.totalAmount" @change="handleChange" :step="10" :min="10" :max="10000" label="充值金额"></el-input-number> -->
                    <!-- </el-form-item> -->

                    <el-form-item label="充值金额">
                        <el-radio v-model="pay.totalAmount" label="0.01" border>0.01元</el-radio>
                        <el-radio v-model="pay.totalAmount" label="100" border>100元</el-radio>
                        <el-radio v-model="pay.totalAmount" label="500" border>500元</el-radio>
                        <el-radio v-model="pay.totalAmount" label="1000" border>1000元</el-radio>
                        <!-- <el-button plain>200元</el-button> -->
                        <!-- <el-button plain>500元</el-button> -->
                        <!-- <el-button plain>1000元</el-button> -->
                    </el-form-item>

                    <el-form-item label="充值方式">
                        <el-button type="primary" icon="iconfont icon-zhifubao"></el-button>
                    </el-form-item>

                    <el-form-item label="充值须知">
                        <el-link type="danger">《虾皮屋充值协议》</el-link>
                    </el-form-item>

                    <el-form-item label="勾选协议">
                        <el-checkbox @change="isRead">我已阅读充值协议</el-checkbox>
                    </el-form-item>

                   <el-form-item label="">
                       <el-button type="primary" :disabled="RechargeBtnStatus" @click="Pay">提交充值</el-button>
                   </el-form-item>


                </el-form>
            </el-card>

            <!-- 展示面对面图片dialog -->
            <el-dialog title="支付宝扫码充值" width="20%" min-width="300" class="clearfix" :visible.sync="dialogVisible">
                <!-- 充值图片 -->
                    <el-image style="width: 200px; height: 200px" :src="payImage" fit="fit"></el-image>
                    <div>
                        <el-button type="primary" @click="jumpRechargeList">充值记录</el-button>
                        <el-button type="success" @click="getResultStatus">查看结果</el-button>
                        <p>如已完成扫码并支付，可忽略本页面！</p>
                    </div>
            </el-dialog>

        </el-col>

    </el-row>

</template>

<script>

import { ApiRechargeAlipay , ApiRechargeQuery } from '@/request/api'

export default {
    data(){
        return{
            pay:{
                totalAmount:'0.01'
            },
            payImage:'',
            outTradeNo:'',
            RechargeBtnStatus:true,
            dialogVisible:false,
        }
    },
    methods:{
        // 请求支付
        async Pay(){
            ApiRechargeAlipay(this.pay).then(res => {
                // if(res.code !== 200) return this.$message.error(res.message)
                this.payImage = res.data.url
                this.outTradeNo = res.data.outTradeNo
                this.dialogVisible = true
            })      
        },
        isRead(value){
            // 阅读协议
            this.RechargeBtnStatus = !this.RechargeBtnStatus
        },
        jumpRechargeList(){
            this.$router.push('/Rechargelist')
        },
        // 查询结果
        async getResultStatus(){
            let data = {outTradeNo:this.outTradeNo}
            ApiRechargeQuery(data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                if(res.data != 'TRADE_SUCCESS')return this.$alert('尚未支付!',{type:'warning'})
                this.$router.push('/Rechargelist')
                this.dialogVisible = false
            })
        }
    },
    created(){
    }
}
</script>

<style lang="less" scoped>
.el-form{
    
    display: block;
    text-align: center;
    
}
.el-form-item{
    display: flex;
    align-items: center;
    //padding: 0 40%;
}
.el-radio{
    margin-right: 15px;
}
.el-dialog{
    height: 500px;

}
</style>
