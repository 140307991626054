<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>图片弹框列表管理</span></el-alert>
        </el-col>

        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">
                <el-form :inline="true" :model="params" label-width="140px">
                    <el-form-item label="面向用户群体：">
                        <el-select
                                style="width:220px"
                                v-model="params.levelName"
                                clearable>
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="活动名称：">
                        <el-input v-model="params.msgTitle" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>

                    <el-form-item label="状态：">
                        <el-select
                                style="width:220px"
                                v-model="params.msgStatus"
                                clearable
                        >
                            <el-option label="上线" :value="1"/>
                            <el-option label="下线" :value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="操作日期:">
                        <el-date-picker
                                id="datePicker"
                                v-model="xtime"
                                @change="queryTime"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                clearable
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                style="float:right"
                                type="primary"
                                @click="handleSearchList()">
                            查询
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                style="float:right;margin-right: 15px"
                                type="primary"
                                @click="edit({})">
                            添加
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-col>

        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="msgTitle" label="活动名称" align="center"></el-table-column>
                <el-table-column prop="imgUrl" label="图片链接" align="center">
                </el-table-column>
                <el-table-column prop="linkUrl" label="跳转链接" align="center">
                </el-table-column>
                <el-table-column prop="levelName" label="面向用户群体" align="center">
                    <template slot-scope="scope">
                        {{concatName(scope.row)}}
                    </template>
                </el-table-column>
                <el-table-column prop="msgStatus" label="状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.msgStatus ===1 ">上线</span>
                        <span v-else>下线</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sortNum" label="置顶序号" align="center"></el-table-column>
                <el-table-column prop="operationTime" label="操作时间" align="center"></el-table-column>
                <el-table-column prop="operationUser" label="操作人" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="scope.row.msgStatus !=1" @click="release(scope.row)">
                            发布
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.msgStatus ===1" @click="reCall(scope.row)">
                            撤回
                        </el-button>
                        <el-button type="text" size="small" @click="top(scope.row)">置顶</el-button>
                        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>

        </el-col>
        <el-dialog
                title="通知新增/编辑"
                :visible.sync="dialogVisible"
                width="800px">
            <el-form :model="saveData" :rules="rules" ref="saveData" label-width="120px"
                     style="padding-top: 10px">
                <el-form-item label="活动名称" prop="msgTitle">
                    <el-input v-model="saveData.msgTitle"></el-input>
                </el-form-item>
                <el-form-item label="图片链接" prop="imgUrl">
                    <el-input v-model="saveData.imgUrl"></el-input>
                </el-form-item>
                <el-form-item label="跳转链接" prop="linkUrl">
                    <el-input v-model="saveData.linkUrl"></el-input>
                </el-form-item>
                <el-form-item label="面向用户群体：" prop="levelName">
                    <el-select
                            style="width:100%"
                            v-model="saveData.levelName"
                            multiple
                            clearable>
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit()">保存</el-button>
                    <el-button @click="dialogVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-row>

</template>

<script>
    import {
        ApiPopupImgList,
        ApiPopupImgSave,
        ApiPopupImgRemove,
        ApiPopupImgRelease,
        ApiPopupImgReCall,
        ApiPopupImgTop
    } from '@/request/api'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    export default {
        components: {},
        data() {
            return {
                options: [{
                    value: '0',
                    label: '全部用户'
                }, {
                    value: '1',
                    label: '免费版'
                }, {
                    value: '2',
                    label: '普通版'
                }, {
                    value: '3',
                    label: '高级版'
                }, {
                    value: '4',
                    label: '豪华版'
                }, {
                    value: '5',
                    label: '专业版'
                }, {
                    value: '6',
                    label: '未绑定店铺的免费版用户'
                }, {
                    value: '7',
                    label: '已绑定店铺的免费版用户'
                }, {
                    value: '8',
                    label: '7天快到期的已付费用户'
                }],
                xtime: '',
                loading: false,
                params: {
                    msgTitle: '',
                    page: 1,
                    limit: 10,
                    msgStatus: '',
                },
                result: {},
                saveData: {},
                dialogVisible: false,
                rules: {
                    msgTitle: [
                        {required: true, message: '请输入活动名称', trigger: 'blur'}
                    ],
                    imgUrl: [
                        {required: true, message: '请输入图片链接', trigger: 'blur'}
                    ],
                    linkUrl: [
                        {required: true, message: '请输入跳转链接', trigger: 'blur'}
                    ],
                    levelName: [
                        {required: true, message: '请选择面向群体用户', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {

        },
        created() {
            this.handleSearchList()
        },
        methods: {
            top(row) {
                ApiPopupImgTop(row).then(res => {
                    if (res.success) {
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            concatName(row) {
                let name = "";
                if (row['levelName'])
                    this.options.filter(item => {
                        return row['levelName'].indexOf(item.value) != -1
                    }).forEach(item => {
                        name += item['label'] + ","
                    })
                return name.substring(0, name.lastIndexOf(","))
            },
            queryTime() {
                if (this.xtime && this.xtime.length == 2) {
                    this.params.beginTime = this.xtime[0]
                    this.params.endTime = this.xtime[1]
                } else {
                    this.params.beginTime = null
                    this.params.endTime = null
                }
            },
            reCall(row) {
                ApiPopupImgReCall(row).then(res => {
                    if (res.success) {
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            handleSearchList() {
                this.params.page = 1
                this.getPageList()
            },
            // 获取公告列表
            getPageList: function () {
                this.loading = true
                ApiPopupImgList(this.params).then(res => {
                    this.result = res.data
                })
                this.loading = false
            },
            release(row) {
                ApiPopupImgRelease(row).then(res => {
                    if (res.success) {
                        this.getPageList()
                        this.$message.success(res.data)
                    }
                });
            },
            handleSubmit() {
                this.$refs['saveData'].validate((valid) => {
                    if (valid) {
                        let param = JSON.parse(JSON.stringify(this.saveData));
                        param['levelName'] = this.saveData['levelName'].join(",");
                        ApiPopupImgSave(param).then(res => {
                            if (res.success) {
                                this.dialogVisible = false;
                                this.getPageList()
                                this.$message.success(res.data)
                            }
                        });
                    }
                });
            },
            edit(row) {
                this.saveData = {
                    id: row.id,
                    msgTitle: row.msgTitle,
                    imgUrl: row.imgUrl,
                    linkUrl: row.linkUrl,
                    levelName: row.levelName ? row.levelName.toString().split(",") : ""
                };
                this.dialogVisible = true;
            },
            remove(row) {
                this.$confirm('确认删除？')
                    .then(() => {
                        ApiPopupImgRemove(row).then(res => {
                            if (res.success) {
                                this.getPageList()
                                this.$message.success(res.data)
                            }
                        });
                    })
                    .catch(_ => {
                    });
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            }
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
