<template>
	<el-row>

		<el-col>
			<el-alert type="success" :closable="false"><span>欢迎您回来，<em style="font-size:14px;">{{userinfo.username}}</em></span></el-alert>
		</el-col>

		<el-row :gutter="15">
			<!-- left -->
			<el-col class="hidden-sm-and-down" :md="24" :span="12">
				<!-- 消费趋势 -->
				<el-col :md="12" :span="12" style="padding-left: 0;">
					<el-card shadow="hover">
						<div class="card-header">
							<span>消费趋势</span>
							<el-link type="primary">查看 ></el-link>
						</div>
						<div>
							<div id="main" style="height:150px;margin-top:10px;"></div>
						</div>
					</el-card>
				</el-col>
				<!-- 用户群体 -->
				<el-col :md="12" :span="12" style="padding-right: 0;">
					<el-card shadow="hover">
						<div class="card-header">
							<span>用户群体</span>
							<el-link type="primary">查看 ></el-link>
						</div>
						<div>
							<div id="usermain" style="height:150px;margin-top:10px;"></div>
						</div>
					</el-card>
				</el-col>
			</el-col>
			<!-- right -->
			<el-col :xs="24" :sm="24" :md="24" :span="12" class="right_info" style="padding-left: 0; padding-right: 0;">
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<!-- 用户数量 -->
						<img :src="images.user">
						<p>用户:12121</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<!-- 店铺数量 -->
						<img :src="images.shop">
						<p>店铺:1211</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<!-- 任务数量 -->
						<img :src="images.renwu">
						<p>任务:121212</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<img :src="images.notice">
						<p>公告:12121</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<img :src="images.like">
						<p>点赞:12121</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<img :src="images.like">
						<p>点赞:12121</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<img :src="images.like">
						<p>点赞:12121</p>
					</el-card>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6">
					<el-card shadow="hover">
						<img :src="images.like">
						<p>点赞:12121</p>
					</el-card>
				</el-col>
			</el-col>



		</el-row>

	</el-row>
</template>

<script>
	import echarts from 'echarts'
	import {
		ApiPersonalInfo,
		ApiPersonalChangeCustomer,
		ApiPersonalChangePhone,
		ApiPersonalCustomerList
	} from '@/request/api'

	export default {
		data() {
			return {
				images: {
					'user': require('@/assets/images/user.png'),
					'shop': require('@/assets/images/shop.png'),
					'renwu': require('@/assets/images/renwu.png'),
					'notice':require('@/assets/images/notice.png'),
					'like':require('@/assets/images/like.png'),
				},
				userinfo: [],
				kefuinfo: [],
				imageUrl: '',
				Authorization: '',
				modifyData: {
					phone: '',
					password: ''
				},
				dialogVisible: false,
				kefuVisible: false, //客服
				kefulist: [],
				putinfo: {
					customerId: ''
				}
			}
		},
		mounted() {

		},
		created() {
			this.getUserinfo()
		},
		methods: {

			setEcharts() {
				var myChart = echarts.init(document.getElementById('main'));
				var option = {
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)'
					},
					legend: {
						orient: 'vertical',
						left: 10,
						data: ['消费记录', '充值记录', ]
					},
					series: [{
						name: '数据图表',
						type: 'pie',
						radius: ['50%', '70%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '14',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: this.userinfo.consumptionAmount,
								name: '消费记录'
							},
							{
								value: this.userinfo.rechargeAmount,
								name: '充值记录'
							},
						]
					}]
				};
				myChart.setOption(option);
			},
			// 获取用户信息
			async getUserinfo() {
				ApiPersonalInfo().then(res => {
					this.userinfo = res.data
					this.kefuinfo = res.data.customerServiceInfo
					this.setEcharts()
				})
			},

			
		}
	}
</script>

<style lang="less" scoped>
	
	.right_info{
		.el-card{
			text-align: center;
			p{
				padding: 0;
				margin: 10px 0;
			}
		}
	}
	
	
	.el-col {
		margin-bottom: 15px;
	}

	.info {
		height: 100%;
		.el-card {
			height: 100%;
		}
	}

	.card-header {
		padding: 0;
		display: flex;
		justify-content: space-between;

		div {
			color: #ccc;

			span {
				color: red;
			}
		}
	}


</style>
