<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您的卡密列表</span></el-alert>
        </el-col>
        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                
                <el-input v-model="pay.cardKey" placeholder="卡密" clearable></el-input>
                <el-input v-model="pay.useUser" placeholder="使用用户" clearable></el-input>

                <el-select v-model="pay.state" placeholder="卡密状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    :picker-options="pickerOptions"
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="search">搜索结果</el-button>

            </el-card>
        </el-col>
        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="cardKey" label="卡密" width="280"></el-table-column>
                <el-table-column prop="price" label="价格" align="center"></el-table-column>
                <el-table-column prop="state" label="使用状态" width="150"></el-table-column>
                <el-table-column prop="generator" label="购买用户" width="150" align="center"></el-table-column>
                <el-table-column prop="useUser" label="使用用户" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" @click="useCardMessageBox(scope.$index,scope.row)" >使用</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.pay.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiCardKeyList , ApiCardKeyUse } from '@/request/api'

export default {
    data(){
        return{
            payStatus:[
                {value:'UNUSED',label:'未使用'},
                {value:'USE',label:'已使用'},
            ],
            loading:true,
            pay:{
                useUser:'',
                state:'',
                cardKey:'',
                beginTime:'',
                endTime:'',
                limit:10,
                page:1,
            },
            xtime:'',
            result:[],
            total:0,
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
        }
    },
    created(){
        this.getCardList()
    },
    methods:{
        // 获取卡密列表
        async getCardList(){
            this.loading = true
            ApiCardKeyList(this.pay).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.total = res.data.count
                this.result = res.data.data
            })
            this.loading = false
        },
        search(){
            // 搜索
            this.getCardList()
        },
        // 更改数量
        handleSizeChange(value){
            this.pay.limit = value
        },
        // 更改页数
        handleCurrentChange(value){
            this.pay.page = value
        },
        // 更改时间
        queryTime(){
            this.pay.beginTime = this.xtime[0]
            this.pay.endTime = this.xtime[1]
        },
        // 使用卡密
        async useCard(index,row){
            let data = {
                'cardKey':row.cardKey
            }
            ApiCardKeyUse(data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$message.success('激活成功!')
            })
        },
        // 使用卡密
        useCardMessageBox(index,row){
            this.$confirm('您确定要为自己账号使用该卡密吗？', '使用提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { 
                this.useCard(index,row)
            }).catch(() => {
                return
            });
        }
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: left;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>