import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import './plugins/element.js'
import './assets/css/main.css'
import './assets/font/iconfont.css'
import qs from 'qs'
import {options} from 'less'
import 'element-ui/lib/theme-chalk/display.css';
import store from './store'

import Text from './request/config.js'

Vue.use(Vant)
Vue.prototype.$qs = qs
Vue.config.productionTip = false

// 文字
Vue.prototype.logo_title = '知虾聊聊APP管理后台-萌啦科技'

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
