<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您的Shopee店铺列表</span></el-alert>
        </el-col>
        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                                
                <el-input v-model="data.shopName" placeholder="店铺名称"></el-input>
                <el-input v-model="data.shopUsername" placeholder="店铺账号 / 手机号 / 邮箱"></el-input>

                <!-- <el-select v-model="pay.state" placeholder="所属站点" clearable> -->
                    <!-- <el-option -->
                        <!-- v-for="item in area" -->
                        <!-- :key="item.value" -->
                        <!-- :label="item.label" -->
                        <!-- :value="item.value"> -->
                    <!-- </el-option> -->
                <!-- </el-select> -->
                <!--  -->

                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    :picker-options="pickerOptions"
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="search">搜索结果</el-button>
                <el-button type="warning" icon="el-icon-circle-plus" @click="showAdd = true">添加店铺</el-button>

            </el-card>
        </el-col>
        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <!-- <el-table-column prop="orderId" label="系统用户"></el-table-column> -->
                <el-table-column prop="shopUsername" label="店铺账号"></el-table-column>
                <el-table-column prop="shopCountry" label="所属站点" width="250">
                    <template slot-scope="scope">
                        <el-tag>{{ resultArea(scope.row.shopCountry) }} </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="shopUid" label="UID"></el-table-column>
                <el-table-column prop="shopId" label="SHOPID"></el-table-column>
                <el-table-column prop="shopType" label="店铺类型"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间"></el-table-column>

                <el-table-column fixed="right" label="操作" align="center" width="300">

                    <template slot-scope="scope">
                        
                        <el-button style="margin-left:15px;" type="success" size="small" @click="goBuy(scope.$index,scope.row,1)" >修改授权</el-button>
                        <el-button style="margin-left:15px;" type="danger" size="small" @click="goBuy(scope.$index,scope.row,2)" >删除授权</el-button>

                    </template>

                </el-table-column>

            </el-table>

            <!-- 添加店铺 -->
            <el-dialog title="添加授权店铺" width="20%" :visible.sync="showAdd">
                <div class="addStores" style="width:80%;margin:0 auto;">
                    <el-input disabled type="hidden"></el-input>
                    <!-- 地区 -->
                    <el-select style="width:100%;" v-model="add_data.shopCountry" placeholder="所属站点" clearable>
                        <el-option
                            v-for="item in area"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select style="width:100%;" v-model="add_data.shopType" placeholder="账号类型" clearable>
                        <el-option
                            v-for="item in shopType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- 账号 -->
                    <el-input v-model="add_data.shopUsername" clearable placeholder="店铺账号 / 邮箱 / 电话"></el-input>
                    <!-- 密码 -->
                    <el-input v-model="add_data.shopPassword" clearable show-password placeholder="店铺密码"></el-input>
                    <!-- 提交 -->
                    <el-button type="primary" @click="add">添加店铺</el-button>
                </div>
            </el-dialog>


            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.data.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiShopAdd , ApiShopList } from '@/request/api'

export default {
    data(){
        return{
            showAdd:false,
            area:[
                {value:'TW',label:'台湾'},
                {value:'TH',label:'泰国'},
                {value:'ID',label:'印尼'},
                {value:'PH',label:'菲律宾'},
                {value:'MY',label:'马来西亚'},
                {value:'BR',label:'巴西'},
                {value:'VN',label:'越南'},
                {value:'SG',label:'新加坡'},
            ],
            shopType:[
                {value:'CROSS',label:'跨境'},
                {value:'LOCAL',label:'本土'},
            ],
            loading:false,
            // 添加店铺
            add_data:{
                shopUsername:'',
                shopPassword:'',
                shopCountry:'',
                shopType:''
            },
            // 搜索条件
            data:{
                shopUsername:'',
                shopName:'',
                beginTime:'',
                endTime:'',
                limit:10,
                page:1,
            },

            xtime:'',
            result:[],
            total:0,
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
        }
    },
    created(){
        this.getShoplist()
    },
    methods:{
        //  添加店铺 
        async add(){
            ApiShopAdd(this.add_data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$message.success('添加成功')
                this.showAdd = false
                this.getShoplist()
            })
        },
        // 查询店铺列表
        async getShoplist(){
            ApiShopList(this.data).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                // 显示店铺列表
                this.result = res.data.data
                this.total = res.data.count
            })
        },
        search(){
            // 搜索
            this.getShoplist()
        },
        // 返回地区
        resultArea(value){
            let area = ''
            if(value == 'https://seller.th.shopee.cn/')
                area = '泰国'
            else if(value == 'https://seller.xiapi.shopee.cn/')
                area = '台湾'
            else if(value == 'https://seller.sg.shopee.cn/')
                area = '新加坡'
            else if(value == 'https://seller.ph.shopee.cn/')
                area = '菲律宾'
            else if(value == 'https://seller.br.shopee.cn/')
                area = '巴西'
            else if(value == 'https://seller.my.shopee.cn/')
                area = '马来'
            else if(value == 'https://seller.vn.shopee.cn/')
                area = '越南'
            else if(value == 'https://seller.id.shopee.cn/')
                area = '印尼'
            return area
        },
        // 更改数量
        handleSizeChange(value){
            this.data.limit = value
        },
        // 更改页数
        handleCurrentChange(value){
            this.data.page = value
        },
        // 更改时间
        queryTime(){
            this.data.beginTime = this.xtime[0]
            this.data.endTime = this.xtime[1]
        },

        // 使用卡密
        useCard(index,row){
            this.$confirm('您确定要为自己账号使用该卡密吗？', '使用提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { 
                //this.use(index,row)
            }).catch(() => {
                return
            });
        }
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: right;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
.addStores{
    .el-select{
        margin-bottom: 15px;
    }
    .el-input{
        margin-bottom: 15px;
    }
    .el-button{
        width: 100%;
    }
}
</style>