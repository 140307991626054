<template>
  <el-row :md="24" :gutter="15">

    <el-col style="margin-bottom: 15px;">
      <el-alert type="success" :closable="false"><span>消费记录列表</span></el-alert>
    </el-col>

    <!-- 查询区域 -->
    <el-col>
      <el-card shadow="hover" class="hidden-xs-only">
        <el-form :inline="true" :model="params" label-width="140px">
          <el-form-item label="消费时间:">
            <el-date-picker
                id="datePicker"
                v-model="xtime"
                @change="queryTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
            />
          </el-form-item>

          <el-form-item label="用户账号：">
            <el-input v-model="params.username" class="input-width" placeholder="请输入"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input v-model="params.phone" class="input-width" placeholder="请输入"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="支付模式：">
            <el-select
                style="width:220px"
                v-model="params.payPattern"
                clearable
            >
              <el-option label="线上" :value="1"/>
              <el-option label="线下" :value="2"/>
            </el-select>
          </el-form-item>
          <el-form-item label="消费类型：">
            <el-select
                style="width:220px"
                v-model="params.type"
                clearable
            >
              <el-option label="开通会员" value="BUY_APP"/>
              <el-option label="小语种翻译" value="CHAT_TRANSLATE_SMALL"/>
              <el-option label="字符翻译" value="CHAT_TRANSLATE"/>
            </el-select>
          </el-form-item>
          <el-form-item label="消费套餐：">
            <el-select
                style="width:220px"
                v-model="params.tc"
                clearable
            >
              <el-option label="普通会员" value="普通会员"/>
              <el-option label="高级会员" value="高级会员"/>
              <el-option label="豪华会员" value="豪华会员"/>
              <el-option label="专业会员" value="专业会员"/>
              <el-option label="翻译1W字" value="翻译1W字"/>
              <el-option label="小语种翻译1W字" value="小语种翻译1W字"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
                type="primary"
                @click="handleSearchList()">
              查询
            </el-button>
            <el-button
                type="primary"
                @click="handleResetSearch()">
              重置
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                type="success"
                @click="add()">
              新增订单
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                type="success"
                @click="exportExcel()">
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>

    <!-- 充值表格 -->
    <el-col style="margin-top:15px;" :xs="24">
      <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
        <el-table-column type="index" label="序号"></el-table-column>

        <el-table-column prop="orderId" label="消费单号"></el-table-column>
        <el-table-column prop="username" label="用户账号"></el-table-column>
        <el-table-column prop="type" label="消费类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type ==='BUY_APP'">开通会员</span>
            <span v-if="scope.row.type ==='CHAT_TRANSLATE_SMALL'">小语种翻译</span>
            <span v-if="scope.row.type ==='CHAT_TRANSLATE'">字符翻译</span>
          </template>
        </el-table-column>
        <el-table-column prop="tc" label="消费套餐"></el-table-column>

        <el-table-column prop="payPattern" label="支付模式">
          <template slot-scope="scope">
            <span v-if="scope.row.payPattern == 1 ">线上</span>
            <span v-if="scope.row.payPattern == 2 ">线下</span>
          </template>
        </el-table-column>

        <el-table-column prop="totalBalance" label="应收金额"></el-table-column>
        <el-table-column prop="changeBalance" label="实收金额"></el-table-column>
        <el-table-column prop="createTime" label="消费时间"></el-table-column>
        <el-table-column prop="endDate" label="过期时间"></el-table-column>
        <el-table-column prop="createUser" label="操作人"></el-table-column>
        <el-table-column prop="beforeBalance" label="原金额"></el-table-column>
        <el-table-column prop="afterBalance" label="消费余额"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>

      <el-col :xs="12">
        <!-- 分页区域 -->
        <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="this.params.page"
                       :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                       :total="result.count" style="margin-top:15px;text-align:left;">
        </el-pagination>
      </el-col>
    </el-col>
    <el-dialog
        title="添加订单"
        :visible.sync="dialogVisible"
        width="800">
      <el-form :model="saveData" :rules="rules" ref="saveData" label-width="100px" label-position="right"
               style="padding-top: 10px">
        <el-form-item label="用户账号：" prop="username">
          <el-input v-model="saveData.username"></el-input>
        </el-form-item>
        <el-form-item label="套餐类型：" prop="levelId">
          <el-select
              v-model="saveData.levelId"
              prop="type"
          >
            <el-option v-for="item in levelList" :key="item.id" :value="item.id"
                       :label="item.levelName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应收金额：" prop="totalAmount">
          <el-input :value="getLevelPrice()" readonly></el-input>
        </el-form-item>
        <el-form-item label="实收金额：" prop="payAmount">
          <el-input v-model="saveData.payAmount"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="markDesc">
          <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="saveData.markDesc">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">保存</el-button>
          <el-button @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-row>

</template>

<script>
import {
  ApiConsumeLogList, ApiGetLevelList, ApiBuyVipByAdmin, ApiRechargeList
} from '@/request/api'

export default {
  data() {
    return {
      loading: true,
      params: {
        username: '',
        phone: '',
        payPattern: "",
        type: "",
        tc: "",
        beginTime: '',
        endTime: '',
        limit: 10,
        page: 1,
      },
      result: [],
      xtime: '',
      dialogVisible: false,
      saveData: {},
      rules: {
        username: [
          {required: true, message: '请输入用户账号', trigger: 'blur'}
        ],
        totalAmount: [
          {required: true, message: '请选择套餐', trigger: 'blur'}
        ],
        payAmount: [
          {required: true, message: '请输入实收金额', trigger: 'blur'}
        ],
        markDesc: [
          {required: true, message: '请输入备注', trigger: 'blur'}
        ],
      },
      levelList: []
    }
  },
  created() {
    this.getLevelList();
    this.GetConsumptionLogList()
  },
  methods: {
    //导出的方法
    async exportExcel() {
      let excelResult;
      let paramsExcel = this.params;
      paramsExcel.pageType = 1;
      if (!paramsExcel.beginTime) {
        this.$message.warning('请输入消费时间')
        return;
      }
      await ApiConsumeLogList(paramsExcel).then(res => {
        excelResult = res.data
      })
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/excel/Export2Excel');
        const tHeader = ['消费单号', '用户账号', '消费类型', '消费套餐', '支付模式', '应收金额', '实收金额', '消费时间', '过期时间', '注册时间', '激活时间', '来源', '操作人', '原金额', '消费金额', '备注'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['orderId', 'username', 'type', 'tc', 'payPattern', 'totalBalance', 'changeBalance', 'createTime', 'endDate', 'registrationTime', 'loginTime', 'channel', 'createUser', 'beforeBalance', 'afterBalance', 'remark'];
        // 上面的index、nickName、name是tableData里对象的属性
        const data = this.formatJson(filterVal, excelResult);
        export_json_to_excel(tHeader, data, 'consumption_excel');
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.data.map(v => filterVal.map(j => {
        if (j === 'payPattern') {
          return v[j] === 1 ? '线上' : '线下'
        } else if (j === 'type') {
          return v[j] === 'BUY_APP' ? '开通会员' : v[j] === 'CHAT_TRANSLATE_SMALL' ? '小语种翻译' : '字符翻译'
        } else if (j === 'channel') {
          return v[j] === 0 ? '聊聊app' : v[j] === 1 ? '知虾存量' : v[j] === 2 ? '知虾增量' : v[j] === 3 ? '虾扑存量' : '虾扑增量';
        } else {
          return v[j]
        }
      }))
    },
    getLevelPrice() {
      let price = null;
      this.levelList.forEach(level => {
        if (this.saveData['levelId'] == level['id']) {
          price = level.price;
          return true;
        }
      })
      this.saveData['totalAmount'] = price;
      return price;
    },
    getLevelList() {
      ApiGetLevelList().then(res => {
        this.levelList = res.data;
      })
    },
    onSubmit() {
      this.$refs["saveData"].validate((valid) => {
        if (valid) {
          ApiBuyVipByAdmin(this.saveData).then(res => {
            if (res.success) {
              this.dialogVisible = false;
              this.GetConsumptionLogList()
              this.$message.success(res.data)
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    add() {
      this.saveData = {};
      this.dialogVisible = true;
    },
    handleResetSearch() {
      this.params = Object.assign({}, {
        limit: 10,
        page: 1
      });
      this.xtime = '';
    },
    handleSearchList() {
      this.params.page = 1
      this.GetConsumptionLogList();
    },
    // 获取用户列表
    GetConsumptionLogList: function () {
      this.loading = true
      ApiConsumeLogList(this.params).then(res => {
        this.result = res.data
      })
      this.loading = false
    },
    handleSizeChange(value) {
      this.params.limit = value
      this.GetConsumptionLogList()
    },
    handleCurrentChange(value) {
      this.params.page = value
      this.GetConsumptionLogList()
    },
    queryTime() {
      if (this.xtime && this.xtime.length == 2) {
        this.params.beginTime = this.xtime[0]
        this.params.endTime = this.xtime[1]
      } else {
        this.params.beginTime = null
        this.params.endTime = null
      }
    },
  }
}
</script>


<style lang="less" scoped>
.iconfont {
  margin-right: 15px;
}

.AddUserClass {
  .el-input {
    margin-bottom: 10px;
  }

  .el-button {
    width: 100%;

  }
}

.table-expand {
  font-size: 0;

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
}

.table-expand label {
  width: 90px;
  color: #99a9bf;
}
</style>
