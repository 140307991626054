// axios请求库
import axios from 'axios'
import {
    Message
} from 'element-ui';
// 转类型
import qs from 'qs'
// 提示框
import {
    Toast
} from 'vant'
import router from '../router';
import {baseURL} from './config';

// axios.defaults.baseURL = "https://zxchat.menglar.com/admin/v1"
// axios.defaults.baseURL = "https://zxchat.pre.menglar.com/admin/v1"

axios.defaults.baseURL = baseURL;

axios.defaults.timeout = 300000; //5分钟

// 请求拦截
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = "Bearer " + window.sessionStorage.getItem('token')
        // config.headers.JSESSIONID = ""
        return config
    },
    error => {
        return Promise.error(error)
    }
)

// 响应拦截
axios.interceptors.response.use(
    response => {
        // 状态码 = 200
        if (response.status === 200) {
            if (response.data.code == 0) {
                return Promise.resolve(response)
            } else {
                Message.error(response.data.msg);
            }
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 404:
                    router.push('/home')
                    break;
                case 401:
                    // 判断里面状态
                    if (error.response.data.code === 1403) {
                        Message.error(error.response.data.message)
                    } else {
                        Message.error("登录验证已失效，请重新登录!")
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        })
                    }

                    break;
                case 400:
                    Message.error(error.response.data.message)
                    break;
                default:
                    Message.error(error.response.data.message)
            }
            return Promise.reject(error.response)
        }
    }
)

/**
 * get ,url,params
 *
 * @export
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
export function get(url, params) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function getData(url, p) {
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * post ,url,params
 *
 * @export
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(params))
            .then(res => {
                resolve(res.data)
            }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * put url,params
 *
 * @export
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, qs.stringify(params))
            .then(res => {
                resolve(res.data)
            }).catch(err => {
            reject(err.data)
        })
    })
}


export function del(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function download(url, params) {
    return axios.create({
        baseURL: baseURL,
        timeout: 600000,
        headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem('token')
        }
    }).post(url, params, {responseType: 'blob'});
}
