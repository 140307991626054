/**
 * 报表 API统一管理
 */
import {get, getData, post, put, del, download} from './http'

//获取会员等级列表
export const ApiUserLevelList = p => get('report/userLevelList', p);
//获取租户列表
export const ApiTenantList = p => get('report/tenantList', p);

//用户分析报表
export const ApiUserAnalysisList = p => get('report/userAnalysis', p);
export const ApiUserAnalysisExport = p => download('report/userAnalysisExport', p);
//用户列表导出
export const ApiUserListExport = p => download('user/userListExport', p);

//租户分析报表
export const ApiTenantAnalysisList = p => get('report/tenantAnalysis', p);
export const ApiTenantAnalysisExport = p => download('report/tenantAnalysisExport', p);

//分享记录
export const ApiShareList = p => post('report/shareList', p)
//翻译记录
export const ApiTranslateList = p => post('report/translateList', p)
