<template>
    <el-row :md="24" :gutter="15">
        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false">
                <span>翻译记录</span>
            </el-alert>
        </el-col>

        <el-card shadow="hover" class="hidden-xs-only">
            <el-form :inline="true" :model="params" label-width="100px">
                <el-row :gutter="1">
                    <el-col :md="8" :lg="8">
                        <el-form-item label="翻译日期：">
                            <el-date-picker
                                    style="width: 240px"
                                    id="datePicker"
                                    v-model="xtime"
                                    @change="queryTime"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :lg="8">
                        <el-form-item label="用户手机号：">
                            <el-input style="width:240px" v-model="params.phone" class="input-width"
                                      placeholder="请输入"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :lg="8">
                        <el-form-item label="店铺账号：">
                            <el-input style="width:240px" v-model="params.shopUsername" class="input-width"
                                      placeholder="请输入"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :lg="8">
                        <el-form-item label="翻译语言：">
                            <el-select
                                    style="width:240px"
                                    v-model="params.languageTo"
                                    clearable>
                                <el-option
                                        v-for="item in optionsLanguage"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="6" :lg="6">
                        <el-form-item>
                            <el-button
                                    style="float:right"
                                    type="primary"
                                    @click="handleSearchList()">
                                查询
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                    style="float:right;margin-right: 15px"
                                    type="primary"
                                    @click="handleResetSearch()">
                                重置
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                        <el-button
                        style="float:right;margin-right: 15px"
                        type="primary"
                        @click="exportExcel()">
                        导出
                        </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.records" v-loading.fullscreen.lock="loading" :xs="24"
                      @sort-change="sortHttp">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="phone" label="账号" align="center">
                </el-table-column>
                <el-table-column prop="shopUsername" label="店铺账号" align="center">
                </el-table-column>
                <el-table-column prop="translateNum" label="字符长度" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="时间" align="center">
                </el-table-column>
                <!--<el-table-column prop="msgId" label="消息ID" align="center">-->
                <!--</el-table-column>-->
                <!--<el-table-column prop="msgFrom" label="源消息" align="center" :show-overflow-tooltip="true">-->
                <!--</el-table-column>-->
                <!--<el-table-column prop="msgTo" label="翻译消息" align="center" :show-overflow-tooltip="true">-->
                <!--</el-table-column>-->
                <el-table-column prop="languageFrom" label="源语言" align="center">
                </el-table-column>
                <el-table-column prop="languageTo" label="翻译后的语言" align="center">
                </el-table-column>
                <el-table-column prop="buyName" label="买家名称" align="center">
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.total" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>

        </el-col>
    </el-row>

</template>

<script>
    import {
        ApiTranslateList
    } from '@/request/reportApi'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {baseURL} from '../../request/config';

    export default {
        components: {},
        data() {
            return {
                optionsLanguage: [
                    {value: "zh", label: "简体中文"},
                    {value: "cht", label: "繁体中文"},
                    {value: "en", label: "英文"},
                    {value: "th", label: "泰文"},
                    {value: "may", label: "马来语"},
                    {value: "vie", label: "越南语"},
                    {value: "id", label: "印尼语"},
                    {value: "pt", label: "葡萄牙语"}
                ],
                xtime: [],
                loading: false,
                params: {
                    page: 1,
                    limit: 10,
                    phone: '',
                    shopUsername: '',
                    sortProp: "",
                    sortType: '',
                    languageTo: ''
                },
                result: {},

            }
        },
        mounted() {
        },
        created() {
            // this.initSearchDate();
            this.handleSearchList();
        },
        methods: {
            shopNumTxt(row) {
                return row.shopNum + "/" + row.shopNumCross + "/" + row.shopNumLocal;
            },
            findChange(v) {
                this.handleSearchList();
            },
            exportExcel() {
                this.queryTime();
                window.open(baseURL + "/report/translateListExport?" + this.$qs.stringify(this.params));
            },
            sortHttp(e) {
                let sortProp = "";
                for (let i = 0; i < e.prop.length; i++) {
                    let str = e.prop[i];
                    if (str.match(/^.*[A-Z]+.*$/)) {
                        sortProp += "_" + str.toString().toLocaleLowerCase();
                    } else {
                        sortProp += str;
                    }
                }
                this.params.sortProp = sortProp;
                this.params.sortType = e.order == "ascending" ? "asc" : e.order == "descending" ? "desc" : "";
                this.handleSearchList();
            },
            getDay(day) {
                let today = new Date();
                let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                let tYear = today.getFullYear();
                let tMonth = today.getMonth();
                let tDate = today.getDate();
                tMonth = this.doHandleMonth(tMonth + 1);
                tDate = this.doHandleMonth(tDate);
                return tYear + "-" + tMonth + "-" + tDate;
            },
            doHandleMonth(month) {
                let m = month;
                if (month.toString().length == 1) {
                    m = "0" + month;
                }
                return m;
            },
            initSearchDate() {
                this.xtime.push(this.getDay(-7));
                this.xtime.push(this.getDay(0));
            },
            handleResetSearch() {
                this.params = Object.assign({}, {
                    limit: 10,
                    page: 1
                });
                this.xtime = '';
            },
            queryTime() {
                if (this.xtime && this.xtime.length == 2) {
                    this.params.beginDate = this.xtime[0]
                    this.params.endDate = this.xtime[1]
                } else {
                    this.params.beginDate = null
                    this.params.endDate = null
                }
            },
            handleSearchList() {
                this.queryTime();
                this.params.page = 1
                this.getPageList()
            },
            // 获取公告列表
            getPageList: function () {
                this.loading = true
                this.result.length=0;
                ApiTranslateList(this.params).then(res => {
                    this.result = res.data
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                });
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            }
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
