<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>卡密列表管理</span></el-alert>
		</el-col>

		<!-- 查询区域 -->
		<el-col>
			<el-card shadow="hover">

				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="AddCardKeyVisible = true"> 添加卡密</el-button>
						</el-col>
					</el-col>

					<el-dialog class="modifypass" title="添加卡密" :md="8" :visible.sync="AddCardKeyVisible">
						<div class="AddUserClass">
							
							<!-- 套餐列表 -->
							<el-select v-model="AddCard.id">
								<el-option v-for="item in Meallist" :key="item.value" :value="item.id" :label="item.setMealName"></el-option>
							</el-select>
							
							<el-input v-model="AddCard.count" clearable placeholder="添加数量" prefix-icon="iconfont icon-mima">
								<template slot="prepend">添加数量</template>
							</el-input>
							<el-button type="primary" @click="AddCardKey">添加卡密</el-button>
						</div>
					</el-dialog>

					<el-col :xs="24" :span="12">
						<el-col :xs="24" :md="6">
							<el-input v-model="params.cardKey" placeholder="卡密" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="6">
							<el-input v-model="params.useUser" placeholder="使用用户" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="6">
							<el-select v-model="params.state" placeholder="卡密状态" clearable>
							    <el-option
							        v-for="item in CardStatus"
							        :key="item.value"
							        :label="item.label"
							        :value="item.value">
							    </el-option>
							</el-select>
						</el-col>
					
						<el-col :xs="24" :md="6">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetCardKeyList">查询</el-button>
						</el-col>
					</el-col>

				</el-row>

			</el-card>
		</el-col>

		<!-- 账单表格 -->
		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="cardKey" label="卡密" width="280"></el-table-column>
				<el-table-column prop="price" label="价格" align="center"></el-table-column>
				<el-table-column prop="state" label="使用状态" width="150"></el-table-column>
				<el-table-column prop="generator" label="购买用户" width="150" align="center"></el-table-column>
				<el-table-column prop="useUser" label="使用用户" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="danger" size="small" @click="DeleteRecord(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>
	</el-row>

</template>

<script>
	import {
		ApiCardKeyList,
		ApiCardKeyDelete,
		ApiMealList,
		ApiCardKeyAdd
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				CardStatus:[
				    {value:'UNUSED',label:'未使用'},
				    {value:'USE',label:'已使用'},
				],
				params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: '',
					useUser:'',
					state:'',
					cardKey:''
				},
				result: [],
				Meallist:[],
				xtime: '',
				AddCardKeyVisible: false,
				AddCard: {
					'id':'',
					'count':''
				},
			}
		},
		created() {
			// 初始化套餐列表
			this.GetMealList()
			this.GetCardKeyList()
		},
		methods: {
			// 获取套餐列表
			GetMealList:function(){
				ApiMealList().then(res=>{
					this.Meallist = res.data.data
				})
			},
			// 获取卡密列表
			GetCardKeyList: function() {
				this.loading = true
				ApiCardKeyList(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			AddCardKey:function(){
				ApiCardKeyAdd(this.AddCard).then(res=>{
					this.$message.success('添加成功！')
					this.AddCardKeyVisible = false
					this.GetCardKeyList()
				})
			},
			// 删除记录
			DeleteRecord: function(row) {
				this.$confirm('此操作将永久删除该记录，是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 删除
					let data = {
						'id':row.id
					}
					ApiCardKeyDelete(data).then(res => {
						this.$message.success('删除成功！')
						// 重新获取
						this.GetCardKeyList()
					})
				}).catch(() => {
					// 取消
				})
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetCardKeyList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetCardKeyList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-select {
			width: 100%;
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}
</style>
