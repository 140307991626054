<template>
    <el-row :md="24" :gutter="15">

        <el-col style="margin-bottom: 15px;">
            <el-alert type="success" :closable="false"><span>IP套餐管理</span></el-alert>
        </el-col>

        <el-col>
            <el-card shadow="hover" class="hidden-xs-only">
                <el-form :inline="true" :model="params" label-width="140px">

                    <el-form-item label="地区：">
                        <el-input v-model="params.areaName" class="input-width" placeholder="请输入"
                                  clearable></el-input>
                    </el-form-item>

                    <el-form-item label="状态：">
                        <el-select
                                style="width:220px"
                                v-model="params.state"
                                clearable
                        >
                            <el-option label="可售" :value="1"/>
                            <el-option label="售罄" :value="2"/>
                        </el-select>
                    </el-form-item>
                    <!--<el-form-item label="创建日期:">-->
                    <!--<el-date-picker-->
                    <!--id="datePicker"-->
                    <!--v-model="xtime"-->
                    <!--@change="queryTime"-->
                    <!--format="yyyy-MM-dd"-->
                    <!--value-format="yyyy-MM-dd"-->
                    <!--type="daterange"-->
                    <!--range-separator="至"-->
                    <!--start-placeholder="开始日期"-->
                    <!--end-placeholder="结束日期"-->
                    <!--clearable-->
                    <!--/>-->
                    <!--</el-form-item>-->
                    <el-form-item>
                        <el-button
                                style="float:right"
                                type="primary"
                                @click="handleSearchList()">
                            查询
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                                style="float:right;margin-right: 15px"
                                type="primary"
                                @click="edit({state:1})">
                            添加
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-col>

        <el-col style="margin-top:15px;" :xs="24">
            <el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="areaCode" label="地区编码" align="center"></el-table-column>
                <el-table-column prop="areaName" label="地区名称" align="center">
                </el-table-column>
                <el-table-column prop="price" label="原价" align="center">
                </el-table-column>
                <el-table-column prop="discountPrice" label="折扣价" align="center">
                </el-table-column>
                <el-table-column prop="state" label="状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state ===1 ">可售</span>
                        <span v-if="scope.row.state ===2 ">售罄</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" width="160"></el-table-column>
                <el-table-column prop="updateTime" label="修改时间" align="center" width="160"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :xs="12">
                <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="this.params.page"
                               :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next"
                               :total="result.count" style="margin-top:15px;text-align:left;">
                </el-pagination>
            </el-col>

        </el-col>
        <el-dialog
                title="通知新增/编辑"
                :visible.sync="dialogVisible"
                width="600px">
            <el-form :model="saveData" :rules="rules" ref="saveData" label-width="120px"
                     style="padding-top: 10px">
                <el-form-item label="地区编码" prop="areaCode">
                    <el-input v-model="saveData.areaCode"></el-input>
                </el-form-item>
                <el-form-item label="地区名称" prop="areaName">
                    <el-input v-model="saveData.areaName"></el-input>
                </el-form-item>

                <el-form-item label="原价" prop="price">
                    <el-input-number v-model="saveData.price" :precision="2" :step="0.1" style="width:100%"></el-input-number>
                </el-form-item>
                <el-form-item label="折扣价" prop="discountPrice">
                    <el-input-number v-model="saveData.discountPrice" :precision="2" :step="0.1" style="width:100%"></el-input-number>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-select
                            style="width:100%"
                            v-model="saveData.state"
                            clearable
                    >
                        <el-option label="可售" :value="1"/>
                        <el-option label="售罄" :value="2"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="saveData.remark" type="textarea"
                              :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit()">保存</el-button>
                    <el-button @click="dialogVisible=false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-row>

</template>

<script>
    import {
        ApiIpMealList,
        ApiIpMealSave,
        ApiIpMealRemove,
    } from '@/request/api'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    export default {
        components: {},
        data() {
            return {
                xtime: '',
                loading: false,
                params: {
                    areaName: '',
                    page: 1,
                    limit: 10,
                    state: '',
                },
                result: {},
                saveData: {},
                dialogVisible: false,
                rules: {
                    areaCode: [
                        {required: true, message: '请输入地区编码', trigger: 'blur'}
                    ],
                    areaName: [
                        {required: true, message: '请输入地区名称', trigger: 'blur'}
                    ],
                    price: [
                        {required: true, message: '请输入原价', trigger: 'blur'}
                    ],
                    discountPrice: [
                        {required: true, message: '请输入折扣价', trigger: 'blur'}
                    ],
                    state: [
                        {required: true, message: '请选择状态', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {

        },
        created() {
            this.handleSearchList()
        },
        methods: {
            queryTime() {
                if (this.xtime && this.xtime.length == 2) {
                    this.params.beginTime = this.xtime[0]
                    this.params.endTime = this.xtime[1]
                } else {
                    this.params.beginTime = null
                    this.params.endTime = null
                }
            },
            handleSearchList() {
                this.params.page = 1
                this.getPageList()
            },
            // 获取公告列表
            getPageList: function () {
                this.loading = true
                ApiIpMealList(this.params).then(res => {
                    this.loading = false
                    this.result = res.data
                }).catch(err => {
                    this.loading = false
                })

            },
            handleSubmit() {
                this.$refs['saveData'].validate((valid) => {
                    if (valid) {
                        ApiIpMealSave(this.saveData).then(res => {
                            if (res.success) {
                                this.dialogVisible = false;
                                this.getPageList()
                                this.$message.success(res.data)
                            }
                        });
                    }
                });
            },
            edit(row) {
                this.saveData = {
                    id: row.id,
                    discountPrice: row.discountPrice,
                    price: row.price,
                    areaName: row.areaName,
                    areaCode: row.areaCode,
                    remark: row.remark,
                    state: row.state
                };
                this.dialogVisible = true;
            },
            remove(row) {
                this.$confirm('确认删除？')
                    .then(() => {
                        ApiIpMealRemove(row).then(res => {
                            if (res.success) {
                                this.getPageList()
                                this.$message.success(res.data)
                            }
                        });
                    })
                    .catch(_ => {
                    });
            },
            handleSizeChange(value) {
                this.params.limit = value
                this.getPageList()
            },
            handleCurrentChange(value) {
                this.params.page = value
                this.getPageList()
            }
        }
    }
</script>


<style lang="less" scoped>
    .iconfont {
        margin-right: 15px;
    }

    .AddUserClass {
        .el-input {
            margin-bottom: 10px;
        }

        .el-button {
            width: 100%;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
