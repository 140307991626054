const app = {
    state: {
        authMenu: []
    },
    mutations: {
        setAuthMenu(state, menuList) {
            state.authMenu = menuList;
        }
    },
    actions: {},
    getters: {
        authMenu: state => state.authMenu
    }
}

export default app
