/**
 * API统一管理
 */
import {get, getData, post, put, del} from './http'

//检测谷歌认证
export const ApiCheckGoogleAuth = p => post('check_google_auth', p)
// 用户管理
export const ApiUserList = p => get('user/list', p)
// 添加用户
export const ApiUserAdd = p => post('user/add', p)
// 编辑代理状态
export const ApiEditProxyStatus = p => put('user/edit_proxy', p)
// 编辑状态
export const ApiEditStatus = p => put('user/edit_status', p)
// 编辑用户
export const ApiEditUser = p => put('user/edit', p)
// 充值记录
export const ApiRechargeList = p => get('recharge/list', p)
// 店铺管理
export const ApiShopList = p => get('shop/list', p)
// 编辑店铺
export const ApiShopEdit = p => put('shop/edit', p)
// 添加店铺
export const ApiShopAdd = p => post('shop/add', p)
// 删除店铺
export const ApiShopDelete = p => del('shop/delete', p)
// 套餐列表
export const ApiMealList = p => get('set-meal/list', p)
// 聊易虾列表
export const ApiMealAppList = p => get('set-meal/app-list', p)
// 聊易虾状态编辑
export const ApiMealAppStateEdit = p => put('set-meal/app-state-edit ', p)
// 添加VIP套餐
export const ApiMealVIPAdd = p => post('set-meal/add', p)
// 编辑VIP套餐
export const ApiMealEdit = p => put('set-meal/edit', p)
// 套餐VIP编辑
export const ApiMealVIPEdit = p => put('set-meal/vip-edit', p)
// 套餐删除
export const ApiMealDelete = p => del('set-meal/delete', p)
// 聊易虾套餐添加
export const ApiMealAppAdd = p => post('set-meal/app-add', p)
// 聊易虾套餐编辑
export const ApiMealAppEdit = p => put('set-meal/app-edit', p)
// 卡密列表
export const ApiCardKeyList = p => get('card-key/list', p)
// 删除卡密
export const ApiCardKeyDelete = p => del('card-key/delete', p)
// 添加卡密
export const ApiCardKeyAdd = p => post('card-key/add', p)
// 获取登录日志
export const ApiLoginLog = p => get('log/login-log', p)
// 获取APP日志
export const ApiAppLoginLog = p => get('log/app-login-log', p)
// 系统配置列表
export const ApiConfigList = p => get('sys-config/list', p)
// 系统配置添加
export const ApiConfigAdd = p => post('sys-config/add', p)
// 系统配置编辑
export const ApiConfigEdit = p => put('sys-config/edit', p)
// 获取公告列表
export const ApiNoticesList = p => get('notices/list', p)
// 添加公告
export const ApiNoticesAdd = p => post('notices/add', p)
// 删除公告
export const ApiNoticesDelete = p => del('notices/delete', p)
// 更改TOP状态
export const ApiNoticesChangeTop = p => put('notices/change-top', p)
// 更改STATE状态
export const ApiNoticesChangeState = p => put('notices/change-state', p)
// 编辑公告
export const ApiNoticesEdit = p => put('notices/edit', p)
// 登录
export const ApiLogin = p => post('login', p)
// IP列表
export const ApiIpList = p => get('ip/list', p)
// IP添加
export const ApiIpAdd = p => post('ip/add', p)
// IP删除
export const ApiIpDelete = p => del('ip/delete', p)
// 租户列表
export const ApiTenantList = p => get('tenant/list', p)
// 租户添加
export const ApiTenantAdd = p => post('tenant/add', p)
// 租户删除
export const ApiTenantDelete = p => del('tenant/delete', p)
// 租户状态更新
export const ApiTenantEditState = p => put('tenant/edit-state', p)
// 租户信息编辑
export const ApiTenantEdit = p => put('tenant/edit', p)
// Banner列表
export const ApiBannerList = p => get('banner/list', p)
// Banner添加
export const ApiBannerAdd = p => post('banner/add', p)
// Banner状态更新
export const ApiBannerEditState = p => put('banner/edit-state', p)
// Banner删除
export const ApiBannerDelete = p => del('banner/delete', p)
// Banner信息编辑
export const ApiBannerEdit = p => put('/banner/edit', p)
// 账变管理
export const ApiConsumptionLog = p => get('log/balance-log', p)
// 业务日志
export const ApiBusinessLog = p => get('log/business-log', p)
// 提现记录
export const ApiWithdrawList = p => get('withdraw/list', p)
// 提现提交
// export const ApiWithdrawSubmit = p => post('withdraw/submit',p)
//  提现状态设置
export const ApiWithdrawOperation = p => put('withdraw/operation', p)
// APP更新列表
export const ApiAppUpdate = p => get('app-update/list', p)
// APP更新编辑
export const ApiAppUpdateEdit = p => put('app-update/edit', p)


// 注册
// export const ApiRegister = p => post('register',p)
// 用户信息
export const ApiPersonalInfo = p => getData('personal/info', p)
// 获取客服列表
export const ApiPersonalCustomerList = p => getData('personal/customer-list', p)
// 修改客服
export const ApiPersonalChangeCustomer = p => put('personal/change-customer', p)
// 修改绑定电话
export const ApiPersonalChangePhone = p => put('personal/change-phone', p)
// 获取卡密列表
// export const ApiCardKeyList = p => get('card-key/list',p)
// 使用卡密
export const ApiCardKeyUse = p => put('card-key/use', p)
// 消费记录
export const ApiSetMealConsumptionList = p => get('set-meal/consumption-list', p)
// 购买套餐
export const ApiSetMealBuy = p => post('set-meal/buy', p)
// 获取套餐列表
export const ApiSetMealList = p => getData('set-meal/list', p)
// 购买卡密
export const ApiSetMealBuyCardKey = p => post('set-meal/buy-card-key', p)
// 历史使用卡密列表
export const ApiCardKeyUseLog = p => get('card-key/use-log', p)
// 发起支付宝
export const ApiRechargeAlipay = p => post('recharge/alipay', p)
// 查询订单结果
export const ApiRechargeQuery = p => put('recharge/query', p)
// 查询充值记录
// export const ApiRechargeList = p => get('recharge/list',p)
// 修改密码
export const ApiChangePassword = p => put('personal/change-password', p)
// 退出登录
export const ApiLogout = p => getData('logout', p)
// 添加店铺
// export const ApiShopAdd = p => post('shop/add',p)
// 店铺列表
// export const ApiShopList = p => get('shop/list',p)
//意见反馈列表
export const ApiFeedbackList = p => post('sysFeedback/findPage', p)
//已经反馈回复
export const ApiFeedbackReply = p => post('sysFeedback/reply', p)
//通知管理列表
export const ApiSysMsgList = p => post('sys/msg/findPage', p)
//通知管理保存
export const ApiSysMsgSave = p => post('sys/msg/saveOrUpdate', p)
//通知管理发布
export const ApiSysMsgRelease = p => post('sys/msg/release', p)
//通知管理删除
export const ApiSysMsgRemove = p => post('sys/msg/remove', p)
//查询当个消息
export const ApiSysMsgGetById = p => get('sys/msg/getById', p)

//客服充值
export const ApiPayByAdmin = p => post('recharge/payByAdmin', p)
//消费记录列表
export const ApiConsumeLogList = p => post('recharge/consumeLogList', p)
//会员等级列表
export const ApiGetLevelList = p => post('recharge/getLevelList', p)
//客服开通会员
export const ApiBuyVipByAdmin = p => post('recharge/buyVipByAdmin', p)
//撤回消息
export const ApiSysMsgReCall = p => post('sys/msg/reCall', p);
//推送消息
export const ApiSysMsgPush = p => post('sys/msg/push', p);

//图片弹框列表
export const ApiPopupImgList = p => post('popup/img/findPage', p)
//图片弹框保存
export const ApiPopupImgSave = p => post('popup/img/saveOrUpdate', p)
//图片弹框发布
export const ApiPopupImgRelease = p => post('popup/img/release', p)
//图片弹框删除
export const ApiPopupImgRemove = p => post('popup/img/remove', p)
//图片弹框撤回
export const ApiPopupImgReCall = p => post('popup/img/reCall', p);
//图片弹框置顶
export const ApiPopupImgTop = p => post('popup/img/top', p);


//文字弹框列表
export const ApiPopupTxtList = p => post('popup/txt/findPage', p)
//文字弹框保存
export const ApiPopupTxtSave = p => post('popup/txt/saveOrUpdate', p)
//文字弹框发布
export const ApiPopupTxtRelease = p => post('popup/txt/release', p)
//文字弹框删除
export const ApiPopupTxtRemove = p => post('popup/txt/remove', p)
//文字弹框撤回
export const ApiPopupTxtReCall = p => post('popup/txt/reCall', p);
//一键发版
export const ApiQuickUpApp = p => post('app-update/quickUp', p);

//IP套餐列表
export const ApiIpMealList = p => post('ip/meal/findPage', p)
//IP套餐保存
export const ApiIpMealSave = p => post('ip/meal/saveOrUpdate', p)
//IP套餐删除
export const ApiIpMealRemove = p => post('ip/meal/remove', p)

//权限菜单
export const ApiAuthMenu = p => get('sys/auth/authMenus', p)
