<template>
	<el-row :md="24" :gutter="15">

		<el-col style="margin-bottom: 15px;">
			<el-alert type="success" :closable="false"><span>客服列表管理</span></el-alert>
		</el-col>

		<!-- 查询区域 -->
		<el-col>
			<el-card shadow="hover">

				<el-row :gutter="15">

					<el-col class="hidden-xs-only" :span="12" style="float: left;">
						<el-col :md="4">
							<el-button type="primary" icon="iconfont icon-tianjia" @click="AddCustomerVisible = true"> 添加客服</el-button>
						</el-col>
					</el-col>

					<!-- 添加客服窗口开始 -->
					<el-drawer title="添加客服" :visible.sync="AddCustomerVisible" :direction="direction" :before-close="handleClose">
						<el-card class="Customer">
							<el-col>
								<el-input v-model="AddUser.username" clearable placeholder="客服名称" prefix-icon="iconfont icon-yonghu"></el-input>
							</el-col>
							<el-col>
								<el-input v-model="AddUser.password" clearable show-password placeholder="客服微信" prefix-icon="iconfont icon-weixin"></el-input>
							</el-col>
							<el-col>
								<el-input v-model="AddUser.email" clearable placeholder="客服邮箱" prefix-icon="iconfont icon-youxiang1"></el-input>
							</el-col>
							<el-col>
								<el-input v-model="AddUser.phone" clearable placeholder="客服QQ" prefix-icon="iconfont icon-qq"></el-input>
							</el-col>
							<el-col>
								<el-upload style="border: #ccc 1px solid;" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
								 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
									<img v-if="imageUrl" :src="imageUrl" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-col>
							<el-col>
								<el-button style="width: 100%;">添加客服</el-button>
							</el-col>
						</el-card>
					</el-drawer>
					<!-- 添加客服窗口结束 -->


					<!-- 添加客服窗口开始 -->

					<el-dialog class="modifypass" title="编辑客服" width="40%" :visible.sync="EditCustomerVisible">
						<div class="AddUserClass">

							<el-row :gutter="15">
								<el-col :span="12">
									<el-input v-model="AddUser.username" clearable placeholder="客服名称" prefix-icon="iconfont icon-yonghu"></el-input>
								</el-col>
								<el-col :span="12">
									<el-input v-model="AddUser.password" clearable show-password placeholder="客服微信" prefix-icon="iconfont icon-weixin"></el-input>
								</el-col>
								<el-col :span="12">
									<el-input v-model="AddUser.email" clearable placeholder="客服邮箱" prefix-icon="iconfont icon-youxiang1"></el-input>
								</el-col>
								<el-col :span="12">
									<el-input v-model="AddUser.phone" clearable placeholder="客服QQ" prefix-icon="iconfont icon-qq"></el-input>
								</el-col>
							</el-row>

							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
							 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>


							<el-button type="primary" @click="AddUserInfo">添加客服</el-button>
						</div>
					</el-dialog>


					<!-- <el-dialog :visible.sync="dialogVisible">
					  <img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog> -->


					<!-- 添加客服窗口结束 -->



					<el-col :xs="24" :span="12">
						<el-col :xs="24" :md="8">
							<el-input :xs="10" :md="10" placeholder="用户" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-input :xs="10" placeholder="手机号码" clearable></el-input>
						</el-col>
						<el-col :xs="24" :md="8">
							<el-button style="width: 100%;" type="primary" icon="el-icon-search" @click="GetUserList">查询</el-button>
						</el-col>
					</el-col>


				</el-row>


				<!-- <el-select placeholder="卡密状态" clearable>
				    <el-option
				        v-for="item in payStatus"
				        :key="item.value"
				        :label="item.label"
				        :value="item.value">
				    </el-option>
				</el-select> -->
				<!-- <el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
				 end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker> -->

			</el-card>
		</el-col>

		<!-- 客服表格 -->
		<el-col style="margin-top:15px;" :xs="24">
			<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column prop="nickname" label="客服名称" align="center"></el-table-column>
				<el-table-column prop="wechat" label="微信" align="center"></el-table-column>
				<el-table-column prop="qq" label="QQ" align="center"></el-table-column>
				<el-table-column prop="email" label="邮箱" align="center"></el-table-column>
				<el-table-column prop="id" label="客服ID" align="center"></el-table-column>
				<el-table-column prop="qrCode" label="客服二维码" align="center"></el-table-column>
				<el-table-column fixed="right" label="操作" align="center" width="150">
					<template slot-scope="scope">
						<el-button type="success" size="small" @click="EditUserInfo(scope.row)">编辑</el-button>
						<!-- <el-button type="danger" size="small" @click="DeleteRecord(scope.$index,scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>

			<el-col :xs="12">
				<!-- 分页区域 -->
				<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
				 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, prev, pager, next" :total="result.count" style="margin-top:15px;text-align:left;">
				</el-pagination>
			</el-col>


		</el-col>
	</el-row>

</template>

<script>
	import {
		ApiUserList,
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				UploadHeaders: '',
				imageUrl: '',
				params: {
					username: '',
					email: '',
					phone: '',
					loginState: '',
					proxy: '',
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				xtime: '',
				AddCustomerVisible: true,
				EditCustomerVisible: false,
				
				EditUserVisible: false,
				AddUser: {
					username: '',
					password: '',
					email: '',
					phone: ''
				},
				EditUser: {
					userId: '',
					username: '',
					password: '',
					email: '',
					phone: '',
					balance: '',
					// loginState:'',
					// proxy:'',
					proxyPoint: ''
				}
			}
		},
		created() {
			this.GetCustomerServiceList()
			// UploadHeaders
			this.UploadHeaders = {
				'Authorization': window.sessionStorage.getItem('token')
			}
		},
		methods: {
			// 获取客服列表
			GetCustomerServiceList: function() {
				this.loading = true
				ApiUserList(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			// 添加用户信息
			AddUserInfo: function() {
				ApiUserAdd(this.AddUser).then(res => {
					this.$message.success('添加成功!')
					this.AddUserVisible = false
				})
			},
			// 编辑用户信息
			EditUserInfo: function(value) {

				if (value == 'edit') {
					// 提交修改
					ApiEditUser(this.EditUser).then(res => {
						this.$message.success('修改成功')
						// 更新结果
						this.GetCustomerServiceList()
					})
					this.EditUserVisible = false
				} else {
					// 显示编辑
					this.EditUserVisible = true
					// 绑定数据
					this.EditUser.userId = value.userId
					this.EditUser.username = value.username
					this.EditUser.password = value.password
					this.EditUser.email = value.email
					this.EditUser.phone = value.phone
					this.EditUser.balance = value.balance
					this.EditUser.loginState = value.loginState
					this.EditUser.proxy = value.proxy
					this.EditUser.proxyPoint = value.proxyPoint
				}

			},
			// 更新代理状态
			UpdateProxy: function(row) {
				let data = {
					id: row.userId,
					proxy: row.proxy
				}
				ApiEditProxyStatus(data).then(res => {
					this.$message.success('更新成功!')
				})
			},
			// 更新用户状态
			UpdateStatus: function(row) {
				let data = {
					id: row.userId,
					// status:row.loginState == '启用' ? 'ENABLE':'LOCKED'
					status: row.loginState
				}
				ApiEditStatus(data).then(res => {
					this.$message.success('更新成功!')
				})
			},
			
			
			
			SetCustomer:function(method,row){
				
			},
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			

			// 上传
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw)
			},
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				return isLt2M;
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetCustomerServiceList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetCustomerServiceList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
		}
	}
</script>


<style lang="less" scoped>
	.iconfont {
		margin-right: 15px;
	}

	.AddUserClass {
		.el-input {
			margin-bottom: 10px;
		}

		.el-button {
			width: 100%;
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.Customer{
		.el-col{
			margin-bottom: 10px;
		}
	}
</style>
