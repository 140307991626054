<template>
    <el-row style="text-align: center"><img src="../assets/logo2.png" height="600" width="600"/>
        <!-- 欢迎你 -->
        <!--<el-col>-->
            <!--<el-alert type="success" :closable="false"><span>欢迎您回来，<em style="font-size:14px;">{{userinfo.username}}</em></span></el-alert>-->
        <!--</el-col>-->
        <!--&lt;!&ndash; 余额 + 消费 + 客服 &ndash;&gt;-->
        <!--<el-row :gutter="15">-->
            <!--&lt;!&ndash; 余额 &ndash;&gt;-->
            <!--<el-col :span="8" class="info">-->
                <!--<el-card shadow="hover">-->
                    <!--&lt;!&ndash; 顶部 &ndash;&gt;-->
                    <!--<div class="card-header">-->
                        <!--<div>-->
                            <!--可用余额:-->
                            <!--<span>{{ userinfo.balance }}<span>元</span></span>-->
                        <!--</div>-->
                        <!---->
                        <!--<el-button type="primary" size="mini" @click="jumpRouter('recharge')">充值</el-button>-->
                    <!--</div>-->
                    <!--<div class="card-header" style="margin-top:5px;">-->
                        <!--<div>-->
                            <!--用户套餐:-->
                            <!--<span>{{ userinfo.setMealName }}</span>-->
                        <!--</div>-->

                        <!--<el-button type="warning" size="mini" @click="jumpRouter('packagelist')">升级</el-button>-->
                    <!--</div>-->
                    <!--<div class="card-header" style="margin-top:5px;">-->
                        <!--<div>-->
                            <!--用户电话:-->
                            <!--<span>{{ userinfo.phone }}</span>-->
                        <!--</div>-->
                        <!--<el-button type="danger" size="mini" @click="jumpRouter('modifyPhone')">修改</el-button>-->
                    <!--</div>-->
                    <!--&lt;!&ndash; 修改电话区域 &ndash;&gt;-->
                    <!--<el-dialog class="modifypass" title="修改手机" width="30%" :visible.sync="dialogVisible">-->
                        <!--<div>-->
                            <!--<el-input placeholder="请输入新的手机号码" v-model="modifyData.phone"></el-input>-->
                            <!--<el-input placeholder="请输入账户密码" v-model="modifyData.password"></el-input>-->
                            <!--<el-button @click="modifyphone">修 改 手 机 </el-button>-->
                        <!--</div>-->
                    <!--</el-dialog>-->

                    <!--<div class="card-header" style="margin-top:5px;">-->
                        <!--<div>-->
                            <!--用户邮箱:-->
                            <!--<span>{{ userinfo.email }}</span>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="card-header" style="margin-top:10px;">-->
                        <!--<div>-->
                            <!--到期时间:-->
                            <!--<span>{{ userinfo.setMealTime }}</span>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</el-card>-->
            <!--</el-col>-->
            <!--&lt;!&ndash; 消费 &ndash;&gt;-->
            <!--<el-col :span="8" class="info">-->
                <!--<el-card shadow="hover">-->
                    <!--&lt;!&ndash; 顶部 &ndash;&gt;-->
                    <!--<div class="card-header">-->
                        <!--<span>消费趋势</span>-->
                        <!--<el-link type="primary">查看 ></el-link>-->
                    <!--</div>-->
                    <!--<div>-->
                        <!--<div id="main" style="height:150px;margin-top:10px;"></div>-->
                    <!--</div>-->

                <!--</el-card>-->
            <!--</el-col>-->
            <!--&lt;!&ndash; 客服 &ndash;&gt;-->
            <!--<el-col :span="8" class="info">-->
                <!--<el-card shadow="hover">-->
                    <!--&lt;!&ndash; 顶部 &ndash;&gt;-->
                    <!--<div class="card-header">-->
                        <!--<span>客服信息</span>-->
                        <!--<el-link type="primary" @click="jumpRouter('kefu')">更改 ></el-link>-->

                        <!--&lt;!&ndash; 修改客服区域 &ndash;&gt;-->
                        <!--<el-dialog class="modifykefu" title="选择更换客服" width="25%" :visible.sync="kefuVisible">-->
                            <!--<div>-->

                                <!--<el-select v-model="putinfo.customerId" placeholder="请选择您的客服" clearable>-->
                                    <!--<el-option-->
                                        <!--v-for="item in kefulist"-->
                                        <!--:key="item.id"-->
                                        <!--:label="item.nickname"-->
                                        <!--:value="item.id">-->
                                    <!--</el-option>-->
                                <!--</el-select>-->

                                <!--<el-button @click="modifykefu">修改客服 </el-button>-->
                            <!--</div>-->
                        <!--</el-dialog>-->

                    <!--</div>-->
                    <!---->
                    <!---->
                    <!--<el-col class="kefu">-->
                        <!--&lt;!&ndash; 二维码 &ndash;&gt;-->
                        <!--<el-col :span="7" class="left">-->
                                <!--<el-image style="width:100px;height:100px;" :src="kefuinfo.qrCode"></el-image>-->
                        <!--</el-col>-->
                        <!--&lt;!&ndash; 联系信息 &ndash;&gt;-->
                        <!--<el-col :span="17" class="right">-->
                            <!--<el-row style="width:100%;">-->
                                <!--<el-col>专员 : {{ kefuinfo.nickname }}</el-col>-->
                                <!--<el-col>扣扣 : {{ kefuinfo.qq }}</el-col>-->
                                <!--<el-col>微信 : {{ kefuinfo.wechat }}</el-col>-->
                                <!--<el-col>邮箱 : {{ kefuinfo.email }}</el-col>-->
                            <!--</el-row>-->
                             <!---->
                        <!--</el-col>-->
                    <!---->
                    <!--</el-col>-->
                <!--</el-card>-->
            <!--</el-col>-->

            <!--&lt;!&ndash; 邀请码 &ndash;&gt;-->
            <!--<el-col :span="8" class="info">-->
                <!--<el-card shadow="hover">-->
                    <!--&lt;!&ndash; 顶部 &ndash;&gt;-->
                    <!--<div class="card-header">-->
                        <!--<span>推广信息</span>-->
                        <!--<el-link type="primary">查看 ></el-link>-->
                    <!--</div>-->


                    <!--<el-col class="kefu">-->
                        <!--&lt;!&ndash; 二维码 &ndash;&gt;-->
                        <!--<el-col :span="7" class="left">-->
                                <!--<el-image style="width:100px;height:100px;" :src="userinfo.referralImg"></el-image>-->
                        <!--</el-col>-->
                        <!--&lt;!&ndash; 联系信息 &ndash;&gt;-->
                        <!--<el-col :span="17" class="right">-->
                            <!--<el-row style="width:100%;">-->
                                <!--<el-col style="margin-bottom:20px;">账户-&#45;&#45;等级 : {{ userinfo.level }}</el-col>-->
                                <!--<el-col style="margin-bottom:20px;">我的推荐人 : {{ userinfo.parentUsername }}</el-col>-->
                                <!--<el-col style="margin-bottom:20px;">我的推广码 : {{ userinfo.referralCode }}</el-col>-->

                                <!--&lt;!&ndash; <el-col>邀请链接 : {{ kefuinfo.qq }}</el-col> &ndash;&gt;-->
                                <!---->
                                <!---->
                            <!--</el-row>-->

                        <!--</el-col>-->

                    <!--</el-col>-->
                <!--</el-card>-->
            <!--</el-col>-->

            <!--&lt;!&ndash; 公告 &ndash;&gt;-->
            <!--<el-col :span="8">-->
                <!--<el-card shadow="hover">-->
                    <!--&lt;!&ndash; 顶部 &ndash;&gt;-->
                    <!--<div class="card-header">-->
                        <!--<span>站点公告</span>-->
                        <!--<el-link type="primary">查看全部 ></el-link>-->
                    <!--</div>-->
                    <!--&lt;!&ndash; 公告列表 &ndash;&gt;-->

                <!--</el-card>-->
            <!--</el-col>-->

            <!-- 上传图片 -->
            <!-- <el-col :span="8"> -->
                <!-- <el-card shadow="hover"> -->
                    <!-- 顶部 -->
                    <!-- <div class="card-header"> -->
                        <!-- <span>头像上传</span> -->
<!--                          -->
                    <!-- </div> -->
                    <!-- 上传接口 -->
                    <!-- <div> -->
                        <!-- <el-upload -->
                          <!-- class="avatar-uploader" -->
                          <!-- action="http://192.168.31.226:1081/v1/personal/upload" -->
                          <!-- :show-file-list="false" -->
                          <!-- :headers="Authorization" -->
                          <!-- :on-success="handleAvatarSuccess" -->
                          <!-- :before-upload="beforeAvatarUpload"> -->
                          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
                          <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                        <!-- </el-upload> -->

                    <!-- </div> -->
                <!-- </el-card> -->
            <!-- </el-col> -->


        </el-row>



    </el-row>
</template>

<script>

import echarts from 'echarts'

import { ApiPersonalInfo , ApiPersonalChangeCustomer , ApiPersonalChangePhone , ApiPersonalCustomerList } from '@/request/api'

export default {
    data(){
        return{
            userinfo:[],
            kefuinfo:[],
            imageUrl:'',
            Authorization:'',
            modifyData:{
                phone:'',
                password:''
            },
            dialogVisible:false,
            kefuVisible:false, //客服
            kefulist:[],
            putinfo:{
                customerId:''
            }
        }
    },
    mounted(){

    },
    created(){
        // this.getUserinfo()
        // this.Authorization = {'Authorization':window.sessionStorage.getItem('token')}
    },
    methods:{

        setEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 10,
                    data: ['消费记录', '充值记录', ]
                },
                series: [
                    {
                        name: '数据图表',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '14',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: this.userinfo.consumptionAmount, name: '消费记录'},
                            {value: this.userinfo.rechargeAmount, name: '充值记录'},
                        ]
                    }
                ]
            };
            myChart.setOption(option);
        },
        // 获取用户信息
        async getUserinfo(){
            ApiPersonalInfo().then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.userinfo = res.data
                this.kefuinfo = res.data.customerServiceInfo
                this.setEcharts()
            })
        },

        // 上传
        handleAvatarSuccess(res,file){
            this.imageUrl = URL.createObjectURL(file.raw)
        },
        beforeAvatarUpload(file){
            //const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            return isLt2M;
        },
        async getCustomerList(){
            ApiPersonalCustomerList().then(res => {
                this.kefulist = res.data
            })
        },
        async modifykefu(){
            ApiPersonalChangeCustomer(this.putinfo).then(res => {
                // if(res.code !== 200) return this.$message.error(res.message)
                this.$message.success('修改成功!')
                this.kefuVisible = false
                this.kefuinfo = res.data
            })
        },
        jumpRouter(value){
            switch(value){
                case 'recharge':this.$router.push('/Recharge')
                    break
                case 'packagelist':this.$router.push('/packagelist')
                    break
                case 'modifyPhone':this.dialogVisible = true
                    break
                case 'kefu':this.kefuVisible = true
                        this.getCustomerList()
                    break
            }
        },
        // 修改电话
        async modifyphone(){

            ApiPersonalChangePhone(this.modifyData).then(res => {
                // if(res.code !== 200)return this.$message.error(res.message)
                this.$message.success('成功修改手机号码!')
                this.dialogVisible = false
                this.userinfo.phone = this.modifyData.phone
            })

        }
    }
}
</script>

<style lang="less" scoped>
.el-col{
    margin-bottom: 15px;
}
.info{
    height: 200px;
    .el-card{
        height: 100%;
    }
}
.card-header{
    padding: 0;
    display: flex;
    justify-content: space-between;
    div{
        color:#ccc;
        span{
            color:red;
        }
    }
}
.kefu{
    height: 100%;
    .left{

        padding-top: 15px;
        display: flex;
        align-items: center;
        .el-image{
            padding-right: 15px;
            border-right: 1px solid #ccc;
        }
    }
    .right{
        padding-top: 15px;
        display: flex;
        align-items: center;
        .el-col{
            padding: 0;
            margin-bottom: 4px;
            text-align: left;
        }
    }
}
.modifypass{
    div{
        .el-input{
            width:90%;
            margin-bottom: 15px;
        }
        .el-button{
            width:90%;
            height: 40px;
        }
    }
}
.modifykefu{
    div{
        .el-button{
            width:200px;
            height: 40px;
            margin-left: 15px;
        }
    }
}
</style>
