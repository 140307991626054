<template>
    <el-row>
        
        <el-col>
            <el-alert type="success" :closable="false"><span>您的消费记录</span></el-alert>
        </el-col>
        <!-- 查询区域 -->
        <el-col class="search-box">
            <el-card shadow="hover">
                
                <!--
                <el-select v-model="pay.state" placeholder="卡密状态" clearable>
                    <el-option
                        v-for="item in payStatus"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                -->

                <el-date-picker 
                    v-model="xtime" 
                    type="daterange" 
                    align="right" 
                    unlink-panels 
                    range-separator="至" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    :picker-options="pickerOptions"
                    style="margin-right:15px;"
                    @change="queryTime"
                    value-format="yyyy-MM-dd"
                    ></el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="search">搜索结果</el-button>

            </el-card>
        </el-col>
        <!-- 账单表格 -->
        <el-col style="margin-top:15px;">
            <el-table :data="result" v-loading.fullscreen.lock="loading">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column prop="orderId" label="订单ID"></el-table-column>
                <el-table-column prop="type" label="订单类型"></el-table-column>
                <el-table-column prop="beforeBalance" label="原余额"></el-table-column>
                <el-table-column prop="changeBalance" label="消费金额"></el-table-column>
                <el-table-column prop="afterBalance" label="新余额"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.pay.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                style="margin-top:15px;text-align:left;"
            >
            </el-pagination>

        </el-col>
    </el-row>

</template>

<script>

import { ApiSetMealConsumptionList } from '@/request/api'

export default {
    data(){
        return{
            payStatus:[
                {value:'UNUSED',label:'未使用'},
                {value:'USE',label:'已使用'},
            ],
            loading:true,
            pay:{
                useUser:'',
                state:'',
                cardKey:'',
                beginTime:'',
                endTime:'',
                limit:10,
                page:1,
            },
            xtime:'',
            result:[],
            total:0,
            pickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },
        }
    },
    created(){

        this.getCardList()

    },
    methods:{

        // 获取消费记录
        async getCardList(){

            this.loading = true
            ApiSetMealConsumptionList(this.pay).then(res => {
                if(res.code !== 200)return this.$message.error(res.message)
                this.total = res.data.count
                this.result = res.data.data
            })
            this.loading = false

        },
        search(){
            // 搜索
            this.getCardList()
        },
        // 更改数量
        handleSizeChange(value){
            this.pay.limit = value
        },
        // 更改页数
        handleCurrentChange(value){
            this.pay.page = value
        },
        // 更改时间
        queryTime(){
            this.pay.beginTime = this.xtime[0]
            this.pay.endTime = this.xtime[1]
        },
        // 使用卡密
        async useCard(index,row){
            let data = {
                'id':row.id
            }
            // 请求内容
            const {data:res} = await this.$http.post('set-meal/buy',qs.stringify(data)).catch(function(error){
                return that.$alert(error.response.data.message,{type:'warning'})
            })

            if(res.code == 200){
                this.$message.success("激活成功")
            }else if(res.code == 1502){
                this.$message.error('登录验证已失效，请重新登录!')
                return this.$router.push('/login')
            }else{
                return this.$message.error(res.message)
            }  
        },
        // 使用卡密
        useCard(index,row){
            this.$confirm('您确定要为自己账号使用该卡密吗？', '使用提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { 
                this.use(index,row)
            }).catch(() => {
                return
            });
        }
    }
}
</script>


<style lang="less" scoped>
.search-box{
    margin-top: 15px;
    .el-card{
        text-align: right;
        .el-input{
            width:200px;
            margin-right:15px;
        }
        .el-select{
            margin-right: 15px;
        }
    }
}
</style>