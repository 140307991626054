<template>
	<el-row :xs="24">

		<el-col>
			<el-alert type="success" :closable="false"><span>日志管理</span></el-alert>
		</el-col>
		
		<el-col style="padding-bottom: 50px;">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="登录日志" name="loginlog">
					
					
					
					
					<!-- 查询区域 -->
					<el-col class="search-box hidden-sm-and-down" >
						<el-card shadow="hover">
							<el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker>
							<el-button type="primary" icon="el-icon-search" @click="GetLogList">搜索结果</el-button>
						</el-card>
					</el-col>
					
					<!-- 账单表格 -->
					<el-col style="margin-top:15px;" :xs="24">
						<el-table stripe :data="result.data" v-loading.fullscreen.lock="loading" :xs="24">
							<el-table-column type="index" label="#"></el-table-column>
							<el-table-column prop="username" label="用户" align="center"></el-table-column>
							<el-table-column prop="loginMessage" label="登录时间" align="center"></el-table-column>
							<el-table-column prop="loginIp" label="登录IP" align="center"></el-table-column>
							<el-table-column prop="loginDevice" label="登录设备" align="center"></el-table-column>
							<el-table-column prop="loginBrowser" label="浏览器" align="center"></el-table-column>
							<el-table-column prop="loginResult" label="登录状态" align="center"></el-table-column>
							<el-table-column prop="createTime" label="登录时间" align="center"></el-table-column>
							
							<!-- 傻吊网友不需要删除 -->
							<!-- <el-table-column fixed="right" label="操作" align="center">
								<template slot-scope="scope">
									<el-button type="danger" size="small" @click="DeleteRecord(scope.$index,scope.row)">删除记录</el-button>
								</template>
							</el-table-column> -->
							
						</el-table>
					
						<el-col :xs="12">
							<!-- 分页区域 -->
							<el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="this.params.page"
							 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="result.count"
							 style="margin-top:15px;margin-bottom: 15px;text-align:left;">
							</el-pagination>
						</el-col>
						
					
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="APP日志" name="apploginlog" style="padding-bottom: 20px;">
					<!-- 查询区域 -->
					<el-col class="search-box hidden-sm-and-down" >
						<el-card shadow="hover">
							<el-date-picker v-model="app_xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" style="margin-right:15px;" @change="queryAppTime" value-format="yyyy-MM-dd"></el-date-picker>
							<el-button type="primary" icon="el-icon-search" @click="GetAppLogList">搜索结果</el-button>
						</el-card>
					</el-col>
					
					<!-- 账单表格 -->
					<el-col style="margin-top:15px;" :xs="24">
						<el-table stripe :data="app_result.data" v-loading.fullscreen.lock="loading" :xs="24">
							<el-table-column type="index" label="#"></el-table-column>
							<el-table-column prop="username" label="用户" align="center"></el-table-column>
							<el-table-column prop="loginIp" label="登录IP" align="center"></el-table-column>
							<el-table-column prop="deviceBrand" label="手机品牌" align="center"></el-table-column>
							<el-table-column prop="deviceModel" label="手机型号" align="center"></el-table-column>
							<el-table-column prop="androidVersion" label="安卓版本" align="center"></el-table-column>
							<el-table-column prop="versionName" label="APP版本号" align="center"></el-table-column>
							<el-table-column prop="loginMessage" label="登录信息" align="center"></el-table-column>
							<el-table-column prop="loginResult" label="登录状态" align="center"></el-table-column>
							<el-table-column prop="createTime" label="登录时间" align="center"></el-table-column>
							
							<!-- 傻吊网友不需要删除 -->
							<!-- <el-table-column fixed="right" label="操作" align="center">
								<template slot-scope="scope">
									<el-button type="danger" size="small" @click="DeleteRecord(scope.$index,scope.row)">删除记录</el-button>
								</template>
							</el-table-column> -->
							
						</el-table>
					
						<el-col :xs="12">
							<!-- 分页区域 -->
							<el-pagination small @size-change="ApphandleSizeChange" @current-change="ApphandleCurrentChange" :current-page="this.app_params.page"
							 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="app_result.count"
							 style="margin-top:15px;margin-bottom: 15px;text-align:left;">
							</el-pagination>
						</el-col>
						
					
					</el-col>
				</el-tab-pane>
				
				<el-tab-pane label="业务日志" name="businesslog">
					
					<!-- 查询区域 -->
					<el-col class="search-box hidden-sm-and-down" >
						<el-card shadow="hover">
							<el-date-picker v-model="xtime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" style="margin-right:15px;" @change="queryTime" value-format="yyyy-MM-dd"></el-date-picker>
							<el-button type="primary" icon="el-icon-search" @click="GetBusinessList">搜索结果</el-button>
						</el-card>
					</el-col>
					
					<!-- 账单表格 -->
					<el-col style="margin-top:15px;" :xs="24">
						<el-table stripe :data="business_result.data" v-loading.fullscreen.lock="loading" :xs="24">
							<el-table-column type="index" label="#"></el-table-column>
							<el-table-column prop="username" label="用户" align="center"></el-table-column>
							<el-table-column prop="operationIp" label="操作IP" align="center"></el-table-column>
							<el-table-column prop="operationMethod" label="操作模式" align="center"></el-table-column>
							<el-table-column prop="operationModule" label="操作板块" align="center"></el-table-column>
							<el-table-column prop="operationSystem" label="操作系统" align="center"></el-table-column>
							<el-table-column prop="createTime" label="记录时间" align="center"></el-table-column>
						</el-table>
					
						<el-col :xs="12">
							<!-- 分页区域 -->
							<el-pagination small @size-change="BusinesshandleSizeChange" @current-change="BusinesshandleCurrentChange" :current-page="this.business_params.page"
							 :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="business_result.count"
							 style="margin-top:15px;margin-bottom: 15px;text-align:left;">
							</el-pagination>
						</el-col>
						
					
					</el-col>
				</el-tab-pane>
				
			</el-tabs>
		</el-col>
		
	</el-row>

</template>

<script>
	import {
		ApiLoginLog,
		ApiAppLoginLog,
		ApiBusinessLog
	} from '@/request/api'

	export default {
		data() {
			return {
				loading: true,
				activeName:'loginlog',
				params: {
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				app_params:{
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				business_params:{
					page: 1,
					limit: 10,
					beginTime: '',
					endTime: ''
				},
				result: [],
				app_result:[],
				business_result:[],
				xtime: '',
				app_xtime:''
			}
		},
		created() {
			// 获取日志列表
			this.GetLogList()
		},
		methods: {
			// 获取登录日志
			GetLogList: function() {
				this.loading = true
				ApiLoginLog(this.params).then(res => {
					this.result = res.data
				})
				this.loading = false
			},
			GetAppLogList:function(){
				this.loading = true
				ApiAppLoginLog(this.app_params).then(res => {
					this.app_result = res.data
				})
				this.loading = false
			},
			GetBusinessList: function() {
				this.loading = true
				ApiBusinessLog(this.business_params).then(res => {
					this.business_result = res.data
				})
				this.loading = false
			},
			// 删除记录
			DeleteRecord:function(index,row){
	
				this.$confirm('此操作将永久删除该记录，是否继续?','提示',{
					confirmButtonText:'确定',
					cancelButtonText:'取消',
					type:'warning'
				}).then(()=>{
					// 请求删除
					let data = {
						'id':row.id
					}
					// ApiDeleteLogRecord(data).then(res => {
						// this.$message.success('删除成功!')
					// })
				}).catch(()=>{
					// 取消
				})
			
			},
			handleSizeChange(value) {
				this.params.limit = value
				this.GetLogList()
			},
			handleCurrentChange(value) {
				this.params.page = value
				this.GetLogList()
			},
			ApphandleSizeChange(value) {
				this.app_params.limit = value
				this.GetAppLogList()
			},
			ApphandleCurrentChange(value) {
				this.app_params.page = value
				this.GetAppLogList()
			},
			BusinesshandleSizeChange(value) {
				this.business_params.limit = value
				this.GetBusinessList()
			},
			BusinesshandleCurrentChange(value) {
				this.business_params.page = value
				this.GetBusinessList()
			},
			queryTime() {
				this.params.beginTime = this.xtime[0]
				this.params.endTime = this.xtime[1]
			},
			queryAppTime() {
				this.app_params.beginTime = this.app_xtime[0]
				this.app_params.endTime = this.app_xtime[1]
			},
			handleClick(tab,event){
				if(tab.name == 'loginlog')
					this.GetLogList()
				if(tab.name == 'businesslog')
					this.GetBusinessList()
				else
					this.GetAppLogList()
			}
		}
	}
</script>


<style lang="less" scoped>
	.search-box {
		margin-top: 15px;

		.el-card {
			text-align: right;

			.el-input {
				// width: 200px;
				margin-right: 15px;
			}

			.el-select {
				margin-right: 15px;
			}
		}
	}
</style>
